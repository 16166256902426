import React from "react";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Tooltip from "@mui/material/Tooltip";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import IconButton from "@mui/material/IconButton";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DownloadIcon from "@mui/icons-material/Download";
import ListItemText from "@mui/material/ListItemText";
import MapIcon from "@mui/icons-material/Map";
import { TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

const TreeItemOptions = (props) => {
  const {
    nodeId,
    labelText,
    labelIcon: LabelIcon,
    treeItemOptions,
    onDrop,
    ...other
  } = props;

  const {
    addFunction,
    deleteFunction,
    shareFunction,
    addTitle,
    deleteTitle,
    editable,
    renameFunction,
    setThumbnailAnchor,
    handleSet,
    setThumbnailType,
    isThumbnail,
    downloadProjects,
    openFloorPlan,
    floorPlanAnchor,
    setFloorPlanAnchor,
    setFloorPlanOpen,
    hasFloorPlan,
    setPinDetails,
    isActive,
    displayPins,
    isPublic,
  } = treeItemOptions;

  const theme = useTheme();

  // Handle onDrop event
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Check if a drop function is provided
    if (onDrop) {
      onDrop(e);
    }
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    const parent = e.target.closest("li");
    const editableText = parent.querySelector(".editableText");

    if (editableText) {
      editableText.contentEditable = true;
      editableText.focus();

      let renameCalled = false; // Flag to track if renameFunction has been called

      const handleEnter = (e) => {
        // e.preventDefault();

        if (!renameCalled && (e.key === "Enter" || e.type === "blur")) {
          renameCalled = true; // Set the flag to true to prevent further calls
          renameFunction(editableText.textContent);

          // Clean up event listeners
          editableText.contentEditable = false;
          editableText.removeEventListener("keypress", handleEnter);
          editableText.removeEventListener("blur", handleEnter);
        }
      };

      editableText.addEventListener("keypress", handleEnter);
      editableText.addEventListener("blur", handleEnter);
    }
  };

  const handleDownload = (e) => {
    e.stopPropagation();
    other?.setSelectionMode((prev) => !prev);
  };

  const handleSelect = (node) => {
    if (other?.selectAllProjects) {
      other?.setSelectedProjects([]);
      other?.selectedProjects?.includes(node)
        ? other?.setSelectedProjects([])
        : other?.selectAllProjects();
      return;
    }
    //remove "Designer Space" from selected projects if present
    if (
      other?.selectedProjects.includes("Designer Space") &&
      node !== "Designer Space"
    ) {
      other?.setSelectedProjects((prev) => {
        return prev.filter((item) => item !== "Designer Space");
      });
    }
    other?.setSelectedProjects((prev) => {
      if (prev.includes(node)) {
        return prev.filter((item) => item !== node);
      } else {
        return [...prev, node];
      }
    });
  };
  

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
      style={{ padding: 0, userSelect: "none" }}
      {...other}
    >
      {other?.selectionMode && (
        <Checkbox
          color="primary"
          sx={{ padding: 0, margin: 0 }}
          onChange={(e) => {
            e.stopPropagation();
            handleSelect(nodeId);
          }}
          checkboxId={nodeId}
          checked={other?.selectedProjects.includes(nodeId)}
        />
      )}
      {LabelIcon && !other?.selectionMode && (
        <Tooltip
          title={`Add thumbnail for ${deleteTitle}`}
          arrow
          PopperProps={{ disablePortal: true }}
        >
          <IconButton
            aria-label={`Icon for ${labelText}`}
            onClick={(e) => {
              e.stopPropagation();
              //set the anchor element for the thumbnail popover for parent parent node
              setThumbnailAnchor(e.currentTarget.parentElement);
              setThumbnailType();
              handleSet();
            }}
          >
            <LabelIcon
              fontSize="small"
              sx={{ color: isThumbnail ? "primary.main" : "default" }}
            />
          </IconButton>
        </Tooltip>
      )}
      <ListItemText
        className="editableText"
        onClick={(e) => {
          var contentEditable = e.currentTarget
            .closest("li")
            .querySelector(".editableText").contentEditable;
          if (contentEditable === "true") {
            e.stopPropagation();
          }
        }}
        primary={labelText}
      />
      {other.loadingIcon && other.loadingIcon}
      <Stack direction="row" spacing={-1.5}>
        {editable ? (
          <Tooltip
            title={`Edit ${deleteTitle} name`}
            arrow
            PopperProps={{ disablePortal: true }}
          >
            <IconButton
              onClick={handleEditClick}
              aria-label={`Edit ${deleteTitle}`}
              style={{ minWidth: "40px" }} // Ensure uniform width
            >
              <ModeEditOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton style={{ visibility: "hidden", minWidth: "40px" }} />
        )}
        {downloadProjects && (
          <Tooltip
            title={
              other?.downloadPercent != 0
                ? other?.downloadPercent
                : `Download Projects`
            }
            arrow
            PopperProps={{ disablePortal: true }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleDownload(e);
              }}
              aria-label={`DownloadProjects`}
              sx={{ minWidth: "40px" }}
            >
              {other?.downloadPercent != 0 && (
                <CircularProgress
                  size="21px"
                  value={other?.downloadPercent}
                  variant="determinate"
                  sx={{
                    position: "absolute",
                    color: theme.palette.primary.main,
                  }}
                />
              )}
              <DownloadIcon
                color={other?.selectionMode ? "primary" : "inherit"}
                fontSize="small"
              />
            </IconButton>
          </Tooltip>
        )}

        {addFunction ? (
          <Tooltip
            title={`Add ${addTitle}`}
            arrow
            PopperProps={{ disablePortal: true }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                addFunction();
              }}
              aria-label={`Add ${addTitle}`}
              style={{ minWidth: "40px" }}
            >
              <ControlPointIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton style={{ visibility: "hidden", minWidth: "40px" }} />
        )}
        {deleteFunction ? (
          <Tooltip
            title={`Delete ${deleteTitle}`}
            arrow
            PopperProps={{ disablePortal: true }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                deleteFunction();
              }}
              aria-label={`Delete ${deleteTitle}`}
              style={{ minWidth: "40px" }}
            >
              <DeleteOutlineIcon
                color={deleteTitle == "All Projects" ? "error" : "inherit"}
                fontSize="small"
              />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton style={{ visibility: "hidden", minWidth: "40px" }} />
        )}
        {shareFunction ? (
          <Tooltip
            title={`Share ${deleteTitle}`}
            arrow
            PopperProps={{ disablePortal: true }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                shareFunction();
              }}
              style={{ transform: "scaleX(-1)", minWidth: "40px" }}
              aria-label={`Share ${deleteTitle}`}
            >
              <ReplyOutlinedIcon color={
                isPublic ? "success" : "inherit"
              }  fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : openFloorPlan ? (
          <Tooltip title={`Open Floor Plan`} arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                openFloorPlan();
                setFloorPlanAnchor(e.currentTarget);
                setFloorPlanOpen(true);
              }}
              aria-label={`Open Floor Plan`}
              style={{ minWidth: "40px" }}
            >
              <MapIcon
                color={hasFloorPlan ? "primary" : "inherit"}
                fontSize="small"
              />
            </IconButton>
          </Tooltip>
        ) : (setPinDetails&&floorPlanAnchor &&displayPins ) ? (
          <Tooltip
            title={`Pin the view on Floor Plan`}
            arrow
            PopperProps={{ disablePortal: true }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setPinDetails();
              }}
              aria-label={`Pin the view on Floor Plan`}
              style={{ minWidth: "40px" }}
            >
              <MapIcon
                color={isActive ? "primary" : "inherit"}
                fontSize="small"
              />
            </IconButton>
          </Tooltip>
        ) : deleteTitle != "All Projects" ? (
          <IconButton style={{ visibility: "hidden", minWidth: "40px" }} />
        ) : null}
        {other.closeButton && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              other.handleDrawerClose();
            }}
            aria-label={`Close`}
            style={{ minWidth: "40px" }}
          >
            <other.closeButton fontSize="small" />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export default TreeItemOptions;
