// AppContext.js
import React, { createContext, useState, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";


// Create a context
export const AppContext = createContext();

// Create a provider component
export const AppProvider = ({ children }) => {
  // Define shared variables and their setters
  const [inHome, setInHome] = useState(true);
  const [fs, setfs] = useState({});
  const [selectedRef, setSelectedRef] = useState(null);
  const [storageRef, setStorageRef] = useState(null);
  const [roomPath, setRoomPath] = useState(null);
  const [open, setOpen] = useState(false);
  const [anchorE1, setAnchorE1] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [edit, setEdit] = useState(false);
  const [compassDirection, setCompassDirection] = useState('North');
  const [editCompassDirection, setEditCompassDirection] = useState(false);
  const [showCompass, setShowCompass] = useState(true);
  const [showComments, setShowComments] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [metadataOpen, setMetadataOpen] = useState(false);
  const [keyValues, setKeyValues] = useState([{ key: "", value: "" }]);
  const [currentSphereNumber, setCurrentSphereNumber] = useState("0");
  const [metadataAnchorEl, setMetadataAnchorEl] = useState(null);
  const [metadataPlacement, setMetadataPlacement] = useState(null);
  const [metadataRef, setMetaDataRef] = useState("");
  const [data, setData] = useState({});
  const [currentlyRecording, setCurrentlyRecording] = useState(false);
  const [ffmpegLoaded, setFfmpegLoaded] = useState(false);
  const [ffmpeg, setFfmpeg] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [dataArray, setDataArray] = useState(null);
  const [animationFrameId, setAnimationFrameId] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [chunks, setChunks] = useState([]);
  const [skyLoading, setSkyLoading] = useState(true); // State to track if sky image is loading
  const [showLoader, setShowLoader] = useState(true); // State to control loader display
  const [confirmDialogText, setConfirmDialogText] = useState("");
  const [intersectedElTextValue, setIntersectedElTextValue] = useState(null);
  const [displayIndex, setDisplayIndex] = useState(-1);
  const [toggleState, setToggleState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentView, setCurrentView] = useState(null);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);
  const [currentFloor, setCurrentFloor] = useState(null);
  const [numberMatch, setNumberMatch] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [floorPlanOpen, setFloorPlanOpen] = useState(false);
  const [currentViewName, setCurrentViewName] = useState("");
  const [currentRoomName, setCurrentRoomName] = useState("");
  const [currentProjectName, setCurrentProjectName] = useState("");
  const [currentFloorName, setCurrentFloorName] = useState("");
  const [localSalientDirection, setLocalSalientDirection] = useState();
  const [localUpDirection, setLocalUpDirection] = useState();
  const [localDirectionOfSalientDirection, setLocalDirectionOfSalientDirection] = useState(); const [isMinimized, setIsMinimized] = useState(false);
  const [displaySection, setDisplaySection] = useState(null);
  const [currentPage, setCurrentPage] = useState(0); // Current page index
  const [selectedID, setSelectedID] = useState(null);
  const [floorSelectedID, setFloorSelectedID] = useState(null);
  const [currentSection, setCurrentSection] = useState(null);
  const [userSubscriptionPlan, setUserSubscriptionPlan] = useState(null);
  const [userSubscriptionStartDate, setUserSubscriptionStartDate] = useState(null);
  const [userSubscriptionEndDate, setUserSubscriptionEndDate] = useState(null);
  const [isMarketPlace, setIsMarketPlace] = useState(false);
  const sceneRef = useRef(null);
  const skyRef = useRef(null);
  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackOpen(false);
    setMessage("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const ConfirmDialog = ({ open, onClose }) => {
    const handleConfirm = () => {
      onClose(true);
    };

    const handleCancel = () => {
      onClose(false);
    };

    return (
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Changes"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmDialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>
            <ClearIcon />
          </Button>
          <Button onClick={handleConfirm} autoFocus>
            <CheckIcon />
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const useConfirmDialog = () => {
    const [open, setOpen] = useState(false);
    const [resolve, setResolve] = useState(null);

    const confirm = () => {
      return new Promise((res) => {
        setOpen(true);
        setResolve(() => res);
      });
    };

    const handleClose = (result) => {
      setOpen(false);
      if (resolve) {
        resolve(result);
      }
    };

    return {
      confirm,
      dialog: <ConfirmDialog open={open} onClose={handleClose} />,
    };
  };

  return (
    <AppContext.Provider
      value={{
        inHome,
        setInHome,
        selectedRef,
        setSelectedRef,
        open,
        setOpen,
        anchorE1,
        snackOpen,
        setSnackOpen,
        handleSnackClick,
        handleSnackClose,
        action,
        message,
        setMessage,
        setAnchorE1,
        edit,
        setEdit,
        showCompass,
        setShowCompass,
        showComments,
        setShowComments,
        compassDirection,
        setCompassDirection,
        editCompassDirection,
        setEditCompassDirection,
        data,
        setData,
        showForm,
        setShowForm,
        roomPath,
        setRoomPath,
        rooms,
        setRooms,
        selectedRoom,
        setSelectedRoom,
        currentSphereNumber,
        setCurrentSphereNumber,
        setfs,
        fs,
        metadataOpen,
        setMetadataOpen,
        keyValues,
        setKeyValues,
        metadataAnchorEl,
        setMetadataAnchorEl,
        metadataRef,
        setMetaDataRef,
        metadataPlacement,
        setMetadataPlacement,
        storageRef,
        setStorageRef,
        currentlyRecording,
        setCurrentlyRecording,
        ffmpegLoaded,
        setFfmpegLoaded,
        ffmpeg,
        setFfmpeg,
        audioContext,
        setAudioContext,
        analyser,
        setAnalyser,
        dataArray,
        setDataArray,
        animationFrameId,
        setAnimationFrameId,
        recorder,
        setRecorder,
        chunks,
        setChunks,
        skyLoading,
        setSkyLoading,
        showLoader,
        setShowLoader,
        confirmDialogText,
        setConfirmDialogText,
        useConfirmDialog,
        intersectedElTextValue,
        setIntersectedElTextValue,
        currentSphereNumber,
        setCurrentSphereNumber,
        currentPage,
        setCurrentPage,
        selectedID,
        setSelectedID,
        currentSection,
        setCurrentSection,
        sceneRef,
        skyRef,
        displaySection,
        setDisplaySection,
        displayIndex,
        setDisplayIndex,
        toggleState,
        setToggleState,
        isLoading,
        setIsLoading,
        currentView,
        setCurrentView,
        currentRoom,
        setCurrentRoom,
        currentProject,
        setCurrentProject,
        currentFloor,
        setCurrentFloor,
        numberMatch,
        setNumberMatch,
        isMuted,
        setIsMuted,
        showComments,
        setShowComments,
        currentViewName,
        setCurrentViewName,
        currentRoomName,
        setCurrentRoomName,
        currentProjectName,
        setCurrentProjectName,
        currentFloorName,
        setCurrentFloorName,
        isMinimized,
        setIsMinimized,
        localSalientDirection,
        setLocalSalientDirection,
        localUpDirection,
        setLocalUpDirection,
        localDirectionOfSalientDirection,
        setLocalDirectionOfSalientDirection,
        floorPlanOpen,
        setFloorPlanOpen,
        floorSelectedID,
        setFloorSelectedID,
        userSubscriptionPlan,
        setUserSubscriptionPlan,
        userSubscriptionStartDate,
        setUserSubscriptionStartDate,
        userSubscriptionEndDate,
        setUserSubscriptionEndDate,
        isMarketPlace,
        setIsMarketPlace,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
