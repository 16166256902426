import axios from "axios";
import {
  ConvertPathIntoRawPath,
  getFile,
} from "../CloudStorage/cloudStorageAbstraction";

async function loadImage(
  selectedRef,
  setConfirmDialogText,
  confirm,
  setEdit,
  setMetadataOpen,
  setMetaDataRef,
  setData,
  setMessage,
  handleSnackClick,
  setOpen,
  sceneRef,
  skyRef,
  edit,
  setIsLoading,
  setInHome
) {
  if (edit) {
    setConfirmDialogText(
      "Unsaved chnages are lost.Are you sure you want to change the image?"
    );
    const result = await confirm();
    if (!result) {
      return;
    }
  }
  setEdit(false);
  setMetadataOpen(false);
  Array.prototype.slice
    .call(document.querySelectorAll("[normal-to-camera]"))
    .forEach(function (item) {
      item.remove();
    });
  setIsLoading(true);
  setMessage("Loading Image!");
  handleSnackClick();

  //the raw file path based on the selected reference

  const rawFilePath = ConvertPathIntoRawPath(selectedRef) + ".png";
  // Fetch the file (raw data) [For PNGs, we are getting a Uint8Array]
  const rawFetchedFile = await getFile(rawFilePath);

  // Check if rawFetchedFile is a Uint8Array
  if (rawFetchedFile instanceof Uint8Array) {
    // Convert the Uint8Array to a Blob (specifying the MIME type as image/png)
    const blob = new Blob([rawFetchedFile], { type: "image/png" });
    const img = new Image();
    // Create a FileReader to read the Blob into an image
    const reader = new FileReader();

    reader.onloadend = async function () {
      // Once the file is loaded, use it as the source for the <a-sky> element
      const newSky = document.createElement("a-sky");
      newSky.setAttribute("class", "clickable");
      newSky.setAttribute("src", reader.result); // The result is a data URL
      newSky.setAttribute("rotation", "0 -90 0");
      // Replace the existing <a-sky> element with the new one
      sceneRef.current.replaceChild(newSky, skyRef.current);
      skyRef.current = newSky;
      const metaDataPath =
        ConvertPathIntoRawPath(selectedRef) + "-metadata.json";
      setMetaDataRef(metaDataPath);
      const metadataFileWeGot = await getFile(metaDataPath);
      //convert this metadata file to a JSON
      const metadataFileAsJSON = JSON.parse(metadataFileWeGot);
      if (metadataFileAsJSON) {
        setInHome(false);
        setMessage("Image loaded!");
        handleSnackClick();
        setOpen(false);
        setData(metadataFileAsJSON);
        setIsLoading(false);
      }
    };
    // Start reading the Blob as a data URL (this will trigger the onloadend)
    reader.readAsDataURL(blob);
  } else {
    setMessage("Problem occured while loading image");
    handleSnackClick();
  }
}

// Fetch the public IP address
const fetchPublicIpAddress = async (setPublicIP) => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json");
    setPublicIP(response.data.ip);
  } catch (error) {
    console.error("Failed to fetch public IP address:", error);
  }
};

// Fetch the private IP address
const fetchPrivateIpAddress = (setPrivateIP) => {
  const rtc = new RTCPeerConnection({ iceServers: [] });
  rtc.createDataChannel("");
  rtc.createOffer().then((offer) => rtc.setLocalDescription(offer));
  rtc.onicecandidate = (event) => {
    if (event && event.candidate && event.candidate.candidate) {
      const candidate = event.candidate.candidate;
      const uniqueAdress = candidate.split(" ")[4];
      if (uniqueAdress) {
        setPrivateIP(uniqueAdress);
        rtc.close();
      }
    }
  };
};

const setMultipleAttributes = (el, attributes) => {
  for (var attr in attributes) el.setAttribute(attr, attributes[attr]);
};

function createSpheres(jsonData) {
  const camera = document.querySelector("[camera]");
  const cameraPosition = new window.THREE.Vector3();
  camera.object3D.getWorldPosition(cameraPosition);

  // Ensure jsonData is in expected format
  if (typeof jsonData !== "object" || Array.isArray(jsonData)) {
    console.error("Invalid jsonData format.");
    return;
  }

  // Iterate over the jsonData to create the spheres
  for (const key in jsonData) {
    if (isNaN(key)) {
      continue;
    }

    const sphereData = jsonData[key];
    const intersection = sphereData.intersection; // Assuming this is [x, y, z]

    // Create DOM elements
    const planeEl = document.createElement("a-plane");
    const borderCircleEl = document.createElement("a-ring");
    const circleEl = document.createElement("a-circle");
    const textEl = document.createElement("a-text");

    if (!window.AFRAME.components["spheres"]) {
      window.AFRAME.registerComponent("spheres", {
        init: function () {
          this.el.addEventListener("click", (e) => {
            const intersectedEl = e.target;
            const sphereNumber = intersectedEl.getAttribute("number");
          });
          this.el.addEventListener("mouseenter", (e) => {
            const intersectedEl = e.target;
            intersectedEl.setAttribute("color", "red");
          });
          this.el.addEventListener("mouseleave", (e) => {
            const intersectedEl = e.target;
            intersectedEl.setAttribute("color", "black");
          });
        },
      });
    }

    if (!window.AFRAME.components["normal-to-camera"]) {
      window.AFRAME.registerComponent("normal-to-camera", {
        tick: function () {
          const camera = this.el.sceneEl.camera; // Get the camera's object3D
          const plane = this.el.object3D; // Get the plane's object3D

          // Get the camera's forward direction (z-axis in three.js)
          const cameraDirection = new window.THREE.Vector3();
          camera.getWorldDirection(cameraDirection);

          // Position the plane to be normal to the camera’s view direction
          const targetPoint = plane.position.clone().sub(cameraDirection);
          plane.lookAt(targetPoint);

          const cameraPosition = new window.THREE.Vector3();
          camera.getWorldPosition(cameraPosition);

          const radialDistance = plane.position
            .clone()
            .sub(cameraPosition)
            .length();
          const perpendicularDistance = plane.position
            .clone()
            .sub(cameraPosition)
            .dot(cameraDirection);

          const scale = Math.abs(perpendicularDistance / radialDistance);
          plane.scale.set(scale, scale, scale);
        },
      });
    }

    if (planeEl && borderCircleEl && circleEl && textEl) {
      // Set attributes for the ring
      borderCircleEl.setAttribute("radius-inner", "10");
      borderCircleEl.setAttribute("radius-outer", "12");
      borderCircleEl.setAttribute("color", "blue");
      borderCircleEl.setAttribute(
        "material",
        "opacity: 0.8; transparent: true"
      );

      // Set attributes for the circle (sphere)
      setMultipleAttributes(circleEl, {
        radius: 10,
        class: "clickable",
        color: "black",
        spheres: true,
        number: key,
        opacity: 0.5,
      });

      // Set the position for the plane
      planeEl.setAttribute(
        "position",
        `${intersection.x} ${intersection.y} ${intersection.z}`
      );
      planeEl.setAttribute("normal-to-camera", true);
      planeEl.setAttribute("number", key);

      // Set attributes for the text (sphere number)
      setMultipleAttributes(textEl, {
        value: key,
        align: "center",
        color: "white",
        width: 300,
        class: "metadata",
      });

      // Append elements to the scene
      const sceneEl = document.getElementsByTagName("a-scene")[0];
      sceneEl.appendChild(planeEl);
      planeEl.appendChild(borderCircleEl);
      planeEl.appendChild(circleEl);
      planeEl.appendChild(textEl);
    }
  }
}

export default {
  loadImage,
  fetchPublicIpAddress,
  fetchPrivateIpAddress,
  createSpheres,
};
