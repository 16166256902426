import { 
    GetFile, 
    CreateFolder, 
    DeleteFolder, 
    RenameFolder, 
    AddFile, 
    RemoveFile, 
    RenameFile, 
    UpdateFile,
    ListAllChildDirectoriesAndFiles,
    GetAllAndDownload
} from './wasabiCloudRaw.js';


export function ConvertPathIntoRawPath(url) {
    if (url.startsWith('gs://')) {
        // Remove the 'gs://' prefix and split the URL by '/'
        const path = url.slice(5); // Remove 'gs://'
        const parts = path.split('/');
        // Return the path excluding the first part (bucket name)
        return parts.slice(1).join('/');
    } else if (url.startsWith('s3.')){
     const parts = url.split('/');
     // Join the parts after the third slash and return
     return parts.slice(3).join('/');
    }else{
        return url;
    }
}

export async function getFile(key) {
    try {
        const file = await GetFile(key);
        return file; // Return the file content retrieved
    } catch (error) {
        console.error('Error in getFile wrapper:', error);
        throw error;
    }
}

export async function createFolder(folderName, path) {
    try {
        const status = await CreateFolder(folderName, path);
        return status; // Return the status code
    } catch (error) {
        console.error('Error in createFolder wrapper:', error);
        throw error;
    }
}

export async function deleteFolder(path) {
    try {
        const status = await DeleteFolder(path);
        return status; // Return the status code
    } catch (error) {
        console.error('Error in deleteFolder wrapper:', error);
        throw error;
    }
}

export async function renameFolder(folderName, path, newFolderName) {
    try {
        const status = await RenameFolder(folderName, path, newFolderName);
        return status; // Return the status code
    } catch (error) {
        console.error('Error in renameFolder wrapper:', error);
        throw error;
    }
}

export async function addFile(fileName, path, file) {
    try {
        const status = await AddFile(fileName, path, file);
        return status; // Return the status code
    } catch (error) {
        console.error('Error in addFile wrapper:', error);
        throw error;
    }
}

export async function removeFile(fileName, path) {
    try {
        const status = await RemoveFile(fileName, path);
        return status; // Return the status code
    } catch (error) {
        console.error('Error in removeFile wrapper:', error);
        throw error;
    }
}

export async function renameFile(fileName, path, newFileName) {
    try {
        const status = await RenameFile(fileName, path, newFileName);
        return status; // Return the status code
    } catch (error) {
        console.error('Error in renameFile wrapper:', error);
        throw error;
    }
}

export async function updateFile(fileName, path, file, append = false) {
    try {
        const status = await UpdateFile(fileName, path, file, append);
        return status; // Return the status code
    } catch (error) {
        console.error('Error in updateFile wrapper:', error);
        throw error;
    }
}


export async function  listAllChildDirectoriesAndFiles(path){
    try {
        const status = await ListAllChildDirectoriesAndFiles(path);
        return status; // Return the status code
    } catch (error) {
        console.error('Error in listAllChildDirectoriesAndFiles wrapper:', error);
        throw error;
    }
}


export async function DownloadAllFileAndFolders(folderPath)
{
    try {
        await GetAllAndDownload(folderPath);
    } catch (error) {
        console.error('Error downloading all files:', error);
    }
}