import React, { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import ToggleColorMode from "./ToggleColorMode";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import MapIcon from "@mui/icons-material/Map";
import CompassIcon from "@mui/icons-material/ExploreTwoTone";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import PublishIcon from "@mui/icons-material/Publish";
import LogoutIcon from "@mui/icons-material/Logout";
import ChatIcon from "@mui/icons-material/Chat";
import { useNavigate } from "react-router-dom";
import { auth, storage } from "../authentication/Firebase";
import User from "./User";
import getLPTheme from "../../getLPTheme";
import { AppContext } from "../../AppContext.js";
import { Typography } from "@mui/material";
// import multivrselogo from "./multivrselogo.svg";
import HelpIcon from "@mui/icons-material/Help";
import { ref } from "firebase/storage";
import commonFunctions from "../CommonComponents/commonFunctions.js";
import {
  addFile,
  getFile,
  ConvertPathIntoRawPath,
  updateFile,
} from "../CloudStorage/cloudStorageAbstraction.js";
import { Buffer } from "buffer";

const AppAppBar = ({ mode, toggleColorMode }) => {
  const {
    inHome,
    setInHome,
    setOpen,
    edit,
    setEdit,
    showCompass,
    setShowCompass,
    showComments,
    setShowComments,
    data,
    setMessage,
    handleSnackClick,
    metadataRef,
    fs,
    setMetadataOpen,
    setData,
    currentViewName,
    setCurrentViewName,
    currentRoomName,
    setCurrentRoomName,
    currentFloorName,
    setCurrentFloorName,
    currentProjectName,
    setCurrentProjectName,
    currentProject,
    currentFloor,
    currentRoom,
    currentView,
    setCurrentView,
    setCurrentRoom,
    setCurrentFloor,
    setCurrentProject,
    setLocalSalientDirection,
    setLocalUpDirection,
    setLocalDirectionOfSalientDirection,
    isMinimized,
    setIsMinimized,
    setFloorPlanOpen,
    floorPlanOpen,
    userSubscriptionEndDate,
  } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasFloorPlan, setHasFloorPlan] = useState(false);

  useEffect(() => {
    if (metadataRef) {
      const projID = metadataRef.split("/").slice(-5)[0];
      const floorID = metadataRef.split("/").slice(-4)[0];
      const roomID = metadataRef.split("/").slice(-3)[0];
      const viewID = metadataRef.split("/").slice(-2)[0].split("-")[0];

      const floorPlan=fs[projID]?.floors[floorID]?.hasFloorPlan;
      setHasFloorPlan(floorPlan);

      const projectName = fs[projID]?.name;
      const floorName = fs[projID].floors[floorID].name;
      const roomName = fs[projID].floors[floorID].rooms[roomID].name;
      const viewName =
        fs[projID].floors[floorID].rooms[roomID].views[viewID].name;

      setCurrentProjectName(projectName);
      setCurrentFloorName(floorName);
      setCurrentRoomName(roomName);
      setCurrentViewName(viewName);
    }
  }, [fs, metadataRef]);

  const theme = getLPTheme();
  theme.palette.mode = mode;
  const textColor = theme.palette.mode === "dark" ? "#FFFFFF" : "#000000";

  const remainingSubscriptionDays = userSubscriptionEndDate 
  ? Math.floor((new Date(userSubscriptionEndDate).setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24))
  : " ";


  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  async function updateMetadata(storage, reference, newKeyValuePairs) {
    // Construct a reference to the metadata JSON file
    const metadataRef = ref(storage, reference);

    try {
      // Convert new key-value pairs to JSON
      const updatedMetadataString = JSON.stringify(newKeyValuePairs);

      // Accessing `_location.path_` with a fallback
      const metadataPath = metadataRef._location?.path_ || "";
      // Safely extract the path and filename
      const lastSlashIndex = metadataPath.lastIndexOf("/");
      if (lastSlashIndex === -1) {
        throw new Error("Invalid metadata path format");
      }
      const rawPath = metadataPath.slice(0, lastSlashIndex);
      const fileName = metadataPath.slice(lastSlashIndex + 1);
      // Convert metadata string to a buffer
      const fileBuffer = Buffer.from(updatedMetadataString, "utf-8");

      // Upload the file and log the status code
      const statusCode = await addFile(fileName, rawPath, fileBuffer);
      setMessage("Metadata updated successfully!");
      handleSnackClick();
    } catch (error) {
      console.error("Error updating metadata:", error);
      setMessage("Error updating metadata!");
      handleSnackClick();
    }
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setInHome(true);
    auth
      .signOut()
      .then(() => {
        // Sign-out successful.
        ("User signed out");
        navigate("/signin");
      })
      .catch((error) => {
        // An error happened.
        console.error("Error signing out:", error);
      });
  };

  // Inactivity Timer Logic
  useEffect(() => {
    let logoutTimer;

    const resetTimer = () => {
      if (logoutTimer) clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        handleLogout();
        setMessage("Logged out due to inactivity!");
        handleSnackClick();
      }, 300000);
    };

    const handleActivity = () => {
      resetTimer();
    };

    // Set up activity listeners
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keypress", handleActivity);

    // Start the timer initially
    resetTimer();

    // Cleanup the event listeners on unmount
    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
    };
  }, []);

  const toggleShowCompass = () => {
    setShowCompass(!showCompass);
    setMessage(showCompass?"Compass hidden":"Compass shown");
    handleSnackClick();
  };

  const toggleShowComments = () => {
    setShowComments(!showComments);
    handleSnackClick();
  };

  const toggleEdit = () => {

    if( remainingSubscriptionDays!=" " && remainingSubscriptionDays <= 0){
      setMessage("Subscription ended. Please renew your subscription to add or edit metadata.");
      handleSnackClick();
      return;
    }
    //eslint-disable-next-line
    if (edit == true) {
      setEdit(false);
      updateMetadata(storage, metadataRef, data);
      setMetadataOpen(false);
      setMessage("Edit mode off!");
      handleSnackClick();
      //remove plane element with attribute normal-to-camera
      Array.prototype.slice
        .call(document.querySelectorAll("[normal-to-camera]"))
        .forEach(function (item) {
          item.remove();
        });
    } else if (metadataRef) {
      // getDownloadURL(ref(storage, metadataRef))
      //   .then((url) => {
      //     // Use the URL to fetch the JSON data
      //     setMessage("Loading metadata!");
      //     handleSnackClick();
      //     return fetch(url);
      //   })
      //   .then((response) => {
      //     if (!response.ok) {
      //       throw new Error("Network response was not ok");
      //     }
      //     return response.json();
      //   })
      //   .then((jsonData) => {
      //     // Use jsonData as your JSON data
      //     setData(jsonData);
      //     commonFunctions.createSpheres(jsonData);
      //     setEdit(true);
      //     setMessage("Right click mouse button to add metadata!");
      //     handleSnackClick();
      //   })
      //   .catch((error) => {
      //     console.error(
      //       "There was a problem with your fetch operation:",
      //       error
      //     );
      //     setMessage("No Image Found");
      //     handleSnackClick();
      //     setOpen(false);
      //   });
      const rawPath = ConvertPathIntoRawPath(metadataRef);
      getFile(rawPath)
        .then((fileBuffer) => {
          const fileString = fileBuffer.toString("utf-8");
          const jsonData = JSON.parse(fileString);
          setData(jsonData);
         
          commonFunctions.createSpheres(jsonData);
          setEdit(true);
          setMessage("Right click mouse button to add metadata!");
          handleSnackClick();
        })
        .catch((error) => {
          console.error("Error fetching metadata:", error);
          setMessage("Error fetching metadata!");
          handleSnackClick();
          setOpen(false);
        });
    }
  };

  const goToHome = () => {
    setInHome(true);
    setCurrentProject(null);
    setCurrentFloor(null);
    setCurrentRoom(null);
    setCurrentView(null);
    handleDrawerClose();
    handleMenuClose();
  };

  //compass storage code
  const stateRefForCompass = useRef({
    currentUser: auth?.currentUser?.email,
    currentProject,
    currentFloor,
    currentRoom,
    currentView,
  });

  // Update the ref whenever state changes
  useEffect(() => {
    stateRefForCompass.current = {
      currentUser: auth?.currentUser?.email,
      currentProject,
      currentFloor,
      currentRoom,
      currentView,
    };
  }, [currentProject, currentFloor, currentRoom, currentView]);

  //compass handling code
  useEffect(() => {
    // Event listener for "compassDirectionChanged"
    const handleCompassDirectionChanged = async (event) => {
      try {
        // Destructure the data from the event's 'detail' property
        const { salientDirection, upDir, salDir } = event.detail;

        // Access the latest state values from the ref
        const {
          currentUser,
          currentProject,
          currentFloor,
          currentRoom,
          currentView,
        } = stateRefForCompass.current;

        // Fetch the metadata file
        const metadataPath = `${currentUser}/${currentProject}/${currentFloor}/${currentRoom}/${currentView}/${currentView}-metadata.json`;
        const rawFileWeGot = await getFile(metadataPath);
        const JSONFileWeGot = JSON.parse(rawFileWeGot.toString("utf-8"));

        setLocalDirectionOfSalientDirection(salDir);
        setLocalSalientDirection(salientDirection);
        setLocalUpDirection(upDir);

        // Prepare updated data with the three variables, replacing keys if they exist
        const updatedData = {
          ...JSONFileWeGot, // Keep existing data from the file
          salientDirection, // Overwrite or add `salientDirection`
          upDir, // Overwrite or add `upDir`
          salDir, // Overwrite or add `salDir`
        };

        // Send the updated data back to storage
        const updateStatus = await updateFile(
          `${currentView}-metadata.json`,
          `${currentUser}/${currentProject}/${currentFloor}/${currentRoom}/${currentView}`,
          JSON.stringify(updatedData, null, 2) // Convert to JSON format
        );
      } catch (error) {
        console.warn("Error handling compass direction change:", error);
      }
    };

    // Add the event listener
    window.addEventListener(
      "compassDirectionChanged",
      handleCompassDirectionChanged
    );

    // Cleanup the event listener
    return () => {
      window.removeEventListener(
        "compassDirectionChanged",
        handleCompassDirectionChanged
      );
    };
  }, []);

  useEffect(() => {
    if (currentView != null) {
      //read the metadata file and set the local variables
      const metadataPath = `${auth?.currentUser?.email}/${currentProject}/${currentFloor}/${currentRoom}/${currentView}/${currentView}-metadata.json`;
      getFile(metadataPath)
        .then((fileBuffer) => {
          const fileString = fileBuffer.toString("utf-8");
          const jsonData = JSON.parse(fileString);
          setLocalSalientDirection(jsonData.salientDirection || "North");
          setLocalUpDirection(jsonData.upDir || new window.THREE.Vector3(0,1,0));
          setLocalDirectionOfSalientDirection(jsonData.salDir || new window.THREE.Vector3(0,0,-1));
        })
        .catch((error) => {
          console.error("Error fetching metadata:", error);
        });
    }
  }, [currentProject,currentFloor,currentRoom,currentView]);

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 2,
          padding: "0px",
          userSelect: "none",
        }}
      >
        <Container maxWidth="false">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "999px",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(24px)",
              maxHeight: 60,
              width: "100%",
              padding: "0p 20px",
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                ml: "-10px",
                px: 0,
              }}
            >
              <User theme={theme} mode={mode} />

              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  marginLeft: "50px",
                  marginRight: "50px",
                  flexGrow: 1,
                }}
              >
                <Tooltip title="Go Home">
                  <IconButton onClick={goToHome}>
                    <HomeIcon />
                  </IconButton>
                </Tooltip>
                {!inHome && (
                  <Tooltip title={edit ? "Submit Metadata" : "Edit Metadata"}>
                    <IconButton onClick={toggleEdit}>
                      {!edit ? (
                        <InfoIcon color={edit ? "warning" : ""} />
                      ) : (
                        <InfoIcon
                          sx={{ color: edit ? "#0a66c2" : "inherit" }}
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
                {!inHome && (
                  <Tooltip title={"Toggle Compass Visibility"}>
                    <IconButton onClick={toggleShowCompass}>
                      {!showCompass ? (
                        <CompassIcon color={showCompass ? "warning" : ""} />
                      ) : (
                        <CompassIcon
                          sx={{ color: showCompass ? "#0a66c2" : "inherit" }}
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
                {edit && (
                  <Tooltip title="Submit Metadata">
                    <IconButton
                      className="Submit Metadata"
                      onClick={() => updateMetadata(storage, metadataRef, data)}
                    >
                      <PublishIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  justifyContent: "center",
                  position: "absolute",
                  left: "50%",
                  color: textColor,
                }}
              >
{
  !inHome && currentProjectName && currentRoomName ? (
    // If both currentProjectName and currentRoomName are present
    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
      {`${currentProjectName}: ${currentFloorName}: ${currentRoomName}`}
    </Typography>
  ) : !inHome && currentProjectName ? (
    // If only currentProjectName is present
    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
      {currentProjectName}
    </Typography>
  ) : (
    remainingSubscriptionDays ? (
      parseFloat(remainingSubscriptionDays) < 10 ? (
        parseFloat(remainingSubscriptionDays) <= 0 ? (
          // If the remaining days are 0 or negative, show "Subscription ended X days ago" in red
          <Typography
            variant="subtitle2"
            sx={{
              color: "red",
              textAlign: "right",
            }}
          >
            {`Subscription ended ${Math.abs(parseFloat(remainingSubscriptionDays))} days ago`}
          </Typography>
        ) : (
          // If the remaining days are less than 10 and positive
          <Typography
            variant="subtitle2"
            sx={{
              opacity: 0.5,
              textAlign: "right",
            }}
          >
            {`Subscription ends in ${remainingSubscriptionDays} days`}
          </Typography>
        )
      ) : null
    ) : null
  )
}
 



                {!inHome && currentViewName && (
                  <Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
                    {currentViewName}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                }}
              >
                {!inHome && hasFloorPlan && (
                  <Tooltip title="Toggle Floor Plan">
                    <IconButton
                      onClick={() => {
                        setIsMinimized(!isMinimized);
                        setFloorPlanOpen(!floorPlanOpen);
                      }}
                    >
                      <MapIcon color={isMinimized?"primary":"inherit"} />
                    </IconButton>
                  </Tooltip>
                )}
                {!inHome && currentProjectName && currentRoomName && (
                  <Tooltip title="Toggle comments visibility">
                    <IconButton onClick={() => setShowComments(!showComments)}>
                      <ChatIcon color={showComments ? "primary" : "inherit"} />
                    </IconButton>
                  </Tooltip>
                )}

                <Tooltip title="Go to help">
                  <IconButton
                    onClick={() => window.open("https://multivrselab.atlassian.net/servicedesk/customer/portal/1", "_blank")}
                  >
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
                {/* <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleMenuClose}>Item 1</MenuItem>
                  <MenuItem onClick={handleMenuClose}>Item 2</MenuItem>
                  <MenuItem onClick={handleMenuClose}>Item 3</MenuItem>
                </Menu> */}
                <Tooltip title="Logout">
                  <IconButton className="logoutButton" onClick={async () => {
                    if (edit) {
                      await toggleEdit(); 
                      handleLogout(); 
                      } else {
                        handleLogout();
                      }
                  }}>
                  <LogoutIcon />
                  </IconButton>
                </Tooltip>

              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 0.5,
                alignItems: "center",
              }}
            >
              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
