import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import { getFile, addFile } from "./CloudStorage/cloudStorageAbstraction";
import { auth } from "./authentication/Firebase";


const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      paper: "#121212",
      default: "#121212",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#bbbbbb",
    },
    divider: "#444444",
    error: { main: "#f44336" },
    warning: { main: "#ff9800" },
    info: { main: "#2196f3" },
    success: { main: "#4caf50" },
    grey: { 300: "#424242" },
  },
});

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newEndDate, setNewEndDate] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
   if(auth.currentUser.email !=" interia@multivrselab.com" && auth.currentUser.email != "ali@des.com"){
      navigate("/signin");
   }
   
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersData = await getFile("users/multivrseInteriaUsers.json");
        const usersList = JSON.parse(usersData)[0];
        setUsers(Object.values(usersList));
      } catch (error) {
        console.error("Error fetching or parsing users data:", error);
      }
    };

    fetchData();
  }, []);

  const getBackgroundColor = (remainingDays) => {
    if (remainingDays < 3) return theme.palette.error.main;
    if (remainingDays < 5) return theme.palette.warning.main;
    if (remainingDays <= 10) return theme.palette.info.main;
    return theme.palette.grey[300];
  };

  const getFontColor = (remainingDays) => {
    if (remainingDays < 3) return theme.palette.getContrastText(theme.palette.error.main);
    if (remainingDays < 5) return theme.palette.getContrastText(theme.palette.warning.main);
    if (remainingDays <= 10) return theme.palette.getContrastText(theme.palette.info.main);
    return "FFFFFF";
  };

  const handleSignUpClick = () => {
    navigate("/adminsignup");
  };

  const handleCardClick = (user) => {
    setSelectedUser(user);
    setNewEndDate(user.subscriptionEndDate || "");
    setOpenDialog(true);
  };

  const handleUpdateEndDate = async () => {
    if (selectedUser && newEndDate) {
      try {
        const usersData = await getFile("users/multivrseInteriaUsers.json");
        const usersList = JSON.parse(usersData)[0];
        const userToUpdate = Object.values(usersList).find(
          (user) => user.email === selectedUser.email
        );

        if (userToUpdate) {
          userToUpdate.subscriptionEndDate = newEndDate;
          await addFile("multivrseInteriaUsers.json", "users", Buffer.from(JSON.stringify([usersList])));
          setOpenDialog(false);

          setUsers(Object.values(usersList));
        }
      } catch (error) {
        console.error("Error updating subscription date:", error);
      }
    }
  };

  const calculateRemainingDays = (date) => {
    if (!date) return null;
    return Math.floor(
      (new Date(date).setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0)) /
      (1000 * 60 * 60 * 24)
    );
  };

  const totalDesigners = users.filter((user) => user.userType === "Designer").length;
  const totalCustomers = users.filter((user) => user.userType === "Customer").length;
  const totalUsers = users.length;

  return (
    <ThemeProvider theme={darkTheme}>
      <Paper sx={{ padding: 3, backgroundColor: "##000000"}}>
        <Typography variant="h1" sx={{ marginBottom: 2, color: "ffffff" }}>
          Admin Page
        </Typography>
        <Button
          variant="contained"
          color="inherit"
          sx={{ marginBottom: 3 }}
          onClick={handleSignUpClick}
        >
          Sign Up New User
        </Button>
        <Divider sx={{ marginBottom: 3, backgroundColor: theme.palette.divider }} />

        {/* Total Users Summary */}
        <Typography variant="h5" sx={{ marginBottom: 2, color: "FFFFFF" }}>
          Total Users: {totalUsers} | Designers: {totalDesigners} | Customers: {totalCustomers}
        </Typography>

        {/* Designers Section */}
        <Typography variant="h2" sx={{ marginBottom: 2, color: "FFFFFF" }}>
          Designers
        </Typography>
        <Grid container spacing={3}>
          {users
            .filter((user) => user.userType === "Designer")
            .map((designer, index) => {
              const remainingDays = calculateRemainingDays(designer.subscriptionEndDate);
              const backgroundColor = remainingDays !== null ? getBackgroundColor(remainingDays) : theme.palette.grey[300];
              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    variant="outlined"
                    sx={{
                      borderRadius: 2,
                      borderColor: theme.palette.divider,
                      backgroundColor,
                      cursor: "pointer",
                      color: "#000000",
                    }}
                    onClick={() => handleCardClick(designer)}
                  >
                    <CardContent>
                      <Typography variant="h5" sx={{ color: "#000000" }}>
                        {designer.firstname} {designer.lastname}
                      </Typography>
                      <Typography variant="body1" sx={{ color: "#000000" }}>
                        <strong>Email:</strong> {designer.email}
                      </Typography>
                      <Typography variant="body1" sx={{ color: "#000000" }}>
                        <strong>Subscription Expiry:</strong> {designer.subscriptionEndDate || "N/A"}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
        </Grid>

        <Divider sx={{ marginY: 3, backgroundColor: theme.palette.divider }} />

        {/* Customers Section */}
        <Typography variant="h2" sx={{ marginBottom: 2, color: "FFFFFF" }}>
          Customers
        </Typography>
        <Grid container spacing={3}>
          {users
            .filter((user) => user.userType === "Customer")
            .map((customer, index) => {
              const remainingDays = calculateRemainingDays(customer.subscriptionEndDate);
              const backgroundColor = remainingDays !== null ? getBackgroundColor(remainingDays) : theme.palette.grey[300];
              const fontColor = remainingDays !== null ? getFontColor(remainingDays) : "#000000";

                return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                  variant="outlined"
                  sx={{
                    borderRadius: 2,
                    borderColor: theme.palette.divider,
                    backgroundColor,
                    cursor: "pointer",
                  }}
                  onClick={() => handleCardClick(customer)}
                  >
                  <CardContent>
                    <Typography variant="h5" sx={{ color: "#000000" }}>
                    {customer.firstname} {customer.lastname}
                    </Typography>
                    <Typography variant="body1" sx={{ color: "#000000" }}>
                    <strong>Email:</strong> {customer.email}
                    </Typography>
                    <Typography variant="body1" sx={{ color: "#000000" }}>
                    <strong>Subscription Expiry:</strong> {customer.subscriptionEndDate || "N/A"}
                    </Typography>
                  </CardContent>
                  </Card>
                </Grid>
                );
            })}
        </Grid>

        {/* Edit Subscription Date Dialog */}
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Edit Subscription End Date</DialogTitle>
          <DialogContent>
            <TextField
              label="New Subscription End Date"
              type="date"
              fullWidth
              value={newEndDate}
              onChange={(e) => setNewEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleUpdateEndDate} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </ThemeProvider>
  );
};

export default AdminPage;