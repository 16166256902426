import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const tiers = [
  {
    title: "Free Trial (3 Months)",
    price: "0",
    description: [
      "1 Active Project",
      "2 Archived Projects",
      "Upto 50 Images",
      "Support Portal Access",
      "No VR Headsets Included",
    ],
    buttonText: "Contact us",
    buttonVariant: "contained",
  },
  {
    title: "Small Business",
    // subheader: 'Recommended',
    price: "2000",
    description: [
      "3 Active Projects",
      "6 Archived Projects",
      "Upto 500 Images",
      "Support Portal Access",
      "1 VR Headsets Included*",
    ],
    buttonText: "Contact us",
    buttonVariant: "contained",
  },
  {
    title: "Midsize Business",
    price: "5000",
    description: [
      "8 Active Projects",
      "16 Archived Projects",
      "Upto 1500 Images",
      "Support Portal Access",
      "2 VR Headsets Included*",
    ],
    buttonText: "Contact us",
    buttonVariant: "contained",
  },
  {
    title: "Large Business",
    price: "8000",
    description: [
      "15 Active Projects",
      "30 Archived Projects",
      "Upto 3000 Images",
      "Support Portal Access",
      "4 VR Headsets Included*",
    ],
    buttonText: "Contact us",
    buttonVariant: "contained",
  },
];

export default function Pricing() {
  const [hoveredTier, setHoveredTier] = React.useState(null);

  const handleMouseEnter = (tierTitle) => {
    setHoveredTier(tierTitle);
  };

  const handleMouseLeave = () => {
    setHoveredTier(null);
  };

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Pricing
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
      {tiers.map((tier) => (
        <Grid item key={tier.title} xs={12} sm={6} md={3}>
          <Card
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 4,
              border: tier.title === hoveredTier ? "1px solid" : undefined,
              borderColor:
                tier.title === hoveredTier ? "primary.main" : undefined,
              background:
                tier.title === hoveredTier
                  ? "linear-gradient(#033363, #021F3B)"
                  : undefined,
              transform:
                tier.title === hoveredTier
                  ? "scale(1.05)"
                  : hoveredTier
                  ? "scale(0.80)"
                  : "scale(1)",
              transition: "transform 0.3s, background 0.3s, border 0.3s",
              "&:hover": {
                cursor: "pointer",
                border: "1px solid",
                borderColor: "primary.main",
                background: "linear-gradient(#033363, #021F3B)",
              },
            }}
            onMouseEnter={() => handleMouseEnter(tier.title)}
            onMouseLeave={handleMouseLeave}
          >
            <CardContent>
              <Box
                sx={{
                  mb: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color:
                    tier.title === hoveredTier ? "primary.contrastText" : "",
                }}
              >
                <Typography component="h3" variant="h6">
                  {tier.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  color:
                    tier.title === hoveredTier
                      ? "primary.contrastText"
                      : undefined,
                }}
              >
                <Typography component="h3" variant="h2">
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(tier.price)}
                </Typography>
                <Typography component="h3" variant="h6">
                  &nbsp; / month
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  color:
                    tier.title === hoveredTier
                      ? "primary.contrastText"
                      : undefined,
                }}
              >
                <Typography component="h3" variant="body2">
                  &nbsp; GST extra as applicable
                </Typography>
              </Box>
              <Divider
                sx={{
                  my: 2,
                  opacity: 0.2,
                  borderColor: "grey.500",
                }}
              />
              {tier.description.map((line) => (
                <Box
                  key={line}
                  sx={{
                    py: 1,
                    display: "flex",
                    gap: 1.5,
                    alignItems: "center",
                  }}
                >
                  <CheckCircleRoundedIcon
                    sx={{
                      width: 20,
                      color:
                        tier.title === hoveredTier
                          ? "primary.light"
                          : "primary.main",
                    }}
                  />
                  <Typography
                    component="text"
                    variant="subtitle2"
                    sx={{
                      color:
                        tier.title === hoveredTier
                          ? "primary.contrastText"
                          : undefined,
                    }}
                  >
                    {line}
                  </Typography>
                </Box>
              ))}
            </CardContent>
            <CardActions>
              <Button
                fullWidth
                variant={tier.buttonVariant}
                component="a"
                href="https://multivrselab.atlassian.net/servicedesk/customer/portal/1"
                target="_blank"
              >
                {tier.buttonText}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
      <Box
        sx={{
          width: { sm: "100%", md: "70%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography variant="body1" color="text.secondary">
        *Users pay a deposit corresponding to the cost of headsets at the time of enrolling in the subscription. This deposit shall be fully paid back to the users in the form of deduction in the monthly subscription cost over a period of 4 months starting from the third month of their paid subscription plan.
        </Typography>
        <Typography variant="body1" color="text.secondary">
          <b>Active Project</b> - These are synonymous with projects being
          currently executed. They can be modified, viewed and shared.
        </Typography>
        <Typography variant="body1" color="text.secondary">
          <b>Archived Project</b> - These are synonymous with projects that are
          completed. Changes are not allowed (only viewed and shared).
        </Typography>
      </Box>
    </Container>
  );
}
