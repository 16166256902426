import React, { useEffect, useState,useContext} from "react";
import { useTheme } from "@emotion/react";
import { AppContext } from "../../AppContext";
import "aframe";
import GPSIcon from "../../icons/gps.png";

export default function FloorPlanAFrame({
  image,
  floorPlanMetadata,
  currentRoomID,
  currentViewID,
}) {
  const {isMinimized, setIsMinimized} = useContext(AppContext);
  const [currentPinPosition, setCurrentPinPosition] = useState(null);
  let theme = useTheme();
  if(Object.keys(theme).length == 0){
    theme = {
      palette: {
        mode: "light",
      },
    }
  }

  // Aspect ratio of the floor plan
  const floorPlanWidth = isMinimized ? 9 : 18; // Adjust width for minimized/maximized
  const floorPlanHeight = isMinimized ? 6 : 12; // Adjust height accordingly (maintain aspect ratio)

  useEffect(() => {
    if (floorPlanMetadata && Object.keys(floorPlanMetadata).length > 0) {
      const keys = Object.keys(floorPlanMetadata);
      for (let key of keys) {
        if (
          floorPlanMetadata[key].roomID === currentRoomID &&
          floorPlanMetadata[key].viewID === currentViewID
        ) {
          setCurrentPinPosition(floorPlanMetadata[key].position);
          return;
        }
      }
    }
    setCurrentPinPosition(null);
  }, [floorPlanMetadata, currentRoomID, currentViewID]);

  const mapToAFrameCoordinates = (xPercent, yPercent) => {
    const x = (xPercent / 100) * floorPlanWidth - floorPlanWidth / 2;
    const y = (yPercent / 100) * floorPlanHeight - floorPlanHeight / 2;
    return { x, y };
  };

  const createMarker = (key, xPercent, yPercent) => {
    const { x, y } = mapToAFrameCoordinates(xPercent, yPercent);
    const markerData = floorPlanMetadata[key];

    return (
      <a-plane
      floordata={`roomID: ${markerData.roomID}; viewID: ${markerData.viewID};roomName: ${markerData.roomName};viewName: ${markerData.viewName}`}
      class="raycastable floordata"
      position={`${x} ${-y} 0.02`}
      width="3"
      height="2"
      material="opacity: 0"
      >
      <a-ring
        borderCircleEl
        radius-inner="0.4"
        radius-outer="0.45"
        color="blue"
        material="opacity: 0.8;transparent: true"
      ></a-ring>
      <a-circle
        circleEl
        radius="0.45"
        color="black"
        class="raycastable"
        floorSpheres
        opacity="0.5"
        number={key}
      ></a-circle>
      <a-text
        textEl
        value={key}
        align="center"
        color="white"
        width="8"
        class="raycastable"
        floorKeys
      ></a-text>
      <a-plane
        custom-rounded-rect={`width: 2.5; height:0.4; radius: 0.1; color: ${
        theme.palette.mode === "light"
          ? "rgba(255, 255, 255, 0.4)"
          : "rgba(0, 0, 0, 0.4)"
        }; opacity: 0.6`}
        position="0 -0.7 0"
        width="3"
        height="0.4"
        color={
        theme.palette.mode === "light"
          ? "rgba(255, 255, 255, 0.4)"
          : "rgba(0, 0, 0, 0.4)"
        }
        material="opacity: 0.8"
      ></a-plane>
      <a-text
        textEl
        value={`${markerData.roomName.toString().substring(0, 5)}:${markerData.viewName.toString().substring(0, 5)}`}
        position="0 -0.7 0"
        align="center"
        color={
        theme.palette.mode === "light"
          ? "rgba(0, 0, 0, 0.87)"
          : "rgba(255, 255, 255, 0.87)"
        }
        width="7"
        class="raycastable"
        floorNames
      ></a-text>
      </a-plane>
    );
  };

  return (
    <a-entity position={isMinimized ? "14 -11 -12" : "0 0 -12"}>
      {/* Floor Plan Image */}
      <a-image
        src={image}
        position="0 0 0"
        width={floorPlanWidth}
        height={floorPlanHeight}
        material="opacity: 0.95"
        floorplan
        class="raycastable floorplan"

      ></a-image>

      {/* Markers */}
      {!isMinimized &&
        floorPlanMetadata &&
        Object.keys(floorPlanMetadata).map((key) =>
          createMarker(
            key,
            floorPlanMetadata[key].position.x,
            floorPlanMetadata[key].position.y
          )
        )}

      {/* Current Pin Marker */}
      {currentPinPosition && isMinimized && (
        <a-image
          position={`${
            mapToAFrameCoordinates(currentPinPosition.x, currentPinPosition.y).x
          } ${-mapToAFrameCoordinates(
            currentPinPosition.x,
            currentPinPosition.y
          ).y} 0`}
          src={GPSIcon}
          material="opacity: 0.8"
          width="0.8"
          height="0.8"
        ></a-image>
      )}
    </a-entity>
  );
}
