import React, { useEffect } from "react";
import MenuItem from "./MenuItem";
import FloorPlanComponent from "./FloorPlanComponent";
import backBlue from "../../icons/backBlue.svg";
import nextBlue from "../../icons/nextBlue.svg";
import upBlue from "../../icons/upBlue.svg";
import homeButton from "../../icons/homeButton.svg";
import marketPlace from "../../icons/shoppingBag.svg";
import previousButtonBlack from "../../icons/previousButtonBlack.svg";
import nextButtonBlack from "../../icons/nextButtonBlack.svg";
import backButtonBlack from "../../icons/backButtonBlack.svg";
import nextButtonWhite from "../../icons/nextButtonWhite.svg";
import backButtonWhite from "../../icons/backButtonWhite.svg";
import previousButtonWhite from "../../icons/previousButtonWhite.svg";
//import all images from public folder in icons object
// import icons from "../../public/";

const designerIcon = "/icons/architect.png";
const projectIcon = "/icons/apartment-building.png";
const floorIcon = "/icons/002-stairs.png";
const roomIcon = "/icons/room.png";
const viewsIcon = "/icons/searching.png";

const createMenu = ({
  isDesktop,
  rows = 1,
  columns = 3,
  itemGap = 4, // Gap between items
  columnGap = 4, // Gap between columns
  columnWidth = 4, // Width of each column
  menuButtonPositions = {
    // Custom midpoint positions for buttons
    previous: { x: -10, y: 12, z: -20 },
    next: { x: 10, y: 12, z: -20 },
    hide: { x: 0, y: -11, z: -20 },
    back: { x: -12, y: 12, z: -20 },
    marketPlace: { x: 12, y: 12, z: -20 },
    PageNumbersPosition: { x: 0, y:-17.1, z:-20 },
  },
  maxItemsPerPage = rows * columns,
  displaySection, // Items to display
  currentSection, // Current section name
  currentPage, // Current page number
  setSelectedID, // Function to set selected ID
  menuPosition = "0 0 -10", // Default menu position
  textWidth = 20, // Text width for each item
  itemWidth = 7, // Default item width
  itemHeight = 7, // Default item height
  thumbnails,
  currentRoom,
  currentRoomName,
  currentProjectName,
  currentFloorName,
  currentFloor,
  currentDesigner,
  currentView,
  currentViewName,
  isLoading = false,
  theme,
  hasFloorPlan,
  floorPlanImage,
  floorPlanMetadata,
  floorSelectedID,
  setFloorSelectedID,
  isMarketPlace,
}) => {
  const alphabetSet = "1234";
  const menuWidth =
    Math.ceil(maxItemsPerPage / rows) * (columnWidth + columnGap) - columnGap;
  const centerOffset = menuWidth / 2;

  const currentItems = displaySection
    ? Object.entries(displaySection).slice(
        currentPage * maxItemsPerPage,
        (currentPage + 1) * maxItemsPerPage
      )
    : [];

  const calculateWidth = (text) => itemWidth || text.length * 0.5;
  const calculateHeight = (text) => itemHeight || text.split("\n").length * 1.5;

  const headTitle = (() => {
    var title1 = "";
    var title2 = "";
    switch (currentSection) {
      case "Designer":
        title1 = "";
        title2 = "Designer";
        break;

      case "Project":
        title1 = "";
        title2 = currentDesigner;
        break;

      case "Floor":
        title1 = `${currentDesigner} . ${currentProjectName}`;
        title2 = currentFloorName;
        break;

      case "Room":
        title1 = `${currentDesigner} . ${currentProjectName} . ${currentFloorName}`;
        title2 = currentRoomName;
        break;

      case "Views":
        title1 = currentViewName
          ? `${currentDesigner} . ${currentProjectName} . ${currentFloorName} . ${currentRoomName}`
          : `${currentDesigner} . ${currentProjectName} . ${currentFloorName}`;
        title2 = currentViewName ? currentViewName : currentRoomName;
        break;

      default:
        title1 = "";
        title2 = currentSection;
    }
    return { title1, title2 };
  })();


  return (
    <a-entity position={menuPosition}>
      {displaySection && currentSection != null && (
        <>
          {!(isDesktop && isMarketPlace )&& (
            <>
              <MenuItem
                text={
                  (headTitle.title1 ? `${headTitle.title1}\n` : "") +
                  headTitle.title2
                }
                position="0 12 -20"
                width={28}
                height={4}
                uniqueID={"Title"}
                textWidth={textWidth + 3}
                setSelectedID={setSelectedID}
                selectable={false}
                primaryTextColor={
                  theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)"
                }
                primaryPlaneColor={
                  theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)"
                }
              />
            </>
          )}

          {!isDesktop && isMarketPlace && (
            <MenuItem
              uniqueID={"Home"}
              position={
                isLoading
                  ? `1000 1000 1000`
                  : `${menuButtonPositions.marketPlace.x} ${menuButtonPositions.marketPlace.y} ${menuButtonPositions.marketPlace.z}`
              }
              width="2"
              height="2"
              setSelectedID={setSelectedID}
              selectable={true}
              primaryTextColor={
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)"
              }
              primaryPlaneColor={
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)"
              }
              textWidth={textWidth}
              className={"menu raycastable"}
              secondaryPlaneColor={
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(255, 255, 255, 0.87)"
              }
              secondaryTextColor={
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)"
              }
              image={homeButton}
            />
          )}

          {!isDesktop && !isMarketPlace && (
            <MenuItem
              uniqueID={"MarketPlace"}
              position={
                isLoading
                  ? `1000 1000 1000`
                  : `${menuButtonPositions.marketPlace.x} ${menuButtonPositions.marketPlace.y} ${menuButtonPositions.marketPlace.z}`
              }
              width="2"
              height="2"
              setSelectedID={setSelectedID}
              selectable={true}
              primaryTextColor={
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)"
              }
              primaryPlaneColor={
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)"
              }
              textWidth={textWidth}
              className={"menu raycastable"}
              secondaryPlaneColor={
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(255, 255, 255, 0.87)"
              }
              secondaryTextColor={
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)"
              }
              image={marketPlace}
            />
          )}

          {/* Back Icon */}
          {currentSection != "Designer" && (
            <MenuItem
              uniqueID={"Back"}
              position={
                isLoading
                  ? `1000 1000 1000`
                  : `${menuButtonPositions.back.x} ${menuButtonPositions.back.y} ${menuButtonPositions.back.z}`
              }
              width="2"
              height="2"
              setSelectedID={setSelectedID}
              selectable={true}
              primaryTextColor={
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)"
              }
              secondaryPlaneColor={
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(255, 255, 255, 0.87)"
              }
              secondaryTextColor={
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)"
              }
              primaryPlaneColor={
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(255, 255, 255, 0.87)"
              }
              textWidth={textWidth}
              className={"menu raycastable"}
              image={(isMarketPlace && isDesktop) ? (theme.palette.mode=="light"?backButtonBlack:backButtonWhite) : upBlue}
            />
          )}

          {/* Previous icon */}
          {displaySection &&
            (Object.entries(displaySection).length > maxItemsPerPage ||
              (currentSection == "Views" &&
                Object.entries(displaySection).length > 1)) && (
              <MenuItem
                uniqueID={"Previous"}
                position={
                  isLoading
                    ? `1000 1000 1000`
                    : `${menuButtonPositions.previous.x} ${menuButtonPositions.previous.y} ${menuButtonPositions.previous.z}`
                }
                width="2"
                height="2"
                setSelectedID={setSelectedID}
                selectable={true}
                primaryTextColor={
                  theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)"
                }
                primaryPlaneColor={
                  theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)"
                }
                textWidth={textWidth}
                className={"menu raycastable"}
                secondaryPlaneColor={
                  theme.palette.mode === "light"
                    ? "rgba(0, 0, 0, 0.87)"
                    : "rgba(255, 255, 255, 0.87)"
                }
                secondaryTextColor={
                  theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)"
                }
                image={
                  (isMarketPlace && isDesktop )? (theme.palette.mode=="light"?previousButtonBlack:previousButtonWhite) : backBlue
                }
              />
            )}

              {/* Page numbers */}
          {displaySection && Object.entries(displaySection).length > maxItemsPerPage && isMarketPlace && isDesktop && (
            <MenuItem
              text={`Page ${currentPage + 1} of ${Math.ceil(Object.entries(displaySection).length / maxItemsPerPage)}`}
              position={menuButtonPositions.PageNumbersPosition.x + " " + menuButtonPositions.PageNumbersPosition.y + " " + menuButtonPositions.PageNumbersPosition.z}
              width={10}
              height={1.5}
              textWidth={textWidth}
              setSelectedID={setSelectedID}
              selectable={false}
              uniqueID={"PageNumbers"}
              primaryTextColor={
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)"
              }
              secondaryPlaneColor={
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(255, 255, 255, 0.87)"
              }
              secondaryTextColor={
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)"
              }
              primaryPlaneColor={
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(255, 255, 255, 0.87)"
              }
            />
          )}

            {/* Next icon */}
          {displaySection &&
            (Object.entries(displaySection).length > maxItemsPerPage ||
              (currentSection == "Views" &&
                Object.entries(displaySection).length > 1)) && (
              <MenuItem
                uniqueID={"Next"}
                position={
                  isLoading
                    ? `1000 1000 1000`
                    : `${menuButtonPositions.next.x} ${menuButtonPositions.next.y} ${menuButtonPositions.next.z}`
                }
                width="2"
                height="2"
                setSelectedID={setSelectedID}
                selectable={true}
                primaryTextColor={
                  theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)"
                }
                primaryPlaneColor={
                  theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)"
                }
                textWidth={textWidth}
                className={"menu raycastable"}
                secondaryPlaneColor={
                  theme.palette.mode === "light"
                    ? "rgba(0, 0, 0, 0.87)"
                    : "rgba(255, 255, 255, 0.87)"
                }
                secondaryTextColor={
                  theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)"
                }
                image={(isMarketPlace && isDesktop) ? (theme.palette.mode=="light"?nextButtonBlack : nextButtonWhite) : nextBlue}
              />
            )}

          {!currentView &&currentItems && currentItems.length > 0 &&
            !hasFloorPlan &&
            currentItems.map(([key, value], index) => {
              const totalItems = currentItems.length;
              const columnIndex = index % columns; // Fill items horizontally (row-first)
              const rowIndex = Math.floor(index / columns); // Move to next row after filling a row

              const totalWidth =
                totalItems > 0
                  ? Math.min(columns, totalItems) * (columnWidth + columnGap) -
                    columnGap
                  : 0;
              const centerAdjustment = totalWidth / 2 - (centerOffset || 0) - 2;

              // Calculate item positions
              const xPos =
                columnIndex * (columnWidth + columnGap) - centerOffset;
              const yPos = isMarketPlace
                ? 3 - rowIndex * (itemHeight + itemGap) + 10
                : 3 - rowIndex * (itemHeight + itemGap);
              const imagePath = `/${
                thumbnails && thumbnails[key] && thumbnails[key].icon
              }`;
              const alphabetLetter = alphabetSet.split("")[index % 26];

              // Adjust xPos for centering based on the number of items
              const centeredXPos = xPos - centerAdjustment;
              return thumbnails && thumbnails[key] && thumbnails[key].icon ? (
                <MenuItem
                  key={key}
                  uniqueID={key}
                  text={value}
                  position={`${centeredXPos} ${yPos} -20`} // Use centeredXPos
                  width={calculateWidth(value)}
                  height={calculateHeight(value)}
                  setSelectedID={setSelectedID}
                  selectable={true}
                  primaryTextColor={
                    theme.palette.mode === "light"
                      ? "rgba(255, 255, 255, 0.4)"
                      : "rgba(0, 0, 0, 0.4)"
                  }
                  secondaryPlaneColor={
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.87)"
                      : "rgba(255, 255, 255, 0.87)"
                  }
                  secondaryTextColor={
                    theme.palette.mode === "light"
                      ? "rgba(255, 255, 255, 0.4)"
                      : "rgba(0, 0, 0, 0.4)"
                  }
                  primaryPlaneColor={
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.87)"
                      : "rgba(255, 255, 255, 0.87)"
                  }
                  textWidth={textWidth}
                  className={"menu raycastable"}
                  image={imagePath}
                  isUserItem={true}
                  alphabetId={alphabetLetter}
                  showAlphabetId={isDesktop && isMarketPlace ? false : true}
                />
              ) : (
                <MenuItem
                  key={key}
                  uniqueID={key}
                  text={value}
                  position={`${centeredXPos} ${yPos} -20`}
                  width={calculateWidth(value)}
                  height={calculateHeight(value)}
                  setSelectedID={setSelectedID}
                  selectable={true}
                  primaryTextColor={
                    theme.palette.mode === "light"
                      ? "rgba(255, 255, 255, 0.4)"
                      : "rgba(0, 0, 0, 0.4)"
                  }
                  secondaryPlaneColor={
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.87)"
                      : "rgba(255, 255, 255, 0.87)"
                  }
                  secondaryTextColor={
                    theme.palette.mode === "light"
                      ? "rgba(255, 255, 255, 0.4)"
                      : "rgba(0, 0, 0, 0.4)"
                  }
                  primaryPlaneColor={
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.87)"
                      : "rgba(255, 255, 255, 0.87)"
                  }
                  textWidth={textWidth}
                  className={"menu raycastable"}
                  isUserItem={true}
                  alphabetId={alphabetLetter}
                  image={
                    currentSection === "Designer"
                      ? designerIcon
                      : currentSection === "Project"
                      ? projectIcon
                      : currentSection === "Floor"
                      ? floorIcon
                      : currentSection === "Room"
                      ? roomIcon
                      : viewsIcon
                  }
                  showAlphabetId={isDesktop && isMarketPlace ? false : true}
                />
              );
            })}
          {hasFloorPlan && (
            <FloorPlanComponent
              image={floorPlanImage}
              floorPlanMetadata={floorPlanMetadata}
              currentRoomID={currentRoom}
              currentViewID={currentView}
              floorSelectedID={floorSelectedID}
              setFloorSelectedID={setFloorSelectedID}
            />
          )}

          {Object.entries(currentItems).length == 0 && (
            <MenuItem
              text={`No items to display\nPlease ask your designer to share`}
              position="0 4 -20"
              width={"Please ask your designer to share".length * 0.5}
              height="2"
              textWidth={20}
              setSelectedID={setSelectedID}
              selectable={false}
              uniqueID={"NoItems"}
              primaryTextColor={
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)"
              }
              secondaryPlaneColor={
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(255, 255, 255, 0.87)"
              }
              secondaryTextColor={
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)"
              }
              primaryPlaneColor={
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(255, 255, 255, 0.87)"
              }
            />
          )}
          {!isDesktop && currentSection == "Views" && (
            <MenuItem
              uniqueID={"HideMenu"}
              text="Hide Menu"
              position={`${menuButtonPositions.hide.x} ${menuButtonPositions.hide.y} ${menuButtonPositions.hide.z}`}
              width="5"
              height="2"
              setSelectedID={setSelectedID}
              selectable={true}
              primaryTextColor={
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)"
              }
              secondaryPlaneColor={
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(255, 255, 255, 0.87)"
              }
              secondaryTextColor={
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)"
              }
              primaryPlaneColor={
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(255, 255, 255, 0.87)"
              }
              textWidth={textWidth}
              className={"menu raycastable"}
            />
          )}
        </>
      )}
    </a-entity>
  );
};

export default createMenu;
