import React, { useState, useEffect, useRef, useContext } from "react";
import "aframe";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { ref } from "firebase/storage";
import { storage } from "../authentication/Firebase";
import { witAIToken } from "../constants";
import axios from "axios";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { URL, MQTTOptions } from "../constants";
import mqtt from "mqtt";
import MenuItem from "./MenuItem";
import { AppContext } from "../../AppContext";
import commonFunctions from "../CommonComponents/commonFunctions";
import NameValuePairs from "./NameValuePairsDisplay";
import createMenu from "./QuestMenu";
import initializeControllerEventListeners from "./aframeControllerEventListener";
import { useTheme } from "@mui/material/styles";
import spiral_rotate from "../../icons/spiral_rotate.svg";
import cardboard_cursor from "../../icons/cursor_cardboard.png";
import closeCircleBlueIcon from "../../icons/close_circle_blue.svg";
import {
  ConvertPathIntoRawPath,
  getFile,
} from "../CloudStorage/cloudStorageAbstraction";
import ACompass from "../CommonComponents/ACompass";

let ffmpeg;
let audioContext;
let analyser;
let dataArray;
let animationFrameId;
let recorder;
let chunks = [];
const VOLUME_THRESHOLD = 0; // Define the threshold as a separate variable
let source = null;
function QuestPage({ isDesktop = false, token = null, activateCardboard }) {
  const {
    setSelectedID,
    selectedID,
    currentSphereNumber,
    setCurrentSphereNumber,
    currentSection,
    setCurrentSection,
    currentlyRecording,
    setCurrentlyRecording,
    displayIndex,
    setDisplayIndex,
    toggleState,
    setToggleState,
    currentView,
    setCurrentView,
    numberMatch,
    setNumberMatch,
    isMuted,
    setIsMuted,
    currentViewName,
    setCurrentViewName,
    currentRoomName,
    setCurrentRoomName,
    currentFloorName,
    setCurrentFloorName,
    currentProjectName,
    setCurrentProjectName,
    localSalientDirection,
    setLocalSalientDirection,
    localDirectionOfSalientDirection,
    setLocalUpDirection,
    localUpDirection,
    setLocalDirectionOfSalientDirection,
    floorSelectedID,
    setFloorSelectedID,
    isMinimized,
    setIsMinimized,
    isMarketPlace,
    setIsMarketPlace,
    isLoading,
    setIsLoading,
  } = useContext(AppContext);

  const baseURL = URL.baseURL;
  const skyRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const [connectStatus, setConnectStatus] = useState(false);
  const [ffmpegLoaded, setFfmpegLoaded] = useState(false);
  const [fs, setFs] = useState(null);
  const [customerMail, setCustomerMail] = useState(null);
  const [currentDesignerMail, setCurrentDesignerMail] = useState(null);
  const [displaySection, setDisplaySection] = useState(null);
  const [currentDesigner, setCurrentDesigner] = useState("");
  const [currentProject, setCurrentProject] = useState("");
  const [currentFloor, setCurrentFloor] = useState("");
  const [currentRoom, setCurrentRoom] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [hasFloorPlan, setHasFloorPlan] = useState(false);
  const [floorPlanImage, setFloorPlanImage] = useState(null);
  const [floorPlanMetadata, setFloorPlanMetadata] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [currentSphereData, setCurrentSphereData] = useState(null);
  const [showMenu, setShowMenu] = useState(true);
  const [thumbnails, setThumbnails] = useState(null);
  const [dontShowkeys, setdontShowkeys] = useState([]);
  const [isComment, setIsComment] = useState(false);
  const [customerHelpText, setCustomerHelpText] = useState(
    "Loading, please wait..."
  );

  const theme = useTheme();
  const alphabetSet = "1234";
  let stream = null;
  const muteIcon =
    theme.palette.mode === "dark"
      ? "/icons/mute_dark.svg"
      : "/icons/mute_light.svg";
  const unmuteIcon =
    theme.palette.mode === "dark"
      ? "/icons/unmute_dark.svg"
      : "/icons/unmute_light.svg";

  useEffect(() => {
    //to not show the keys
    setdontShowkeys(["description"]);
    const getStream = async () => {
      try {
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      } catch {}
    };

    getStream();
  }, []);
  useEffect(() => {
    load();
  }, [fs]);
  function load() {
    setIsLoading(true);
    if (fs != null) {
      const keys = Object.keys(fs);
      const designerList = {};
      const thumbnails = {};
      for (let key of keys) {
        designerList[key] = fs[key].name;
        thumbnails[key] = fs[key].thumbnail;
      }
      setDisplaySection(designerList);
      setCurrentRoom(null);
      setCurrentProject(null);
      setCurrentDesigner(null);
      setThumbnails(thumbnails);
      setCurrentSection("Designer");
    }
  }

  // function sanitizeKeys(obj) {
  //   if (obj !== Object(obj) || Array.isArray(obj)) return obj;

  //   const sanitizedObj = {};
  //   Object.keys(obj).forEach((key) => {
  //     const sanitizedKey = key.toLowerCase().replace(/[^a-z]/g, "");
  //     sanitizedObj[sanitizedKey] = sanitizeKeys(obj[key]);
  //   });
  //   return sanitizedObj;
  // }

  const previousSrcRef = useRef("");
  useEffect(() => {
    const skyElement = document.querySelector("a-sky");
    if (skyElement) {
      const currentSrc = skyElement.getAttribute("src");

      // Check if src has changed
      if (currentSrc !== previousSrcRef.current) {
        previousSrcRef.current = currentSrc; // Update the previous src
      }
    }
  }, []);

  async function loadImage(selectedRef) {
    setIsLoading(true);
    var img;
    // Remove all previous circle elements (optional)
    try {
      Array.prototype.slice
        .call(document.querySelectorAll("[normal-to-camera]"))
        .forEach(function (item) {
          item.remove();
        });
    } catch (e) {
      console.log(e);
    }

    const rawFilePath = ConvertPathIntoRawPath(selectedRef);
    // Fetch the file (raw data) [For PNGs, we are getting a Uint8Array]
    const rawFetchedFile = await getFile(rawFilePath);

    // Check if rawFetchedFile is a Uint8Array
    if (rawFetchedFile instanceof Uint8Array) {
      // Convert the Uint8Array to a Blob (specifying the MIME type as image/png)
      const blob = new Blob([rawFetchedFile], { type: "image/png" });
      img = new Image();
      // Create a FileReader to read the Blob into an image
      const reader = new FileReader();

      reader.onloadend = async function () {
        // Once the file is loaded, use it as the source for the <a-sky> element
        const newSky = document.createElement("a-sky");
        newSky.setAttribute("class", "clickable");
        newSky.setAttribute("src", reader.result); // The result is a data URL
        newSky.setAttribute("rotation", "0 -90 0");
        // Replace the existing <a-sky> element with the new one
        sceneRef.current.replaceChild(newSky, skyRef.current);
        skyRef.current = newSky;
        //remove .png from the selectedRef
        selectedRef = selectedRef.replace(".png", "");
        const metaDataPath =
          ConvertPathIntoRawPath(selectedRef).slice() + "-metadata.json";
        const metadataFileWeGot = await getFile(metaDataPath);
        //convert this metadata file to a JSON
        const metadataFileAsJSON = JSON.parse(metadataFileWeGot);
        setMetadata(metadataFileAsJSON);
        if (hasFloorPlan) {
          setIsMinimized(true);
        }
        setIsLoading(false);
      };
      reader.readAsDataURL(blob);
    } else {
      console.error("Error fetching image URL");
    }

    // If the image fails to load
    if (img) {
      img.onerror = function () {
        TTS("Ask your designer to upload the image for this room!");
        console.error("Error loading image");
        const newSky = document.createElement("a-sky");
        newSky.setAttribute("class", "clickable");
        newSky.setAttribute("src", "/interia_lobby.png");
        newSky.setAttribute("rotation", "0 -90 0");

        // Replace the sky with a default image on error
        sceneRef.current.replaceChild(newSky, skyRef.current);
        skyRef.current = newSky;

        setMetadata(null);
        setIsLoading(false);
      };
    }
  }

  function findUserDataWithEmail(data, targetEmail) {
    for (const userId in data) {
      if (data[userId].email === targetEmail) {
        return { userId, details: data[userId] };
      }
    }
    return null;
  }
  async function getSharedProjectsCustomer(email) {
    let sharedProjectsCustomerData = {};

    try {
      // Retrieve and parse the JSON file for shared projects
      const fileContent = await getFile(`${email}/sharedProjectsCust.json`);
      if (fileContent) {
        const editedData = JSON.parse(fileContent);
        const usersList = await JSON.parse(
          await getFile("users/multivrseInteriaUsers.json")
        );

        // Process each email entry
        for (let mail of Object.keys(editedData)) {
          const designerDetails = findUserDataWithEmail(usersList[0], mail);
          let userName;

          if (designerDetails.details.organization) {
            userName =
              designerDetails.details.organization +
              "\n" +
              designerDetails.details.firstname;
          } else {
            userName =
              designerDetails.details.firstname +
              " " +
              designerDetails.details.lastname;
          }

          // Check subscription end date before proceeding
          const subscriptionEndDate = new Date(
            designerDetails.details.subscriptionEndDate
          );
          const currentDate = new Date();

          // If the subscription end date is less than the current date, skip this designer
          if (subscriptionEndDate < currentDate) {
            continue;
          }

          const projIDs = editedData[mail];
          const projects = {};
          const projRef = ref(
            storage,
            baseURL + "/" + mail + "/projectList.json"
          );

          // Retrieve project metadata
          const rawMetadataFile = await getFile(projRef._location.path_);
          const projectListData = JSON.parse(rawMetadataFile);

          for (let projID of Object.keys(projIDs)) {
            // If projID doesn't start with 'P' then skip
            if (!projID.startsWith("P")) {
              continue;
            }
            projects[projID] = projectListData[projID];
          }

          // Don't set name and thumbnail if no projects are shared
          if (Object.keys(projects).length != 0) {
            projects.name = userName;
            projects.thumbnail = projectListData.thumbnail;
            sharedProjectsCustomerData[mail] = projects;
          }
        }
      } else {
        console.error("Shared projects file not found for email:", email);
      }

      setFs(sharedProjectsCustomerData);
    } catch (error) {
      console.error("Error processing shared projects:", error);
      setConnectStatus(false);
    }

    return sharedProjectsCustomerData;
  }

  async function getMarketPlaceProjects() {
    let marketPlaceProjectsData = {};

    try {
      // Retrieve and parse the JSON file for marketplace projects
      const fileContent = await getFile("global/marketPlace.json");
      if (fileContent) {
        const marketPlaceData = JSON.parse(fileContent);
        const usersList = await JSON.parse(
          await getFile("users/multivrseInteriaUsers.json")
        );

        // Process each email entry in the marketplace data
        for (let email of Object.keys(marketPlaceData)) {
          const designerDetails = findUserDataWithEmail(usersList[0], email);
          let userName;

          if (designerDetails.details.organization) {
            userName =
              designerDetails.details.organization +
              "\n" +
              designerDetails.details.firstname;
          } else {
            userName =
              designerDetails.details.firstname +
              " " +
              designerDetails.details.lastname;
          }

          // Check subscription end date before proceeding
          const subscriptionEndDate = new Date(
            designerDetails.details.subscriptionEndDate
          );
          const currentDate = new Date();

          // If the subscription end date is less than the current date, skip this designer
          if (subscriptionEndDate < currentDate) {
            continue;
          }

          const projIDs = marketPlaceData[email];
          const projects = {};
          const projRef = ref(
            storage,
            baseURL + "/" + email + "/projectList.json"
          );

          // Retrieve project metadata
          const rawMetadataFile = await getFile(projRef._location.path_);
          const projectListData = JSON.parse(rawMetadataFile);

          for (let projID of Object.keys(projIDs)) {
            // If projID doesn't start with 'P', skip it
            if (!projID.startsWith("P")) {
              continue;
            }
            projects[projID] = projectListData[projID];
          }

          // Don't set name and thumbnail if no projects are shared
          if (Object.keys(projects).length !== 0) {
            projects.name = userName;
            projects.thumbnail = projectListData.thumbnail;
            marketPlaceProjectsData[email] = projects;
          }
        }
      } else {
        console.error("Marketplace file not found.");
      }

      setFs(marketPlaceProjectsData);
    } catch (error) {
      console.error("Error processing marketplace projects:", error);
      setConnectStatus(false);
    }

    return marketPlaceProjectsData;
  }

  useEffect(() => {
    if (customerMail) {
      setCurrentSphereData(null);
      setIsComment(false);
      const newSky = document.createElement("a-sky");
      newSky.setAttribute("class", "clickable");
      newSky.setAttribute("src", "/interia_lobby.png");
      newSky.setAttribute("rotation", "0 -90 0");

      // Replace the sky with a default image on error
      sceneRef.current.replaceChild(newSky, skyRef.current);
      skyRef.current = newSky;
      setCurrentProject(null);
      setCurrentDesigner(null);
      setCurrentProjectName(null);
      setCurrentDesignerMail(null);
      setCurrentView(null);
      setCurrentRoom(null);
      setCurrentViewName(null);
      setCurrentRoomName(null);
      // setCurrentFloorName(null);
      setCurrentFloor(null);
      setCurrentProjectName(null);
      // setHasFloorPlan(false);
      // setFloorPlanImage(null);
      // setFloorPlanMetadata(null);
      setIsMinimized(false);
      setMetadata(null);
      showDesignersMenu();

      // setDisplayIndex(0);
      // setNumberMatch(null);
      // setFloorSelectedID(null);
      // setLocalSalientDirection(null);
      // setLocalDirectionOfSalientDirection(null);
      // setLocalUpDirection(null);
    }
    if (isMarketPlace && customerMail) {
      getMarketPlaceProjects(customerMail);
    } else if (customerMail && !isMarketPlace) {
      getSharedProjectsCustomer(customerMail);
    }
  }, [customerMail, isMarketPlace]);
  //mqtt code starts

  const [publicIP, setPublicIP] = useState("");
  const [privateIP, setPrivateIP] = useState("");
  const [subscribedTopics, setSubscribedTopics] = useState(new Set());
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if ((isDesktop || activateCardboard) && token) {
      const auth = getAuth();

      signInWithCustomToken(auth, token)
        .then(async (userCredential) => {
          // Signed in
          const user = userCredential.user;
          setCustomerMail(user.email);
          getSharedProjectsCustomer(user.email);
          //get market place projects
        })
        .catch((error) => {});
    }
  }, [token]);

  useEffect(() => {
    if (Object.keys(subscribedTopics).length != 0 || displaySection) {
      setIsLoading(false);
    }
  }, [subscribedTopics, displaySection]);

  useEffect(() => {
    if (!publicIP || !privateIP || isDesktop) return;

    const newClient = mqtt.connect(URL.MQTTServer, MQTTOptions);
    const topic = `${publicIP}`;

    newClient.on("connect", () => {
      if (!connectStatus)
        newClient.subscribe(topic + "/#", { qos: 1 }, (err) => {
          if (err) {
            console.error("Failed to subscribe to topic:", topic, err);
          } else {
          }
        });
    });

    newClient.on("message", (topic, message) => {
      // Check for the specific message and extract the code
      const messageJSON = JSON.parse(message.toString());
      const { token, username, privateIP } = messageJSON;
      // Check if received data is already in subscribedTopics
      let found = false;
      subscribedTopics.forEach((element) => {
        if (element.username === username) {
          found = true;
        }
      });
      if (!found) {
        setSubscribedTopics((prevTopics) => [
          ...prevTopics,
          { token, username, privateIP },
        ]);
      }
    });

    newClient.on("error", (err) => {});

    if (connectStatus && subscribedTopics.length > 0) {
      newClient.unsubscribe(topic + "/#", (err) => {
        if (err) {
          console.error("Failed to unsubscribe from topic:", topic, err);
        } else {
        }
      });

      TTS("Connection established");
      //sign in with custom token
      const auth = getAuth();
      const token = subscribedTopics[currentIndex].token;

      signInWithCustomToken(auth, token)
        .then(async (userCredential) => {
          // Signed in
          setIsLoading(true);
          const user = userCredential.user;
          setCustomerMail(user.email);

          getSharedProjectsCustomer(user.email);
          newClient.publish(
            publicIP,
            JSON.stringify({
              stop: subscribedTopics[currentIndex].privateIP,
            }),
            { qos: 1 },
            (err) => {
              if (err) {
                console.error("Failed to publish message:", err);
              }
            }
          );
          // Handle beforeunload to publish a message
          const handleBeforeUnload = (event) => {
            try {
              newClient.publish(
                `${publicIP}/${subscribedTopics[currentIndex].privateIP}`, // Adjust the topic as needed
                JSON.stringify({ message: "SimutopiaMobileReload" }), // Customize the message as needed
                { qos: 1 },
                (err) => {
                  if (err) {
                    console.error(
                      "Failed to publish before unload message:",
                      err
                    );
                  } else {
                  }
                }
              );
            } catch (err) {}
          };
          window.addEventListener("beforeunload", handleBeforeUnload);
        })
        .catch((error) => {
          //window.location.reload();
          setConnectStatus(false);
        });
    }

    // Clean up
    return () => {
      if (newClient) {
        newClient.end();
      }
    };
  }, [publicIP, privateIP, connectStatus, subscribedTopics]);

  const TTS = async (text) => {
    if (isMuted || (isMarketPlace && isDesktop)) {
      return;
    }
    try {
      // Send POST request to Wit.ai API

      const response = await axios.post(
        "https://api.wit.ai/synthesize?v=20240304",
        { q: text, voice: "Rebecca" },
        {
          headers: {
            Authorization: "Bearer " + witAIToken, // Bearer token for authorization
            "Content-Type": "application/json",
            Accept: "audio/pcm16", // Correct MIME type for the request
          },
          responseType: "arraybuffer", // We need the response as a raw binary stream
        }
      );
      // Step 1: Create an AudioContext
      const audioContext = new window.AudioContext();
      // Step 2: Convert PCM16 Data to Float32
      function convertPCM16ToFloat32(buffer) {
        const dataView = new DataView(buffer);
        const float32Array = new Float32Array(buffer.byteLength / 2);
        for (let i = 0; i < float32Array.length; i++) {
          const int16 = dataView.getInt16(i * 2, true); // little-endian
          float32Array[i] = int16 / 32768; // convert to float32
        }
        return float32Array;
      }
      const sampleRate = 23500; // adjust to your audio data's sample rate
      const numberOfChannels = 1; // adjust to your audio data's number of channels
      const audioDataFloat32 = convertPCM16ToFloat32(response.data);
      const audioBuffer = audioContext.createBuffer(
        numberOfChannels,
        audioDataFloat32.length,
        sampleRate
      );
      audioBuffer.copyToChannel(audioDataFloat32, 0);
      if (source != null) source.stop();
      source = audioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContext.destination);
      source.start(0);
    } catch (error) {
      console.error("Error fetching audio:", error);
    }
  };

  const loadFFmpeg = async () => {
    if (ffmpegLoaded == false) {
      const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";

      try {
        const coreURL = await toBlobURL(
          `${baseURL}/ffmpeg-core.js`,
          "text/javascript"
        );
        const wasmURL = await toBlobURL(
          `${baseURL}/ffmpeg-core.wasm`,
          "application/wasm"
        );

        ffmpeg = new FFmpeg();
        await ffmpeg
          .load({
            coreURL: coreURL,
            wasmURL: wasmURL,
          })
          .then(() => {
            setFfmpegLoaded(true);
          });
      } catch (error) {
        console.error("Error loading FFmpeg:", error);
      }
    }
  };

  useEffect(() => {
    skyRef.current.setAttribute("src", "/interia_lobby.png");
    commonFunctions.fetchPublicIpAddress(setPublicIP);
    commonFunctions.fetchPrivateIpAddress(setPrivateIP);
    loadFFmpeg();
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === " ") {
      //hide the menu on control key press
      setShowMenu((prevShowMenu) => !prevShowMenu);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  if (!window.AFRAME.components["right-controllor"]) {
    window.AFRAME.registerComponent("right-controllor", {
      init: function () {
        // Swapped gripdown with triggertouchstart/triggertouchend
        this.el.addEventListener("gripdown", (e) => {
          startMonitoring();
        });
        this.el.addEventListener("gripup", (e) => {
          stopMonitoring();
        });
        this.el.addEventListener("triggertouchstart", (e) => {
          const intersectedEls = e.target.components.raycaster.intersectedEls;
          if (intersectedEls.length > 0) {
            const intersectedEl = intersectedEls[0];

            if (intersectedEl.classList.contains("menu")) {
              var uniqueID = intersectedEl.getAttribute("uniqueID");
              setSelectedID(uniqueID);
            }
            if (intersectedEl.classList.contains("metadata")) {
              const sphereNumber = intersectedEl.getAttribute("sphereNumber");
              setCurrentSphereNumber(sphereNumber);
            }
            if (intersectedEl.classList.contains("floorplan")) {
              setIsMinimized((prev) => !prev);
            }
            if (intersectedEl.classList.contains("floordata")) {
              const roomID = intersectedEl.components.floordata.data.roomID;
              const viewID = intersectedEl.components.floordata.data.viewID;
              goToViewThroughFloorPlan(roomID, viewID);
            }
          }
        });
      },
    });
  }

  if (!window.AFRAME.components["floordata"]) {
    window.AFRAME.registerComponent("floordata", {
      schema: {
        roomID: { type: "string" },
        viewID: { type: "string" },
        roomName: { type: "string" },
        viewName: { type: "string" },
      },
      init: function () {
        this.el.addEventListener("click", () => {
          goToViewThroughFloorPlan(this.data.roomID, this.data.viewID);
        });
        //hover effect
        this.el.addEventListener("mouseenter", () => {
          //scale up the floor data
          this.el.setAttribute("scale", "1.2 1.2 1.2");
        });
        this.el.addEventListener("mouseleave", () => {
          //scale down the floor data
          this.el.setAttribute("scale", "1 1 1");
        });
      },
    });
  }
  if (!window.AFRAME.components["floorplan"]) {
    window.AFRAME.registerComponent("floorplan", {
      init: function () {
        this.el.addEventListener("click", () => {
          setIsMinimized((prev) => !prev);
        });
      },
    });
  }

  function goToViewThroughFloorPlan(roomID, viewID) {
    setCurrentSection("Room");
    setSelectedID(roomID);
    setTimeout(() => {
      setSelectedID(viewID);
    }, 0);
  }

  useEffect(() => {
    initializeControllerEventListeners();
  }, []);
  if (!window.AFRAME.components["scene"]) {
    window.AFRAME.registerComponent("scene", {
      init: function () {
        const sceneEl = this.el; // Reference to the entire a-scene

        sceneEl.addEventListener("mousemove", (e) => {
          try {
            // Hide default cursor
            if (activateCardboard) {
              document.querySelector(".a-canvas").style.cursor = "none";
              document.body.style.cursor = "none";
              // Step 1: Normalize device coordinates (NDC)
              const ndcX = (e.clientX / window.innerWidth) * 2 - 1;
              const ndcY = -(e.clientY / window.innerHeight) * 2 + 1;

              // Step 2: Get the camera and cursor elements
              const cameraEl = document.querySelector("a-camera");
              const cursor = document.getElementById("cardboard_cursor");

              if (!cameraEl || !cursor) {
                console.error("Camera or cursor not found in the scene.");
                return;
              }

              // Step 3: Get the camera's world position and orientation
              const cameraObject3D = cameraEl.getObject3D("camera");
              const cameraWorldPosition = new window.THREE.Vector3();
              const cameraWorldDirection = new window.THREE.Vector3();

              cameraObject3D.getWorldPosition(cameraWorldPosition);
              cameraObject3D.getWorldDirection(cameraWorldDirection);

              // Step 4: Define the plane at z = -20 in camera's local space
              const planeZ = new window.THREE.Plane(
                new window.THREE.Vector3(0, 0, 1), // Normal pointing in camera's local z-axis
                21 // Distance from camera along local z-axis
              );

              // Transform the plane to world space
              planeZ.applyMatrix4(cameraObject3D.matrixWorld);

              // Step 5: Set up the raycaster
              const raycaster = new window.THREE.Raycaster();
              raycaster.setFromCamera({ x: ndcX, y: ndcY }, cameraObject3D);

              // Step 6: Intersect the ray with the transformed plane
              const intersectionPoint = new window.THREE.Vector3();
              raycaster.ray.intersectPlane(planeZ, intersectionPoint);

              //convert this point inn a-scene such that to camera
              const intersectionPointInScene = intersectionPoint
                .clone()
                .applyMatrix4(cameraObject3D.matrixWorldInverse);

              // Step 7: Update the cursor position
              cursor.setAttribute("position", {
                x: intersectionPointInScene.x,
                y: intersectionPointInScene.y,
                z: intersectionPointInScene.z,
              });

              // if (intersectionPoint) {
              //   // Update the cursor position to the intersection point
              //   cursor.setAttribute("position", {
              //     x: intersectionPoint.x,
              //     y: intersectionPoint.y,
              //     z: intersectionPoint.z,
              //   });
              // }
            }
          } catch (error) {
            console.error("Error in mousemove event", error);
          }
        });

        this.handlers = {
          buttondown: function (evt) {
            if (activateCardboard) {
              setToggleState((prev) => !prev);
            }
          },
        };
      },

      play: function () {
        const controlsEl = document.querySelector("[button-controls]");
        controlsEl.addEventListener("buttondown", this.handlers.buttondown);
      },

      pause: function () {
        const controlsEl = document.querySelector("[button-controls]");
        if (controlsEl)
          controlsEl.removeEventListener(
            "buttondown",
            this.handlers.buttondown
          );
      },
    });
  }

  useEffect(() => {
    toggleState ? startMonitoring() : stopMonitoring();
  }, [toggleState]);

  const startMonitoring = async () => {
    if (currentlyRecording || !ffmpeg.loaded) return;

    try {
      if (stream == null) {
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      }
      chunks = [];
      recorder = new MediaRecorder(stream);

      recorder.onstart = () => {
        setCurrentlyRecording(true);
      };

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      recorder.onstop = async () => {
        const audioBlob = new Blob(chunks, { type: "audio/webm" });

        try {
          if (audioBlob.size == 0) {
            setCurrentlyRecording(false);
            return;
          }
          await convertWebmToMp3(audioBlob);
        } catch (err) {
          console.error(err.message);
        } finally {
          chunks = [];
          setCurrentlyRecording(false);
        }
      };

      audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const mediaStreamSource = audioContext.createMediaStreamSource(stream);
      analyser = audioContext.createAnalyser();
      mediaStreamSource.connect(analyser);
      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      dataArray = new Uint8Array(bufferLength);
      draw();

      recorder.start();
    } catch (err) {}
  };

  const stopMonitoring = () => {
    if (recorder && recorder.state === "recording") {
      recorder.stop();
      setCurrentlyRecording(false);
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      if (audioContext) {
        audioContext.close();
      }
    }
  };

  const draw = () => {
    if (!currentlyRecording) return;

    analyser.getByteTimeDomainData(dataArray);

    let sum = 0;
    for (let i = 0; i < dataArray.length; i++) {
      const v = dataArray[i] / 128.0;
      sum += Math.abs(v - 1);
    }

    const volume = (sum / dataArray.length) * 100;

    if (volume > VOLUME_THRESHOLD) {
      document.body.classList.add("speaking");
      if (recorder.state === "recording") {
        setTimeout(() => {
          if (recorder && recorder.state === "recording") {
            recorder.stop();
            setCurrentlyRecording(false);
            document.body.classList.remove("speaking");
          }
        }, 3000);
      } else {
        setCurrentlyRecording(true);
        recorder.start();
      }
    }

    animationFrameId = requestAnimationFrame(draw);
  };

  const convertWebmToMp3 = async (webmBlob) => {
    try {
      //check for errors in webmBlob
      if (webmBlob.size == 0) {
        // checkSpeechCommands("");
        return;
      }
      const webmFile = new File([webmBlob], "input.webm");
      const file = await fetchFile(webmFile);
      await ffmpeg.writeFile("input.webm", file);
      await ffmpeg.exec(["-i", "input.webm", "output.mp3"]);
      const mp3Data = await ffmpeg.readFile("output.mp3");
      const mp3Blob = new Blob([mp3Data.buffer], { type: "audio/mp3" });
      sendAudioToWit(mp3Blob);
      await ffmpeg.deleteFile("input.webm");
      await ffmpeg.deleteFile("output.mp3");
      //clear memory
      return mp3Blob;
    } catch (err) {
      console.error(err);
      setCurrentlyRecording(false);

      ffmpeg.terminate();
      ffmpeg = null;
      setFfmpegLoaded(false);
      await loadFFmpeg();
      return new Blob();
    }
  };

  const sendAudioToWit = async (audioBlob) => {
    try {
      const response = await fetch("https://api.wit.ai/dictation?v=20240304", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${witAIToken}`,
          "Content-Type": "audio/mpeg3",
        },
        body: audioBlob,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const text = await response.text();
      const jsonObjects = text.toString().split('"is_final": true,');
      const finalTranscriptionPart =
        jsonObjects[jsonObjects.length - 1].split('"text":')[1];
      const finalTranscription = finalTranscriptionPart.split(",\n")[0];

      checkSpeechCommands(finalTranscription);
    } catch (error) {
      console.error("Error sending audio to Wit.ai:", error);
    }
  };

  const checkSpeechCommands = (finalTranscription) => {
    finalTranscription = finalTranscription.toLowerCase();
    finalTranscription = finalTranscription.replace(/[^a-zA-Z0-9 ]/g, "");
    finalTranscription = textToNumber(finalTranscription);

    const cleanedTranscription = finalTranscription.replace(/\s+/g, "");
    // Extract the numeric part from the cleaned transcription
    const numberMatch = cleanedTranscription.match(/[0-9]+/);

    if (!connectStatus && finalTranscription.includes("yes")) {
      setSelectedID("Yes");
      return;
    }
    if (!connectStatus && finalTranscription.includes("no")) {
      setSelectedID("No");
      return;
    }

    if (
      finalTranscription.match(/designer\s+menu/i) ||
      finalTranscription.match(/designers\s+menu/i)
    ) {
      showDesignersMenu();
      return;
    } else if (
      finalTranscription.match(/project\s+menu/i) ||
      finalTranscription.match(/projects\s+menu/i)
    ) {
      showProjectsMenu();
      return;
    } else if (
      finalTranscription.match(/floor\s+menu/i) ||
      finalTranscription.match(/floors\s+menu/i)
    ) {
      showFloorsMenu();
      return;
    } else if (
      finalTranscription.match(/room\s+menu/i) ||
      finalTranscription.match(/rooms\s+menu/i)
    ) {
      showRoomMenu();
      return;
    } else if (
      finalTranscription.match(/view\s+menu/i) ||
      finalTranscription.match(/views\s+menu/i)
    ) {
      showViewsMenu();
      return;
    } else if (
      finalTranscription.match(/show\s+menu/i) ||
      finalTranscription.match(/open\s+menu/i)
    ) {
      setSelectedID("ShowMenu");
      return;
    } else if (
      finalTranscription.match(/hide\s+menu/i) ||
      finalTranscription.match(/hiding\s+menu/i) ||
      finalTranscription.match(/close\s+menu/i) ||
      finalTranscription.match(/close\s*the\s*menu/i)
    ) {
      setSelectedID("HideMenu");
      return;
    } else if (
      finalTranscription.match(/back/i) ||
      finalTranscription.match(/go\s+back/i)
    ) {
      setSelectedID("Back");
      setShowMenu(true);
      return;
    } else if (finalTranscription.match(/previous/i)) {
      setSelectedID("Previous");
      return;
    } else if (finalTranscription.match(/next/i)) {
      setSelectedID("Next");
      return;
    } else if (finalTranscription.match(/close/i)) {
      setSelectedID("CloseMetadata");
      return;
    } else if (numberMatch) {
      // const numberMatch = finalTranscription.match(
      //   /(?:data|date|date of)\s+(\d+)/
      // );

      setNumberMatch(numberMatch);
    } else {
      const match = findNameInStringFromArray(
        finalTranscription,
        Object.values(displaySection)
      );

      if (match) {
        const key = Object.keys(displaySection).find(
          (key) => displaySection[key] === match
        );

        setSelectedID(key);
        return;
      }
    }
  };

  useEffect(() => {
    if (currentSection == "Views" && numberMatch) {
      if (!currentView) {
        const cleanedNumber = numberMatch[0]; // Get the first numeric sequence found
        if (alphabetSet.includes(cleanedNumber)) {
          const index = alphabetSet.indexOf(cleanedNumber);

          setDisplayIndex((prev) => index);
        }
        return;
      }

      const number = parseInt(numberMatch[0], 10);

      setCurrentSphereNumber(number);

      return;
    } else if (numberMatch && !currentView) {
      const cleanedNumber = numberMatch[0]; // Get the first numeric sequence found
      if (alphabetSet.includes(cleanedNumber)) {
        const index = alphabetSet.indexOf(cleanedNumber);

        setDisplayIndex((prev) => index);
      }
      return;
    }
  }, [numberMatch]);

  useEffect(() => {
    if (displayIndex != null && displaySection != null) {
      if (
        displayIndex >= 0 &&
        displayIndex < Object.keys(displaySection).length
      ) {
        const key = Object.keys(displaySection)[displayIndex];

        setSelectedID(key);
        return;
      }
    }
  }, [displayIndex, displaySection]);

  //Funciton to find any word in a string that matches to given array
  function findNameInStringFromArray(str, arr) {
    const foundName = arr.find((name) =>
      str
        .replace(/\s/g, "")
        .toLowerCase()
        .includes(name.replace(/\s/g, "").toLowerCase())
    );
    if (foundName) {
      return foundName;
    } else {
      return null;
    }
  }

  function showDesignersMenu() {
    if (connectStatus || isDesktop || activateCardboard) {
      setCurrentView(null);
      load();
      TTS("Showing designers");
    } else {
      TTS("Please connect to your mobile device first");
    }
  }

  function showProjectsMenu() {
    if (
      (connectStatus || isDesktop || activateCardboard) &&
      currentDesigner != null
    ) {
      setCurrentView(null);
      const projectsList = fs[currentDesignerMail];
      const projectList = {};
      const thumbnails = {};
      if (projectsList != null || projectsList != undefined)
        for (let key of Object.keys(projectsList)) {
          if (key != "name" && key != "thumbnail")
            if (typeof projectsList[key] === "object") {
              projectList[key] = projectsList[key].name;
            }
          //check if projectList[key] is an object
          thumbnails[key] = projectsList[key]?.thumbnail;
        }
      setDisplaySection(projectList);
      setThumbnails(thumbnails);
      setSelectedID(null);
      setCurrentSection("Project");
      setCurrentRoom(null);
      setCurrentProject(null);
      TTS("Showing projects");
    } else if (!connectStatus) {
      TTS("Please connect to your mobile device first");
    } else if (currentDesigner == null) {
      TTS("Please select a designer first");
    }
  }

  function showFloorsMenu() {
    if (
      (connectStatus || isDesktop || activateCardboard) &&
      currentProject != null &&
      currentDesigner != null
    ) {
      setCurrentView(null);
      const floorList = {};
      const thumbnails = {};
      const floorData = fs[currentDesignerMail][currentProject];
      for (let key of Object.keys(floorData["floors"])) {
        floorList[key] = floorData["floors"][key].name;
        thumbnails[key] = floorData["floors"][key].thumbnail;
      }
      setDisplaySection(floorList);
      setThumbnails(thumbnails);
      setSelectedID(null);
      setCurrentFloor(null);
      setCurrentSection("Floor");
      TTS("Showing floors");
    } else if (!connectStatus) {
      TTS("Please connect to your mobile device first");
    } else if (currentProject == null) {
      TTS("Please select a project first");
    }
  }

  function showRoomMenu() {
    if (
      (connectStatus || isDesktop || activateCardboard) &&
      currentProject != null &&
      currentDesigner != null &&
      currentFloor != null
    ) {
      setCurrentView(null);
      setMetadata(null);
      setCurrentSphereNumber(0);
      setCurrentSphereData(null);
      const roomList = {};
      const thumbnails = {};

      const roomData =
        fs[currentDesignerMail][currentProject]["floors"][currentFloor][
          "rooms"
        ];
      for (let key of Object.keys(roomData)) {
        roomList[key] = roomData[key].name;
        thumbnails[key] = roomData[key].thumbnail;
      }
      setDisplaySection(roomList);
      setThumbnails(thumbnails);
      setSelectedID(null);
      setCurrentRoom(null);
      setCurrentSection("Room");
      TTS("Showing rooms");
    } else if (!connectStatus) {
      TTS("Please connect to your mobile device first");
    } else if (currentProject == null) {
      TTS("Please select a project first");
    } else if (currentFloor == null) {
      TTS("Please select a floor first");
    }
  }

  function showViewsMenu() {
    if (
      (connectStatus || isDesktop || activateCardboard) &&
      currentRoom != null &&
      currentProject != null &&
      currentDesigner != null &&
      currentFloor != null
    ) {
      setCurrentView(null);
      const viewList = {};
      const viewData =
        fs[currentDesignerMail][currentProject]["floors"][currentFloor][
          "rooms"
        ][currentRoom]["views"];
      for (let key of Object.keys(viewData)) {
        viewList[key] = viewData[key].name;
      }
      setDisplaySection(viewList);
      setSelectedID(null);
      setCurrentSection("Views");
      TTS("Showing views");
    } else if (!connectStatus) {
      TTS("Please connect to your mobile device first");
    } else if (currentRoom == null) {
      TTS("Please select a room first");
    }
  }

  useEffect(() => {
    if (currentProject)
      setCurrentProjectName(fs[currentDesignerMail][currentProject].name);
    else setCurrentProjectName("");
  }, [currentProject]);

  useEffect(() => {
    if (currentFloor) {
      setCurrentFloorName(
        fs[currentDesignerMail][currentProject].floors[currentFloor].name
      );
      setHasFloorPlan(
        fs[currentDesignerMail][currentProject]?.floors[currentFloor]
          ?.hasFloorPlan
      );
      //get floorPlan image
      if (
        fs[currentDesignerMail][currentProject]?.floors[currentFloor]
          ?.hasFloorPlan
      ) {
        const imgPath = ConvertPathIntoRawPath(
          baseURL +
            "/" +
            currentDesignerMail +
            "/" +
            currentProject +
            "/" +
            currentFloor +
            "/" +
            currentFloor +
            ".png"
        );
        const dataPath = ConvertPathIntoRawPath(
          baseURL +
            "/" +
            currentDesignerMail +
            "/" +
            currentProject +
            "/" +
            currentFloor +
            "/" +
            currentFloor +
            "-metadata.json"
        );
        //fetch the image
        getFile(imgPath)
          .then((rawFile) => {
            // Convert the uint8array to a blob
            if (rawFile instanceof Uint8Array) {
              // Convert the Uint8Array to a Blob (specifying the MIME type as image/png)
              const blob = new Blob([rawFile], { type: "image/png" });
              // Create a FileReader to read the Blob into an image
              const reader = new FileReader();

              reader.onloadend = async function () {
                // Once the file is loaded, set it to floorPlanImage
                setFloorPlanImage(reader.result);
                //get metadata
                const metadataFile = await getFile(dataPath);

                setFloorPlanMetadata(JSON.parse(metadataFile));
              };
              reader.readAsDataURL(blob);
            }
          })
          .catch((error) => {
            console.error("Error fetching image URL:", error);
          });
      }
    } else {
      setHasFloorPlan(false);
      setCurrentFloorName("");
    }
  }, [currentFloor]);

  useEffect(() => {
    if (currentProject && currentRoom && currentFloor)
      setCurrentRoomName(
        fs[currentDesignerMail][currentProject].floors[currentFloor].rooms[
          currentRoom
        ].name
      );
    else setCurrentRoomName("");
  }, [currentRoom, currentFloor]);

  function textToNumber(text) {
    const units = {
      zero: 0,
      one: 1,
      two: 2,
      three: 3,
      four: 4,
      five: 5,
      six: 6,
      seven: 7,
      eight: 8,
      nine: 9,
    };

    const teens = {
      ten: 10,
      eleven: 11,
      twelve: 12,
      thirteen: 13,
      fourteen: 14,
      fifteen: 15,
      sixteen: 16,
      seventeen: 17,
      eighteen: 18,
      nineteen: 19,
    };

    const tens = {
      twenty: 20,
      thirty: 30,
      forty: 40,
      fifty: 50,
      sixty: 60,
      seventy: 70,
      eighty: 80,
      ninety: 90,
    };

    const scales = {
      hundred: 100,
      thousand: 1000,
      million: 1000000,
    };

    function parseNumber(words) {
      let result = 0;
      let current = 0;

      for (let word of words) {
        if (units[word] !== undefined) {
          current += units[word];
        } else if (teens[word] !== undefined) {
          current += teens[word];
        } else if (tens[word] !== undefined) {
          current += tens[word];
        } else if (scales[word] !== undefined) {
          current *= scales[word];
          result += current;
          current = 0;
        }
      }

      return result + current;
    }

    const words = text.toLowerCase().split(" ");
    let numberArray = [];
    let buffer = [];

    for (let word of words) {
      if (
        units[word] !== undefined ||
        teens[word] !== undefined ||
        tens[word] !== undefined ||
        scales[word] !== undefined
      ) {
        buffer.push(word);
      } else {
        if (buffer.length > 0) {
          numberArray.push(parseNumber(buffer).toString());
          buffer = [];
        }
        numberArray.push(word);
      }
    }

    if (buffer.length > 0) {
      numberArray.push(parseNumber(buffer).toString());
    }

    return numberArray.join(" ");
  }

  function trimMail(mail) {
    var trimmedmail = "";
    if (mail == null || mail == undefined) return "";
    if (mail.indexOf(".") < mail.indexOf("@")) {
      trimmedmail = mail.split(".")[0];
    } else {
      trimmedmail = mail.split("@")[0];
    }
    return trimmedmail;
  }

  //metadata code starts
  function showSpheres(jsonData) {
    const camera = document.querySelector("[camera]");
    const cameraPosition = new window.THREE.Vector3();
    camera.object3D.getWorldPosition(cameraPosition);
    //MUST REMOVE

    for (var key in jsonData) {
      if (isNaN(key)) {
        continue;
      }

      var planeEl = document.createElement("a-plane");
      var textEl = document.createElement("a-text");

      if (planeEl && jsonData[key].description?.length <= 0) {
        delete jsonData[key].description;
      }

      var borderCircleEl = document.createElement("a-ring");
      var circleEl = document.createElement("a-circle");

      if (planeEl && borderCircleEl && circleEl && textEl) {
        // Set attributes for the border ring
        borderCircleEl.setAttribute("radius-inner", "10"); // Inner radius of the ring (hole size)
        borderCircleEl.setAttribute("radius-outer", "12"); // Outer radius of the ring (overall size)
        borderCircleEl.setAttribute("color", "blue");
        borderCircleEl.setAttribute(
          "material",
          "opacity: 0.8; transparent: true"
        );

        // Set attributes for the circle
        setMultipleAttributes(circleEl, {
          radius: 10,
          class: "raycastable metadata",
          color: "black",
          spheres: true,
          number: key,
          opacity: 0.5,
          sphereNumber: key,
        });
      }

      const intersection = jsonData[key].intersection; // This should be [x, y, z] format

      planeEl.setAttribute(
        "position",
        `${intersection.x} ${intersection.y} ${intersection.z}`
      );
      planeEl.setAttribute("normal-to-camera", true);

      // Set attributes for the text (sphere number)
      setMultipleAttributes(textEl, {
        value: key, // Display the sphere number
        align: "center",
        color: "white",
        width: 300, // Adjust the size of the text to a smaller value for better visibility
        class: "metadata raycastable",
        metadataKeys: true,
      });

      // Append ring and circle to the main plane
      planeEl.appendChild(borderCircleEl);
      planeEl.appendChild(circleEl);
      planeEl.appendChild(textEl);

      document.getElementsByTagName("a-scene")[0].appendChild(planeEl);
    }

    // Register the 'spheres' component if not already registered
    if (!window.AFRAME.components["spheres"]) {
      window.AFRAME.registerComponent("spheres", {
        init: function () {
          this.el.addEventListener("click", (e) => {
            const intersectedEl = e.target;
            const sphereNumber = intersectedEl.getAttribute("number");

            setCurrentSphereNumber(sphereNumber);
          });
          this.el.addEventListener("mouseenter", (e) => {
            const intersectedEl = e.target;
            intersectedEl.setAttribute("color", "red");
          });
          this.el.addEventListener("mouseleave", (e) => {
            const intersectedEl = e.target;
            intersectedEl.setAttribute("color", "black");
          });
        },
      });
    }

    if (!window.AFRAME.components["normal-to-camera"]) {
      window.AFRAME.registerComponent("normal-to-camera", {
        tick: function () {
          const camera = this.el.sceneEl.camera; // Get the camera's object3D
          const plane = this.el.object3D; // Get the plane's object3D

          // Get the camera's forward direction (z-axis in three.js)
          const cameraDirection = new window.THREE.Vector3();
          camera.getWorldDirection(cameraDirection);

          // Position the plane to be normal to the camera’s view direction
          const targetPoint = plane.position.clone().sub(cameraDirection);
          plane.lookAt(targetPoint);

          const cameraPosition = new window.THREE.Vector3();
          camera.getWorldPosition(cameraPosition);

          const radialDistance = plane.position
            .clone()
            .sub(cameraPosition)
            .length();
          const perpendicularDistance = plane.position
            .clone()
            .sub(cameraPosition)
            .dot(cameraDirection);

          const scale = Math.abs(perpendicularDistance / radialDistance);
          plane.scale.set(scale, scale, scale);
        },
      });
    }
  }
  //remove all spheres
  function removeSpheres() {
    var elements = document.querySelectorAll(
      "a-text[metadataKeys], a-circle[spheres],a-plane[normal-to-camera]"
    );
    elements.forEach((el) => {
      el.parentNode.removeChild(el);
    });
  }

  useEffect(() => {
    if (metadata && metadata[currentSphereNumber]) {
      // Change that sphere color

      var selectedSphere = document.querySelector(
        `a-text[value="${currentSphereNumber}"]`
      );

      // Change all spheres color to white
      var spheres = document.querySelectorAll("a-text[metadataKeys]");
      spheres.forEach((sphere) => {
        sphere.setAttribute(
          "color",
          theme.palette.mode === "light"
            ? "rgba(255, 255, 255, 0.4)"
            : "rgba(0, 0, 0, 0.4)"
        );
      });

      if (selectedSphere) {
        selectedSphere.setAttribute("color", "red");
      }

      // Get the metadata object
      const currentMetadata = metadata[currentSphereNumber];

      // Initialize an array for formatted metadata
      const nameValuePairs = [];

      // Capitalize and add the "name" key first if it exists
      //if (currentMetadata.name) {
      //  formattedEntries.push(`Name: ${currentMetadata.name}`);
      //}

      // Format the rest of the metadata, excluding "name"

      if (currentMetadata.isComment) {
        nameValuePairs.push({
          name: "Comment",
          value: currentMetadata.description,
        });
      } else {
        Object.entries(currentMetadata).forEach(([key, value]) => {
          if (
            key !== "intersection" &&
            key !== "isComment" &&
            key !== "description"
          ) {
            nameValuePairs.push({
              name: key,
              value: value,
            });
          }
        });
      }

      // Join the formatted entries into a string
      // var met = formattedEntries.join("\n");

      // if (met === "") {
      //   met = "No metadata available\n";
      // }
      setIsComment(currentMetadata.isComment);
      setCurrentSphereData(nameValuePairs);
      // setShowMenu(false);
    }
  }, [currentSphereNumber]);

  const setMultipleAttributes = (el, attributes) => {
    for (var attr in attributes) {
      el.setAttribute(attr, attributes[attr]);
    }
  };

  useEffect(() => {
    if (metadata) {
      showSpheres(metadata);
    }
    return () => {
      removeSpheres();
    };
  }, [metadata]);

  //menu starts
  const rows = isMarketPlace && isDesktop ? 3 : 1;
  const columns = isMarketPlace && isDesktop ? 6 : 3;
  const maxItemsPerPage = rows * columns;
  const handlePrevious = () => {
    if (currentSection == "Views" && currentView) {
      //go to previous view in the room
      const viewKeys = Object.keys(displaySection);
      const currentIndex = viewKeys.indexOf(currentView);
      const previousIndex =
        (currentIndex - 1 + viewKeys.length) % viewKeys.length;
      setSelectedID(viewKeys[previousIndex]);
      return;
    }
    setCurrentPage((prevPage) => {
      const totalPages = Math.ceil(
        Object.keys(displaySection).length / maxItemsPerPage
      );
      return prevPage > 0 ? prevPage - 1 : totalPages - 1; // Wrap to last page if current is 0
    });
    setSelectedID(null);
  };
  const handleNext = () => {
    if (currentSection == "Views" && currentView) {
      //go to next view in the room
      const viewKeys = Object.keys(displaySection);
      const currentIndex = viewKeys.indexOf(currentView);
      const nextIndex = (currentIndex + 1) % viewKeys.length;
      setSelectedID(viewKeys[nextIndex]);
      return;
    }

    const totalPages = Math.ceil(
      Object.keys(displaySection).length / maxItemsPerPage
    );

    setCurrentPage((prevPage) => {
      return prevPage < totalPages - 1 ? prevPage + 1 : 0; // Wrap to first page if current is last
    });
    setSelectedID(null);
  };
  const handleBack = () => {
    if (currentSection == "Project") {
      showDesignersMenu();
    }
    if (currentSection == "Floor") {
      showProjectsMenu();
    }
    if (currentSection == "Room") {
      showFloorsMenu();
    }
    if (currentSection == "Views") {
      if (hasFloorPlan) {
        setCurrentSection("Room");
        setCurrentView(null);
        setCurrentRoom(null);
        setIsMinimized(false);
        return;
      }
      if (currentView) {
        setCurrentView(null);
        return;
      }
      showRoomMenu();
    }
  };

  useEffect(() => {
    var spheres = document.querySelectorAll("a-text[metadataKeys]");
    spheres?.forEach((sphere) => {
      sphere.setAttribute(
        "color",
        theme.palette.mode === "light"
          ? "rgba(255, 255, 255, 0.4)"
          : "rgba(0, 0, 0, 0.4)"
      );
    });
    setCurrentSphereData(null);
    setDisplayIndex(-1);
    setCurrentSphereNumber(0);
    // setFloorPlanMetadata(null);
    if (selectedID == "Yes") {
      setConnectStatus(true);
      setSelectedID(null);
      return;
    }
    if (selectedID == "No") {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % subscribedTopics.length);
      setSelectedID(null);
      return;
    }
    if (selectedID == "Next") {
      TTS("Showing next page");
      handleNext();
      return;
    }
    if (selectedID == "Previous") {
      TTS("Showing previous page");
      handlePrevious();
      return;
    }
    if (selectedID == "Back") {
      TTS("Going back");
      handleBack();
      setSelectedID(null);
      setCurrentPage(0);
      return;
    }
    if (selectedID == "HideMenu") {
      TTS("Hiding the menu");
      setShowMenu(false);
      setSelectedID(null);
      return;
    }
    if (selectedID == "ShowMenu") {
      TTS("Showing the menu");
      setShowMenu(true);
      setSelectedID(null);
      setCurrentSphereNumber(0);
      return;
    }
    if (selectedID == "MuteButton") {
      setIsMuted((prev) => !prev);
      setSelectedID(null);
      return;
    }
    if (selectedID == "Home") {
      setIsMarketPlace(false);
      setSelectedID(null);
      return;
    }
    if (selectedID == "MarketPlace") {
      setIsMarketPlace(true);
      setSelectedID(null);
      return;
    }
    if (selectedID == "CloseMetadata") {
      setCurrentSphereNumber(0);
      setSelectedID(null);
      return;
    }
    if (currentSection == "Designer" && selectedID != null) {
      setCurrentView(null);
      setCurrentDesignerMail(selectedID);
      setCurrentDesigner(trimMail(selectedID));
      setCurrentSection("Project");
      const projectsList = fs[selectedID];
      const projectList = {};
      const thumbnails = {};
      if (projectsList != null || projectsList != undefined)
        for (let key of Object.keys(projectsList)) {
          if (key != "name" && key != "thumbnail")
            if (typeof projectsList[key] === "object") {
              projectList[key] = projectsList[key].name;
            }
          thumbnails[key] = projectsList[key]?.thumbnail;
        }
      setDisplaySection(projectList);
      setThumbnails(thumbnails);
      TTS(
        "Selected designer " +
          fs[selectedID].name.slice(fs[selectedID].name.indexOf("\n") + 1)
      );
      setSelectedID(null);
      setCurrentPage(0);
      return;
    }
    if (currentSection == "Project" && selectedID != null) {
      setCurrentView(null);
      setCurrentProject(selectedID);
      setCurrentSection("Floor");
      const floorList = {};
      const thumbnails = {};
      const floorData = fs[currentDesignerMail][selectedID];
      for (let key of Object.keys(floorData["floors"])) {
        floorList[key] = floorData["floors"][key].name;
        thumbnails[key] = floorData["floors"][key].thumbnail;
      }
      setDisplaySection(floorList);
      setThumbnails(thumbnails);
      TTS("Selected " + fs[currentDesignerMail][selectedID].name);
      setSelectedID(null);
      setCurrentPage(0);
      return;
    }
    if (currentSection == "Floor" && selectedID != null) {
      setCurrentView(null);
      setCurrentFloor(selectedID);
      setCurrentSection("Room");
      const roomList = {};
      const thumbnails = {};
      const roomData =
        fs[currentDesignerMail][currentProject]["floors"][selectedID]["rooms"];
      for (let key of Object.keys(roomData)) {
        roomList[key] = roomData[key].name;
        thumbnails[key] = roomData[key].thumbnail;
      }
      setDisplaySection(roomList);
      setThumbnails(thumbnails);
      TTS(
        "Selected " +
          fs[currentDesignerMail][currentProject]["floors"][selectedID].name
      );
      setSelectedID(null);
      setCurrentPage(0);
      return;
    }
    if (currentSection == "Room" && selectedID != null) {
      setCurrentView(null);
      setIsMinimized(true);
      setCurrentRoom(selectedID);
      setCurrentSection("Views");

      const roomData =
        fs[currentDesignerMail][currentProject]["floors"][currentFloor][
          "rooms"
        ][selectedID]["views"];
      const roomList = {};
      const thumbnails = {};
      for (let key of Object.keys(roomData)) {
        roomList[key] = roomData[key].name;
        thumbnails[key] = roomData[key].thumbnail;
      }
      setDisplaySection(roomList);
      setThumbnails(thumbnails);
      TTS(
        "Selected " +
          fs[currentDesignerMail][currentProject]["floors"][currentFloor][
            "rooms"
          ][selectedID].name
      );
      setSelectedID(null);
      setCurrentPage(0);
      return;
    }
    if (currentSection == "Views" && selectedID != null) {
      setIsMinimized(true);
      const ref =
        baseURL +
        "/" +
        currentDesignerMail +
        "/" +
        currentProject +
        "/" +
        currentFloor +
        "/" +
        currentRoom +
        "/" +
        selectedID +
        "/" +
        selectedID +
        ".png";
      const selectedViewElement = document.querySelector(
        `[uniqueID="${selectedID}"]`
      );

      if (selectedViewElement)
        selectedViewElement.setAttribute("color", "grey");
      loadImage(ref);
      setCurrentView(selectedID);
      TTS(
        "Selected" +
          fs[currentDesignerMail][currentProject]["floors"][currentFloor][
            "rooms"
          ][currentRoom]["views"][selectedID]?.name
      );
      setCurrentPage(0);
      return;
    }
  }, [selectedID]);

  useEffect(() => {
    if (currentView && currentRoom && currentFloor && currentProject) {
      setCurrentViewName(
        fs[currentDesignerMail][currentProject]["floors"][currentFloor][
          "rooms"
        ][currentRoom]["views"][currentView]?.name
      );
    }
  }, [currentView]);

  const calculateWidth = (text) => {
    let max = 0;
    text.split("\n").forEach((line) => {
      if (line.length > max) {
        max = line.length;
      }
    });
    return max * 0.75;
  };
  const calculateHeight = (text) => text.split("\n").length * 1.5;

  //pointer in cardboard mode
  useEffect(() => {
    if (activateCardboard) {
      document.querySelector("a-scene").addEventListener("loaded", (e) => {
        document.querySelector(".a-canvas").style.cursor = "none";
        document.body.style.cursor = "none";
      });
    }
  }, [activateCardboard]);

  useEffect(() => {
    if (isDesktop) {
      if (currentSection == "Designer") {
        setCustomerHelpText("Click to select items");
      } else if (currentSection == "Project") {
        setCustomerHelpText("Look around by clicking and dragging the mouse");
      } else if (currentSection == "Floor") {
        setCustomerHelpText(
          "Click on the mute/unmute button to mute/unmute the audio"
        );
      } else if (currentSection == "Room") {
        setCustomerHelpText(
          "Click on the mute/unmute button to mute/unmute the audio"
        );
      } else if (currentSection == "Views" && !currentView) {
        setCustomerHelpText("Press the spacebar to show/hide the menu");
      } else if (currentSection == "Views" && currentView) {
        setCustomerHelpText("Click on an item to select it");
      }
    } else {
      if (currentSection == "Designer") {
        setCustomerHelpText(
          "Press the grip button and say the name/number to select an item"
        );
      } else if (currentSection == "Project") {
        setCustomerHelpText(
          "Press the grip button and say the project's name/number to select a project"
        );
      } else if (currentSection == "Floor") {
        setCustomerHelpText(
          "Press the grip button and say the floor's name/number to select a floor"
        );
      } else if (currentSection == "Room") {
        setCustomerHelpText(
          "Select an item using the trigger button to select a room"
        );
      } else if (currentSection == "Views" && !currentView) {
        setCustomerHelpText(
          "Say 'Hide menu' to hide the menu and 'Open menu' to show the menu"
        );
      } else if (currentSection == "Views" && currentView) {
        setCustomerHelpText(
          "Select an item using the trigger button or by saying the number"
        );
      }
    }
  }, [currentSection, currentView]);

  useEffect(() => {
    // If user is in a view
    if (currentView) {
      const rawFilePath = `${currentDesignerMail}/${currentProject}/${currentFloor}/${currentRoom}/${currentView}/${currentView}-metadata.json`;
      getFile(rawFilePath)
        .then((data) => {
          try {
            // Convert to JSON
            const jsonData = JSON.parse(data);

            if (jsonData && typeof jsonData === "object") {
              // Set values with defaults if they are undefined
              setLocalSalientDirection(
                jsonData.salientDirection !== undefined
                  ? jsonData.salientDirection
                  : "North"
              );
              setLocalUpDirection(
                jsonData.upDir && typeof jsonData.upDir === "object"
                  ? new window.THREE.Vector3(
                      jsonData.upDir.x || 0,
                      jsonData.upDir.y || 1,
                      jsonData.upDir.z || 0
                    )
                  : new window.THREE.Vector3(0, 1, 0)
              );
              setLocalDirectionOfSalientDirection(
                jsonData.salDir && typeof jsonData.salDir === "object"
                  ? new window.THREE.Vector3(
                      jsonData.salDir.x || 0,
                      jsonData.salDir.y || 0,
                      jsonData.salDir.z || -1
                    )
                  : new window.THREE.Vector3(0, 0, -1)
              );
            } else {
              throw new Error(
                "Invalid JSON data or JSON data is not an object."
              );
            }
          } catch (error) {
            console.error("Error parsing JSON or invalid data:", error);
            // Set default values on error
            setLocalSalientDirection("North");
            setLocalUpDirection(new window.THREE.Vector3(0, 1, 0));
            setLocalDirectionOfSalientDirection(
              new window.THREE.Vector3(0, 0, -1)
            );
          }
        })
        .catch((error) => {
          console.error("Error in getting raw file for compass:::::::", error);
          // Set default values on fetch error
          setLocalSalientDirection("North");
          setLocalUpDirection(new window.THREE.Vector3(0, 1, 0));
          setLocalDirectionOfSalientDirection(
            new window.THREE.Vector3(0, 0, -1)
          );
        });
    } else {
      // Set default values if no view is present
      setLocalSalientDirection("North");
      setLocalUpDirection(new window.THREE.Vector3(0, 1, 0));
      setLocalDirectionOfSalientDirection(new window.THREE.Vector3(0, 0, -1));
    }
  }, [currentView]);

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      {/* Mui component for the marketplace on desktop */}

      <a-scene
        id="scene"
        scene
        ref={sceneRef}
        link-controls
        vr-mode-ui
        cursor="rayOrigin: mouse;"
        raycaster="objects: .raycastable"
        style={{ width: "100%", height: "100%" }}
        // cardboardModeEnabled={activateCardboard}
        cardboardModeEnabled={true}
        renderer="antialias: true; logarithmicDepthBuffer: true; pixelRatio: window.devicePixelRatio"
      >
        <a-assets>
          <img
            id="close-icon"
            src="https://cdn-icons-png.flaticon.com/512/1828/1828778.png"
            alt="Close Icon"
          />
        </a-assets>

        {/* entity for bluetooth controller event listeners */}
        <a-entity button-controls></a-entity>

        <a-camera
          id="camera"
          ref={cameraRef}
          rotation-reader
          camera
          wasd-controls="acceleration:0"
        >
          {/* {activateCardboard && (
            <a-image
              position="0 0 0"
              id="cardboard_cursor"
              src={cardboard_cursor}
              width="2"
              height="2"
              normal-to-camera
              cursor="rayOrigin: entity"
            />
          )} */}
          <a-image
            src="/recording.png"
            width="2"
            height="2"
            position={currentlyRecording ? "0 -6 -20" : "1000 1000 10000"}
          />
          <a-image
            src={spiral_rotate}
            width="5"
            height="5"
            position={isLoading ? "0 -2 -20" : "1000 1000 10000"}
            animation="property: rotation; from: 0 0 0; to: 0 0 359; loop: true; dur: 1500"
          />
          {Array.isArray(subscribedTopics) &&
            !connectStatus &&
            !isDesktop &&
            !activateCardboard && (
              <>
                <MenuItem
                  text={`Do you want to connect to ${trimMail(
                    subscribedTopics[currentIndex].username
                  )}?`}
                  position="0 3 -20"
                  width="20"
                  height="2"
                  textWidth={20}
                  setSelectedID={setSelectedID}
                  uniqueID={"ConnectMessage"}
                  selectable={false}
                  primaryTextColor={
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.87)"
                      : "rgba(255, 255, 255, 0.87)"
                  }
                  secondaryPlaneColor={
                    theme.palette.mode === "light"
                      ? "rgba(255, 255, 255, 0.4)"
                      : "rgba(0, 0, 0, 0.4)"
                  }
                  secondaryTextColor={
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.87)"
                      : "rgba(255, 255, 255, 0.87)"
                  }
                  primaryPlaneColor={
                    theme.palette.mode === "light"
                      ? "rgba(255, 255, 255, 0.4)"
                      : "rgba(0, 0, 0, 0.4)"
                  }
                />
                <MenuItem
                  uniqueID="Yes"
                  text="Yes"
                  position="-3 -2 -20"
                  width="4"
                  height="2"
                  textWidth={20}
                  setSelectedID={setSelectedID}
                  selectable={true}
                  className={"menu raycastable"}
                  primaryTextColor={
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.87)"
                      : "rgba(255, 255, 255, 0.87)"
                  }
                  secondaryPlaneColor={
                    theme.palette.mode === "light"
                      ? "rgba(255, 255, 255, 0.4)"
                      : "rgba(0, 0, 0, 0.4)"
                  }
                  secondaryTextColor={
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.87)"
                      : "rgba(255, 255, 255, 0.87)"
                  }
                  primaryPlaneColor={
                    theme.palette.mode === "light"
                      ? "rgba(255, 255, 255, 0.4)"
                      : "rgba(0, 0, 0, 0.4)"
                  }
                />
                <MenuItem
                  uniqueID="No"
                  text="No"
                  position="2 -2 -20"
                  width="4"
                  height="2"
                  textWidth={20}
                  setSelectedID={setSelectedID}
                  selectable={true}
                  className={"menu raycastable"}
                  primaryTextColor={
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.87)"
                      : "rgba(255, 255, 255, 0.87)"
                  }
                  secondaryPlaneColor={
                    theme.palette.mode === "light"
                      ? "rgba(255, 255, 255, 0.4)"
                      : "rgba(0, 0, 0, 0.4)"
                  }
                  secondaryTextColor={
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.87)"
                      : "rgba(255, 255, 255, 0.87)"
                  }
                  primaryPlaneColor={
                    theme.palette.mode === "light"
                      ? "rgba(255, 255, 255, 0.4)"
                      : "rgba(0, 0, 0, 0.4)"
                  }
                />
              </>
            )}
          {displaySection &&
            showMenu &&
            createMenu({
              menuPosition: isDesktop ? "0 0 -5" : "0 0 -20",
              rows: rows,
              columns: columns,
              menuButtonPositions: {
                // Custom midpoint positions for buttons
                previous: isMarketPlace
                  ? { x: -10, y: -17, z: -20 }
                  : { x: -10, y: 12, z: -20 },
                next: isMarketPlace
                  ? { x: 10, y: -17, z: -20 }
                  : { x: 10, y: 12, z: -20 },
                hide: isMarketPlace
                  ? { x: 0, y: -12, z: -20 }
                  : { x: 0, y: -11, z: -20 },
                back: isMarketPlace
                  ? { x: -12, y: -17, z: -20 }
                  : { x: -12, y: 12, z: -20 },
                marketPlace: isMarketPlace
                  ? { x: 12, y: -17, z: -20 }
                  : { x: 12, y: 12, z: -20 },
                  PageNumbersPosition: isMarketPlace
                  ? { x: 0, y: -17, z: -20 }
                  : { x: 0, y: 12, z: -20 }
              },
              displaySection,
              currentPage,
              maxItemsPerPage,
              handleNext,
              handlePrevious,
              setSelectedID,
              selectedID,
              currentSection,
              handleBack,
              thumbnails,
              isDesktop,
              currentRoomName,
              currentProjectName,
              currentFloorName,
              currentFloor,
              currentDesigner,
              currentView,
              currentViewName,
              isLoading,
              theme,
              hasFloorPlan,
              floorPlanImage,
              floorPlanMetadata,
              isMinimized,
              setIsMinimized,
              currentRoom,
              setFloorSelectedID,
              floorSelectedID,
              isMarketPlace,
            })}

          {currentSphereData && (
            <NameValuePairs
              menuId={currentSphereNumber.toString()}
              data={currentSphereData}
              isComment={isComment}
              dontShowkeys={dontShowkeys}
              textColor={
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(255, 255, 255, 0.87)"
              }
              backgroundColor={
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)"
              }
              selectable={true}
              uniqueID={"CloseMetadata"}
            />
          )}

          {/* dummy close image to improve the load time of close icon in the NameValuePairsDisplay.js */}
          <a-image
            position="1000 1000 100 "
            src={closeCircleBlueIcon}
          ></a-image>
          {/* mute button */}
          {showMenu && !isMarketPlace && !currentSphereData && (
            <>
              <MenuItem
                uniqueID="customerHelp"
                text={customerHelpText}
                setSelectedID={setSelectedID}
                position={
                  isDesktop
                    ? hasFloorPlan && !isMinimized
                      ? "0 -5 -10"
                      : "0 -2 -10"
                    : hasFloorPlan && !isMinimized
                    ? "0 -9 -20"
                    : "0 -6 -20"
                }
                width="10"
                height="1"
                textWidth={8}
                className={"menu"}
                primaryTextColor={
                  theme.palette.mode === "light"
                    ? "rgba(0, 0, 0, 0.87)"
                    : "rgba(255, 255, 255, 0.87)"
                }
                secondaryPlaneColor={
                  theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)"
                }
                secondaryTextColor={
                  theme.palette.mode === "light"
                    ? "rgba(0, 0, 0, 0.87)"
                    : "rgba(255, 255, 255, 0.87)"
                }
                primaryPlaneColor={
                  theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)"
                }
              />

              {isMuted && (
                // when tts is muted
                <MenuItem
                  image={muteIcon}
                  position={"0 -6 -10"}
                  textWidth={10}
                  uniqueID={"MuteButton"}
                  height={1.5}
                  width={1.5}
                  className={"menu raycastable"}
                  setSelectedID={setSelectedID}
                  selectable={true}
                  primaryTextColor={
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.87)"
                      : "rgba(255, 255, 255, 0.87)"
                  }
                  primaryPlaneColor={
                    theme.palette.mode === "light"
                      ? "rgba(255, 255, 255, 0.4)"
                      : "rgba(0, 0, 0, 0.4)"
                  }
                  secondaryTextColor={
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.87)"
                      : "rgba(255, 255, 255, 0.87)"
                  }
                  secondaryPlaneColor={
                    theme.palette.mode === "light"
                      ? "rgba(255, 255, 255, 0.4)"
                      : "rgba(0, 0, 0, 0.4)"
                  }
                />
              )}
              {!isMuted && ( // when tts is unmuted
                <MenuItem
                  image={unmuteIcon}
                  position={"0 -6 -10"}
                  textWidth={10}
                  uniqueID={"MuteButton"}
                  height={1.5}
                  width={1.5}
                  className={"menu raycastable"}
                  setSelectedID={setSelectedID}
                  selectable={true}
                  primaryTextColor={
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.87)"
                      : "rgba(255, 255, 255, 0.87)"
                  }
                  primaryPlaneColor={
                    theme.palette.mode === "light"
                      ? "rgba(255, 255, 255, 0.4)"
                      : "rgba(0, 0, 0, 0.4)"
                  }
                  secondaryTextColor={
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.87)"
                      : "rgba(255, 255, 255, 0.87)"
                  }
                  secondaryPlaneColor={
                    theme.palette.mode === "light"
                      ? "rgba(255, 255, 255, 0.4)"
                      : "rgba(0, 0, 0, 0.4)"
                  }
                />
              )}
            </>
          )}
        </a-camera>

        <a-sky ref={skyRef} rotation="0 -90 0"></a-sky>
        {showMenu && currentView &&  !currentSphereData && isMinimized && !isLoading && (
          <ACompass
            visible={currentView}
            mode={theme.palette.mode}
            propSalientDirection={localSalientDirection}
            propUpDirection={localUpDirection}
            propDirectionOfSalientDirection={localDirectionOfSalientDirection}
          />
        )}

        {!isDesktop && !activateCardboard && (
          //quest side
          <>
            <a-entity
              right-controllor
              oculus-touch-controls="hand: right"
              id="right-controller"
              raycaster="objects: .menu, .metadata, .navigate, .floorplan, .floordata; far: 10000;showLine: true;direction: -0.5 -1 -2;"
            ></a-entity>

            <a-entity
              id="leftHand"
              hand-tracking-grab-controls="hand: left"
              position="-0.5 0 0"
            ></a-entity>
          </>
        )}
        {activateCardboard && (
          <style>
            {`
              .a-enter-vr-button {
                height: 75px !important;
                width: 75px !important;
                background-color: transparent;
                position: fixed;
                bottom: 0vh;
                left: 50%;
                transform: translateX(-50%);
              }
            `}
          </style>
        )}

        {isDesktop && !activateCardboard && !showMenu && (
          <style>
            {`
            .a-enter-vr-button {
              display: none;
            `}
          </style>
        )}
      </a-scene>
    </div>
  );
}

export default QuestPage;
