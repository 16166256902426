import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { createUserWithEmailAndPassword, setPersistence, browserSessionPersistence } from "firebase/auth";
import { auth } from "../authentication/Firebase";
import IconButton from "@mui/material/IconButton";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import InteriaLogo from "../../icons/interia_logo.svg";
import { URL } from "../constants";
import { ConvertPathIntoRawPath, updateFile, getFile, addFile } from "../CloudStorage/cloudStorageAbstraction";
import { Buffer } from 'buffer';
import { useEffect } from "react";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/" style={{ fontFamily: "Audiowide" }}>
        Interia
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignUp() {
  const [userType, setUserType] = React.useState("Customer");
  const [error, setError] = React.useState(""); // State to hold authentication error
  const [confirmPassword, setConfirmPassword] = React.useState(""); // State for confirm password
  const [darkMode, setDarkMode] = React.useState(() => {
    // Retrieve the mode from localStorage and return the appropriate boolean
    return localStorage.getItem("theme") === "dark";
  });
  const [subscriptionPlan, setSubscriptionPlan] = React.useState(""); // State for subscription plan
  const [subscriptionStartDate, setSubscriptionStartDate] = React.useState(""); // State for subscription start date
  const [subscriptionEndDate, setSubscriptionEndDate] = React.useState(""); // State for subscription end date

  useEffect(() => {
    if(auth.currentUser.email !=" interia@multivrselab.com" && auth.currentUser.email != "ali@des.com"){
       navigate("/signin");
    }
    
   }, []);


  React.useEffect(() => {
    // Update the localStorage whenever darkMode changes
    localStorage.setItem("theme", darkMode ? "dark" : "light");
  }, [darkMode]);

  const navigate = useNavigate();
  const firebaseAuth = auth;

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? "dark" : "light",
        },
      }),
    [darkMode]
  );

  const handleThemeToggle = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const password = data.get("password");
    const confirmPassword = data.get("confirmPassword");

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      await setPersistence(auth, browserSessionPersistence);
      const userCredential = await createUserWithEmailAndPassword(
        firebaseAuth,
        data.get("email"),
        password
      );
      const user = userCredential.user;

      const userProfileData = {
        firstname: data.get("firstname"),
        lastname: data.get("lastname"),
        email: data.get("email"),
        userType: data.get("userType"),
        organization: data.get("organization"),
        subscriptionPlan: data.get("subscriptionPlan"),
        subscriptionStartDate: data.get("subscriptionStartDate"),
        subscriptionEndDate: data.get("subscriptionEndDate"),
      };

      const jsonFileName = "multivrseInteriaUsers.json";
      const jsonFilePath = "users/";

      try {
        // Fetch existing JSON data
        let existingData = [];
        try {
          const fileBuffer = await getFile(jsonFilePath + jsonFileName);
          existingData = JSON.parse(fileBuffer.toString("utf-8"));
        } catch (error) {
          if (error.code === "NoSuchKey") {
            console.error("JSON file not found, initializing a new one.");
          } else {
            throw error;
          }
        }

        // Update the JSON structure
        const updatedData = {
          ...existingData[0], // Merge existing data
          [user.uid]: userProfileData, // Add new user data keyed by UID
        };

        // Wrap the updated data into an array as per the desired structure
        const finalData = [updatedData];

        // Convert updated data to a buffer
        const updatedFileBuffer = Buffer.from(
          JSON.stringify(finalData, null, 2),
          "utf-8"
        );

        // Upload the updated JSON file
        await updateFile(jsonFileName, "users", updatedFileBuffer, false);
      } catch (error) {
        console.error("Error handling JSON file:", error);
        throw error;
      }

      if (data.get("userType") === "Customer") {
        //create a new file for the customer called "sharedProjectsCust.json"
        const customerProjectsFileName = "sharedProjectsCust.json";
        const customerFilePath = ConvertPathIntoRawPath(URL.baseURL + "/" + data.get("email"));
        addFile(customerProjectsFileName, customerFilePath, Buffer.from(JSON.stringify({}), "utf-8"));
        navigate("/customer");
      } else if (data.get("userType") === "Designer") {
        const designerProjectsFileName = "projectList.json";
        const designerFilePath = ConvertPathIntoRawPath(URL.baseURL + "/" + data.get("email"));
        try {
          // Initialize an empty project list for the designer
          const jsonData = { thumbnail: {} };
          const fileBuffer = Buffer.from(JSON.stringify(jsonData, "utf-8"));
          await updateFile(designerProjectsFileName, designerFilePath, fileBuffer, false);
        } catch (error) {
          console.error("Error creating designer project list:", error);
          throw error;
        }
        try{
          //create a new file for the designer called "sharedProjectsDes.json"
          const designerProjectsFileName = "sharedProjectsDes.json";
          const designerFilePath = ConvertPathIntoRawPath(URL.baseURL + "/" + data.get("email"));
          addFile(designerProjectsFileName, designerFilePath, Buffer.from(JSON.stringify({}), "utf-8"));
        }
        catch (error) {
          console.error("Error creating designer shared projects list:", error);
          throw error;
        }
        navigate("/designer");
      }
    } catch (error) {
      const errorMessage = error.message;
      setError(
        errorMessage.substring(
          errorMessage.indexOf("/") + 1,
          errorMessage.length - 2
        )
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Avatar
            alt="logo of Interia"
            src={InteriaLogo}
            sx={{
              width: 150,
              height: 150,
            }}
          />
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <IconButton
            sx={{ position: "absolute", top: 10, right: 10 }}
            onClick={handleThemeToggle}
            color="inherit"
          >
            {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel id="userTypeLabel">Select the type of user</InputLabel>
                <Select
                  labelId="userTypeLabel"
                  required
                  fullWidth
                  name="userType"
                  value={userType}
                  onChange={(event) => {
                    setUserType(event.target.value);
                  }}
                >
                  <MenuItem value={"Customer"}>Customer</MenuItem>
                  <MenuItem value={"Designer"}>Designer</MenuItem>
                </Select>
              </Grid>
              {userType === "Designer" && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="organization"
                      label="Organization"
                      name="organization"
                      autoComplete="organization"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel id="subscriptionPlanLabel">Subscription Plan</InputLabel>
                    <Select
                      labelId="subscriptionPlanLabel"
                      required
                      fullWidth
                      name="subscriptionPlan"
                      value={subscriptionPlan}
                      onChange={(event) => {
                        setSubscriptionPlan(event.target.value);
                      }}
                    >
                      <MenuItem value={"Free Trial"}>Free Trial</MenuItem>
                      <MenuItem value={"Small Business"}>Small Business</MenuItem>
                      <MenuItem value={"Midsize Business"}>Midsize Business</MenuItem>
                      <MenuItem value={"Large Business"}>Large Business</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="subscriptionStartDate"
                      label="Subscription Start Date"
                      type="date"
                      id="subscriptionStartDate"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={subscriptionStartDate}
                      onChange={(e) => setSubscriptionStartDate(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="subscriptionEndDate"
                      label="Subscription End Date"
                      type="date"
                      id="subscriptionEndDate"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={subscriptionEndDate}
                      onChange={(e) => setSubscriptionEndDate(e.target.value)}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstname"
                  required
                  fullWidth
                  id="firstname"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  name="lastname"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="new-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
                {error && (
                  <Typography variant="body1" color="error">
                    {error}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/signin" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
