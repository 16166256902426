import React, { useEffect, useState, useContext } from "react";
import ReactDom from "react-dom";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import {
  List,
  ListItem,
  ClickAwayListener,
  Tooltip,
  Typography,
} from "@mui/material";
import { TreeView } from "@mui/x-tree-view/TreeView";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItemButton from "@mui/material/ListItemButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { LayersOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import ListItemIcon from "@mui/material/ListItemIcon";
import Popper from "@mui/material/Popper";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import FilterHdrTwoToneIcon from "@mui/icons-material/FilterHdrTwoTone";
import HomeIcon from "@mui/icons-material/Home";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { AppContext } from "../../AppContext.js";
import { ref } from "firebase/storage";
import { auth } from "../authentication/Firebase";
import {
  URL,
  thumbnailsData,
  thumbnailsCategories,
  imageExtensionList,
} from "../constants";
import { storage } from "../authentication/Firebase.js";
import TreeItemOptions from "./TreeItem.js";
import commonFunctions from "../CommonComponents/commonFunctions.js";
import ThumbnailPopper from "./thumbnailPopper.js";
import FloorPlanPopper from "./FloorPlanPopper.js";
import JSZip from "jszip";
import {
  ConvertPathIntoRawPath,
  getFile,
  addFile,
  createFolder,
  deleteFolder,
  removeFile,
  listAllChildDirectoriesAndFiles,
} from "../CloudStorage/cloudStorageAbstraction.js";
import { Buffer } from "buffer";
import MenuItem from "@mui/material/MenuItem";
import PlanFeatureLimits from "./PlanFeatureLimits.js";
const drawerWidth = 400;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function ProjectBrowser() {
  const {
    metadataRef,
    setMetaDataRef,
    inHome,
    setInHome,
    open,
    setOpen,
    setAnchorE1,
    snackOpen,
    handleSnackClick,
    handleSnackClose,
    action,
    message,
    setMessage,
    setfs,
    fs,
    storageRef,
    setStorageRef,
    useConfirmDialog,
    setConfirmDialogText,
    setEdit,
    setMetadataOpen,
    setData,
    sceneRef,
    skyRef,
    edit,
    isLoading,
    setIsLoading,
    setCurrentProject,
    setCurrentFloor,
    setCurrentRoom,
    setCurrentView,
    currentView,
    currentProject,
    currentFloor,
    currentRoom,
    isMinimized,
    setIsMinimized,
    setFloorPlanOpen,
    floorPlanOpen,
    userSubscriptionPlan,
    userSubscriptionEndDate,
    userSubscriptionStartDate,
  } = useContext(AppContext);
  const theme = useTheme();
  const [focusedProjectfs, setFocusedProjectfs] = useState(null);
  const [selectedProjectID, setSelectedProjectID] = useState(null);
  const [sharedProjects, setSharedProjects] = useState([]);
  const [desAnchorEl, setDesAnchorEl] = useState(null);
  const [email, setEmail] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [desOpen, setDesOpen] = useState(false);
  const [selectedMail, setSelectedMail] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [customerOpen, setCustomerOpen] = useState(false);
  const [customerAnchorEl, setCustomerAnchorEl] = useState(null);
  const [checked, setChecked] = React.useState([-1]);
  const [projLoading, setProjLoading] = useState(false);
  const [selectedProjectName, setSelectedProjectName] = useState(null);
  const [thumbnailAnchor, setThumbnailAnchor] = useState(null);
  const [thumbnailProjectID, setThumbnailProjectID] = useState(null);
  const [thumbnailFloorID, setThumbnailFloorID] = useState(null);
  const [thumbnailRoomID, setThumbnailRoomID] = useState(null);
  const [thumbnailViewID, setThumbnailViewID] = useState(null);
  const [thumbnailType, setThumbnailType] = useState(null);
  const [floorPlanAnchor, setFloorPlanAnchor] = useState(null);
  const [floorPlanProjectID, setFloorPlanProjectID] = useState(null);
  const [floorPlanFloorID, setFloorPlanFloorID] = useState(null);
  const [pinViewID, setPinViewID] = useState(null);
  const [pinRoomID, setPinRoomID] = useState(null);
  const [deleteAnchorEl, setDeleteAnchorEl] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const [selectionMode, setSelectionMode] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadPercent, setDownloadPercent] = useState(0);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [focusedProject, setFocusedProject] = useState(null);
  const [focusProject, setFocusProject] = useState(null);
  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState(null);
  const [isPublicCheck, setIsPublicCheck] = useState(false);
  const [publicProjects, setPublicProjects] = useState([]);

  const expandNode = (nodeId) => {
    const node = document.querySelector(`[uniqueId="${nodeId}"]`);
    if (node) {
      if (node.getAttribute("aria-expanded") != "true") {
        node.firstChild.click();
      }
    }
  };

  const menuOptions = [
    //conditionally render the label of the context menu
    focusedProject && focusProject
      ? { label: "Display All Projects", action: () => handleDisplayAll() }
      : { label: "Display Only This", action: () => handleDisplayOnlyThis() },
  ];

  //if display only this is clicked and there is already focused project then make the focus project true

  // Function to handle Option 1
  const handleDisplayOnlyThis = () => {
    setFocusProject(true);
    setContextMenuOpen(false); // Close the menu after action
  };

  // Function to handle Option 2
  const handleDisplayAll = () => {
    setFocusProject(false);
    setFocusedProject(null);
    setContextMenuOpen(false); // Close the menu after action
  };
  //display the focused project only when display only this is clicked

  useEffect(() => {
    if (focusedProject && focusProject) {
      var newFs = {
        [focusedProject]: fs[focusedProject],
      };
      setFocusedProjectfs(newFs);
    } else {
      load();
    }
    setProjLoading(false);
  }, [focusedProject, focusProject]);

  useEffect(() => {
    //setting storage reference to current user's email
    var tempRef = ref(storage, "/" + auth.currentUser.email);
    setStorageRef(tempRef);
    load();
  }, [open]);

  useEffect(() => {
    if (inHome === true) {
      handleDesClose();
      //clear selected project,input fields,anchors,emaillist and room count
      setSelectedProjectID(null);
      setAnchorE1(null);
      setEmailList([]);
      setEmail("");
      setDesAnchorEl(null);
      setSelectedMail(null);
      setMetaDataRef("");
      setFloorPlanAnchor(null);
      setFloorPlanOpen(false);
      setCurrentFloor(null);
      setCurrentProject(null);
      setCurrentRoom(null);
      setCurrentView(null);
      setThumbnailAnchor(false)
    }
  }, [inHome]);

  //checkbox toggling function for multi-select in editing projects for customers
  const handleToggleCheckBox = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  //function to handle share project button click
  const handleDesClick = (projID) => {
    //place theanchor where the selected project is
    var element = document.querySelector(`[uniqueId="${projID}"]`);
    setSelectedProjectID(projID);
    setSelectedProjectName(fs[projID].name);
    setDesAnchorEl(element);
    setDesOpen((prev) => !prev);
    //also see if the project is public or not
    setIsPublicCheck(publicProjects &&Object.keys(publicProjects)?.includes(projID));
  };
  //function to handle share project button close
  const handleDesClose = () => {
    setSelectedProjectID(null);
    setEmailList([]);
    setEmail("");
    setDesOpen(false);
    setDesAnchorEl(null);
    setIsPublicCheck(false);
  };
  //function to handle add email button click
  const handleAddEmail = () => {
    if (
      email &&
      !emailList.includes(email) &&
      handleEmail() &&
      email != "" &&
      email != null
    ) {
      setEmailList([...emailList, email]);
      setEmail("");
    }
  };

  const handleEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) return true;
    else return false;
  };
  //function to handle share project to add mails in DB
  const handleDesSubmit = async () => {
    //if current date is before subscription start date or is after subscription end date then show message and return
    if (
      userSubscriptionStartDate &&
      new Date() < new Date(userSubscriptionStartDate)
    ) {
      setMessage(
        `Your subscription didn't start yet. Please wait for your subscription to start to share projects with customers.`
      );
      handleSnackClick();
      return;
    } else if (
      userSubscriptionEndDate &&
      new Date() > new Date(userSubscriptionEndDate)
    ) {
      setMessage(
        `Your subscription has expired. Please renew your subscription to share projects with customers.`
      );
      handleSnackClick();
      return;
    }

    try {
      // Add email to list if valid
      if (email) emailList.push(email.trim());
      setEmail("");
      // Fetch necessary data
      const usersData = JSON.parse(
        await getFile("users/multivrseInteriaUsers.json")
      )[0];
      const existingEmails = new Set(
        Object.keys(usersData).map((key) => usersData[key].email)
      );

      const globalMarketPlaceData = JSON.parse(
        await getFile("global/marketPlace.json")
      );

      // Update public/private project state
      const publicProjects =
        globalMarketPlaceData[auth?.currentUser?.email] || {};
      if (isPublicCheck) {
        publicProjects[selectedProjectID] = selectedProjectName;
        globalMarketPlaceData[auth?.currentUser?.email] = publicProjects;
      } else {
        
        if(globalMarketPlaceData[auth?.currentUser?.email])
        delete globalMarketPlaceData[auth?.currentUser?.email][selectedProjectID];
      }

      await addFile(
        "marketPlace.json",
        "global",
        Buffer.from(JSON.stringify(globalMarketPlaceData))
      );

      await getPublicProjects();

      // Filter and validate emails
      const validEmails = emailList.filter(Boolean);
      if (validEmails.length === 0) {
        setMessage(
          isPublicCheck ? "Project is now public!" : "Project is now private!"
        );
        handleSnackClick();
        handleDesClose();
        return;
      }

      const invalidEmails = validEmails.filter(
        (email) => !existingEmails.has(email)
      );
      if (invalidEmails.length > 0) {
        setMessage(`${invalidEmails.join(", ")} do not exist!`);
        handleSnackClick();
        return;
      }

      // Fetch designer shared projects
      const sharedProjectsDes =
        (await JSON.parse(
          await getFile(`${auth.currentUser.email}/sharedProjectsDes.json`)
        )) || {};

      // Share project with each valid email
      await Promise.all(
        validEmails.map(async (recipientEmail) => {
          const sharedProjectsCus =
            (await JSON.parse(
              await getFile(`${recipientEmail}/sharedProjectsCust.json`)
            )) || {};

          // Update designer's shared data
          sharedProjectsDes[recipientEmail] = {
            ...(sharedProjectsDes[recipientEmail] || {}),
            [selectedProjectID]: selectedProjectName,
          };

          // Update customer's shared data
          sharedProjectsCus[auth.currentUser.email] = {
            ...(sharedProjectsCus[auth.currentUser.email] || {}),
            [selectedProjectID]: selectedProjectName,
          };

          // Save updated data
          await addFile(
            "sharedProjectsDes.json",
            auth.currentUser.email,
            Buffer.from(JSON.stringify(sharedProjectsDes))
          );
          await addFile(
            "sharedProjectsCust.json",
            recipientEmail,
            Buffer.from(JSON.stringify(sharedProjectsCus))
          );
        })
      );

      await getSharedProjects();
      setMessage("Project Shared!");
      handleSnackClick();
      handleDesClose();
    } catch (error) {
      console.error("Error sharing project:", error);
      setMessage("Error sharing project!");
      handleSnackClick();
    }
  };

  //function to handle delete email button click
  const handleDeleteEmail = (emailToDelete) => {
    setEmailList(emailList.filter((email) => email !== emailToDelete));
  };
  // function to handle edit project to edit shared mails in DB
  const handleCusSubmit = async () => {
    try {
      const customerDbPath = `${selectedMail}/sharedProjectsCust.json`;
      const designerDbPath = `${auth.currentUser.email}/sharedProjectsDes.json`;

      // Get current customer data and prepare new project data
      let customerData = JSON.parse(await getFile(customerDbPath)) || {};
      // Get the selected projects from the checked array and make JSON like projID and project name
      const selectedProjects = checked.reduce((acc, index) => {
        acc[projectList[index]] = fs[projectList[index]].name;
        return acc;
      }, {});
      // Update customer data with selected projects
      customerData[auth.currentUser.email] = selectedProjects;

      // Update designer data with selected projects
      let designerData = JSON.parse(await getFile(designerDbPath)) || {};
      designerData[selectedMail] = selectedProjects;

      // Commit changes to the storage
      await addFile(
        "sharedProjectsDes.json",
        auth.currentUser.email,
        Buffer.from(JSON.stringify(designerData))
      );
      await addFile(
        "sharedProjectsCust.json",
        selectedMail,
        Buffer.from(JSON.stringify(customerData))
      );

      await getSharedProjects();
      setCustomerAnchorEl(null);
      setCustomerOpen(false);
      setSelectedMail("");
      setMessage("Share access updated!");
      handleSnackClick();
    } catch (error) {
      console.error("Error updating share access:", error);
      setMessage("Error updating share access!");
      handleSnackClick();
    }
  };
  //function to handle customer popper close
  const handleCusClick = (event, email) => {
    event.preventDefault();
    setSelectedMail(email);

    // Reset checked state
    setChecked([]);

    var updatedChecked = [];
    // Get the list of projects shared with the customer
    var projects = sharedProjects[email] || {};
    //now match the projects with the project list and push the index of the project in the checked array
    projectList.forEach((projID, index) => {
      if (projects[projID]) {
        updatedChecked.push(index);
      }
    });

    setChecked(updatedChecked);
    setCustomerAnchorEl(event.target);
    setCustomerOpen(true);
  };
  //function to update projectList.json
  const uploadJSON = (data, nodeID) => {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: "application/json" });
    const storageRef = ref(storage, URL.baseURL + "/" + auth.currentUser.email);
    setProjLoading(false);
    //upload the file
    const rawPath = ConvertPathIntoRawPath(storageRef._location.path_);
    const jsonBuffer = Buffer.from(json);
    addFile("projectList.json", rawPath, jsonBuffer).then((status) => {
      if (status == 200) {
        setProjLoading(true);
        load();
        setfs(data);
        getSharedProjects();
        if (nodeID) {
          expandNode(nodeID);
        }
        setProjLoading(false);
      } else {
        setProjLoading(false);
        setMessage("Error performing the requested task!");
        handleSnackClick();
      }
    });
    setProjLoading(false);
  };
  //function to get files from storage
  async function getFiles(storageRef) {
    try {
      //path to get the file from :::::: ConvertPathIntoRawPath(storageRef._location.path_)+"/projectList.json"
      const path =
        ConvertPathIntoRawPath(storageRef._location.path_) +
        "/projectList.json";
      const rawFile = await getFile(path);
      const projectListData = JSON.parse(rawFile);
      return projectListData;
    } catch (error) {
      console.error("Error fetching project list:", error);
      return {}; // Return an empty object in case of error
    }
  }
  //function to handle drawer open
  const handleDrawerOpen = () => {
    setOpen(true);
    setAnchorE1(null);
  };
  //function to handle drawer close
  const handleDrawerClose = () => {
    setOpen(false);
    setThumbnailAnchor(false);
    setDesOpen(false);
    setFloorPlanAnchor(null);
    setFloorPlanOpen(false);
  };

  //function to render tree structure of project browser
  const renderTree = (nodes) => {
    if (nodes == null) return null;

    const handleDrop = (e, callback) => {
      e.preventDefault();
      e.target.style.backgroundColor = "transparent";

      if (e.dataTransfer.files.length !== 1) {
        setMessage("Only one file is accepted!");
        handleSnackClick();
        return;
      }

      const file = e.dataTransfer.files[0];
      if (
        file.type.includes("image") ||
        imageExtensionList.includes(file.name.split(".").pop())
      ) {
        callback(file);
      } else {
        setMessage("Only image files are accepted!");
        handleSnackClick();
      }
    };

    const createFileInput = (callback) => {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/*";
      fileInput.multiple = false;
      fileInput.click();
      fileInput.addEventListener("change", (e) => {
        const file = e.target.files[0];
        callback(file);
      });
    };

    const handleContextMenu = (e, projID) => {
      e.preventDefault();
      setContextMenuAnchorEl(e.currentTarget);
      setContextMenuOpen(true);
      setFocusedProject(projID);
    };

    return Object.keys(nodes).map((projID) => {
      const projectName = nodes[projID].name;
      if (projID === "error" || projID === "thumbnail") return null;
      const isPublic = publicProjects && Object.keys(publicProjects)?.includes(projID);
      return (
        <TreeItem
          key={projID}
          nodeId={projID}
          uniqueId={projID}
          label={TreeItemOptions({
            nodeId: projID,
            labelText: projectName,
            labelIcon: HomeIcon,
            treeItemOptions: {
              addFunction: () => createFloor(projID),
              deleteFunction: () => deleteProject(projID),
              shareFunction: () => handleDesClick(projID),
              addTitle: "Floor",
              deleteTitle: "Project",
              editable: true,
              renameFunction: (newName) => renameProject(projID, newName),
              setThumbnailAnchor: setThumbnailAnchor,
              setThumbnailType: () => setThumbnailType("Project"),
              handleSet: () => {
                setThumbnailProjectID(projID);
              },
              isThumbnail: nodes[projID]?.thumbnail?.icon,
              isPublic: isPublic,
            },
            selectionMode: selectionMode,
            setSelectionMode: setSelectionMode,
            setSelectedProjects: setSelectedProjects,
            selectedProjects: selectedProjects,
          })}
          expandIcon={<ChevronRightIcon />}
          collapseIcon={<ExpandMoreIcon />}
          //if the project is expanded then disable context menu
          onContextMenu={(e) => {
            e.preventDefault();
            if (focusedProject && focusProject && projID !== focusedProject)
              return;
            handleContextMenu(e, projID);
          }}
          // sx={{
          //   //blur effect for non focused projects
          //   filter:
          //     focusedProject && focusProject && projID !== focusedProject
          //       ? "blur(2px)"
          //       : "",
          // }}
        >
          {nodes[projID].floors &&
            Object.keys(nodes[projID].floors).map((floorID) => {
              const floorName = nodes[projID].floors[floorID].name;

              return (
                <TreeItem
                  key={projID + floorID}
                  nodeId={projID + floorID}
                  uniqueId={projID + floorID}
                  label={TreeItemOptions({
                    nodeId: floorID,
                    labelText: floorName,
                    labelIcon: LayersOutlined,
                    treeItemOptions: {
                      addFunction: () => createRoom(projID, floorID),
                      deleteFunction: () => deleteFloor(projID, floorID),
                      addTitle: "Room",
                      deleteTitle: "Floor",
                      editable: true,
                      renameFunction: (newName) =>
                        renameFloor(projID, floorID, newName),
                      setThumbnailAnchor: setThumbnailAnchor,
                      setThumbnailType: () => setThumbnailType("Floor"),
                      handleSet: () => {
                        setThumbnailProjectID(projID);
                        setThumbnailFloorID(floorID);
                      },
                      isThumbnail:
                        !!nodes[projID]?.floors?.[floorID]?.thumbnail?.icon,
                      openFloorPlan: () => {
                        setFloorPlanProjectID(projID);
                        setFloorPlanFloorID(floorID);
                      },
                      setFloorPlanAnchor: setFloorPlanAnchor,
                      setFloorPlanOpen: setFloorPlanOpen,
                      hasFloorPlan:
                        nodes[projID]?.floors[floorID]?.hasFloorPlan,
                    },
                    onDrop: (e) =>
                      handleDrop(e, (file) =>
                        createFloorPlan(projID, floorID, file)
                      ),
                  })}
                  expandIcon={<ChevronRightIcon />}
                  collapseIcon={<ExpandMoreIcon />}
                >
                  {nodes[projID].floors[floorID].rooms &&
                    Object.keys(nodes[projID].floors[floorID].rooms).map(
                      (roomID) => {
                        const roomName =
                          nodes[projID].floors[floorID].rooms[roomID].name;

                        return (
                          <TreeItem
                            key={projID + floorID + roomID}
                            nodeId={projID + floorID + roomID}
                            uniqueId={projID + floorID + roomID}
                            label={TreeItemOptions({
                              nodeId: roomID,
                              labelText: roomName,
                              labelIcon: MeetingRoomIcon,
                              treeItemOptions: {
                                addFunction: () =>
                                  createFileInput((file) =>
                                    createView(projID, floorID, roomID, file)
                                  ),
                                deleteFunction: () =>
                                  deleteRoom(projID, floorID, roomID),
                                addTitle: "View",
                                deleteTitle: "Room",
                                editable: true,
                                renameFunction: (newName) =>
                                  renameRoom(projID, floorID, roomID, newName),
                                setThumbnailAnchor: setThumbnailAnchor,
                                setThumbnailType: () =>
                                  setThumbnailType("Room"),
                                handleSet: () => {
                                  setThumbnailProjectID(projID);
                                  setThumbnailFloorID(floorID);
                                  setThumbnailRoomID(roomID);
                                },
                                isThumbnail:
                                  !!nodes[projID]?.floors?.[floorID]?.rooms?.[
                                    roomID
                                  ]?.thumbnail?.icon,
                              },
                              onDrop: (e) =>
                                handleDrop(e, (file) =>
                                  createView(projID, floorID, roomID, file)
                                ),
                            })}
                            expandIcon={<ChevronRightIcon />}
                            collapseIcon={<ExpandMoreIcon />}
                          >
                            {nodes[projID].floors[floorID].rooms[roomID]
                              .views &&
                              Object.keys(
                                nodes[projID].floors[floorID].rooms[roomID]
                                  .views
                              ).map((viewID) => {
                                const viewName =
                                  nodes[projID].floors[floorID].rooms[roomID]
                                    .views[viewID].name;

                                return (
                                  <TreeItem
                                    key={projID + floorID + roomID + viewID}
                                    nodeId={projID + floorID + roomID + viewID}
                                    uniqueId={
                                      projID + floorID + roomID + viewID
                                    }
                                    label={TreeItemOptions({
                                      nodeId: viewID,
                                      labelText: viewName,
                                      labelIcon: FilterHdrTwoToneIcon,
                                      treeItemOptions: {
                                        deleteFunction: () =>
                                          deleteView(
                                            projID,
                                            floorID,
                                            roomID,
                                            viewID
                                          ),
                                        addTitle: "Image",
                                        deleteTitle: "View",
                                        editable: true,
                                        displayPins:
                                          floorPlanProjectID == projID && floorPlanFloorID == floorID,
                                        renameFunction: (newName) =>
                                          renameView(
                                            projID,
                                            floorID,
                                            roomID,
                                            viewID,
                                            newName
                                          ),
                                        setThumbnailAnchor: setThumbnailAnchor,
                                        setThumbnailType: () =>
                                          setThumbnailType("View"),
                                        handleSet: () => {
                                          setThumbnailProjectID(projID);
                                          setThumbnailFloorID(floorID);
                                          setThumbnailRoomID(roomID);
                                          setThumbnailViewID(viewID);
                                        },
                                        isThumbnail:
                                          !!nodes[projID]?.floors?.[floorID]
                                            ?.rooms?.[roomID]?.views?.[viewID]
                                            ?.thumbnail?.icon,
                                        setPinDetails: () => {
                                          setPinViewID(viewID);
                                          setPinRoomID(roomID);
                                        },
                                        floorPlanAnchor: floorPlanAnchor,
                                        isActive:
                                          pinViewID == viewID &&
                                          pinRoomID == roomID &&
                                          projID == floorPlanProjectID &&
                                          floorID == floorPlanFloorID,
                                      },
                                      onDrop: (e) =>
                                        handleDrop(e, (file) =>
                                          createView(
                                            projID,
                                            floorID,
                                            roomID,
                                            file
                                          )
                                        ),
                                    })}
                                    draggable={true}
                                    onDragStart={(e) => {
                                      e.dataTransfer.setData(
                                        "viewID",
                                        `${projID}/${floorID}/${roomID}/${viewID}`
                                      );
                                      setThumbnailAnchor(false);
                                      setDesOpen(false);
                                    }}
                                    onDoubleClick={(e) => {
                                      e.preventDefault();
                                      const selectedView = `${URL.baseURL}/${auth.currentUser.email}/${projID}/${floorID}/${roomID}/${viewID}/${viewID}`;
                                      setCurrentProject(projID);
                                      setCurrentFloor(floorID);
                                      setCurrentRoom(roomID);
                                      setCurrentView(viewID);
                                      commonFunctions.loadImage(
                                        selectedView,
                                        setConfirmDialogText,
                                        confirm,
                                        setEdit,
                                        setMetadataOpen,
                                        setMetaDataRef,
                                        setData,
                                        setMessage,
                                        handleSnackClick,
                                        setOpen,
                                        sceneRef,
                                        skyRef,
                                        edit,
                                        setIsLoading,
                                        setInHome
                                      );
                                      setThumbnailAnchor(false);
                                      setDesOpen(false);
                                    }}
                                  />
                                );
                              })}
                          </TreeItem>
                        );
                      }
                    )}
                </TreeItem>
              );
            })}
        </TreeItem>
      );
    });
  };
  //function to create new project
  const createProject = async () => {
    // Create a new project ID by finding the next available number
    const projectIDs = Object.keys(fs);

    //if current date is before subscription start date or is after subscription end date then show message and return
    if (
      userSubscriptionStartDate &&
      new Date() < new Date(userSubscriptionStartDate)
    ) {
      setMessage(
        `Your subscription didn't start yet. Please wait for your subscription to start to create new projects.`
      );
      handleSnackClick();

      return;
    } else if (
      userSubscriptionEndDate &&
      new Date() > new Date(userSubscriptionEndDate)
    ) {
      setMessage(
        `Your subscription has expired. Please renew your subscription to create new projects.`
      );
      handleSnackClick();
      return;
    }

    if (userSubscriptionPlan) {
      if (
        projectIDs.filter((projID) => projID.startsWith("P")).length >=
        PlanFeatureLimits[userSubscriptionPlan].activeProjects
      ) {
        setMessage(
          `You have reached the limit of active projects for ${userSubscriptionPlan} plan. Please upgrade your plan to create more projects.`
        );
        handleSnackClick();
        return;
      }
    }

    let newProjectID = 1;
    while (projectIDs.includes(`P${newProjectID}`)) {
      newProjectID++;
    }
    // Create a new project object
    const newProject = {
      name: `Project ${newProjectID}`,
      floors: {},
      thumbnail: {},
    };
    // Add the new project to the file system
    var newFS = fs;
    newFS[`P${newProjectID}`] = newProject;
    setProjLoading(true);
    const rawPath = ConvertPathIntoRawPath(storageRef._location.path_);
    createFolder("P" + newProjectID, rawPath).then((status) => {
      if (status == 200) {
        uploadJSON(newFS, `P${newProjectID}`);
        setfs(newFS);
        setMessage("Project Created!");
        handleSnackClick();
      } else {
        setMessage("Error creating project!");
        handleSnackClick();
      }
    });
    setProjLoading(false);
  };
  //function to rename project
  const renameProject = async (projectID, newName) => {
    // Update the project name in the file system
    //check if project name already exists
    if (Object.values(fs).some((item) => item.name == newName)) {
      //get element with unique id equal to projectID
      var element = document.querySelector(`[uniqueId="${projectID}"]`);
      //get element with editableText class
      var editableText = element.querySelector(".editableText");
      //set editable text to old name
      editableText.textContent = fs[projectID].name;
      setMessage("Project name already exists!");
      handleSnackClick();
      return;
    }
    //project name should be only alphabetical with spaces adn no special characters and numbers and between 3-20 characters
    if (!/^[a-zA-Z ]{3,20}$/.test(newName)) {
      //get element with unique id equal to projectID
      var element = document.querySelector(`[uniqueId="${projectID}"]`);
      //get element with editableText class
      var editableText = element.querySelector(".editableText");
      //set editable text to old name
      editableText.textContent = fs[projectID].name;
      setMessage(
        "Project name should be between 3-20 characters and only alphabets!"
      );
      handleSnackClick();
      return;
    }
    //check if the project is shared with any customer and update the project name in shared projects

    await Promise.all(
      Object.keys(sharedProjects).map(async (email) => {
        if (sharedProjects[email][projectID]) {
          try {
            // Fetch existing data
            let customerData =
              JSON.parse(await getFile(email + "/sharedProjectsCust.json")) ||
              {};
            let designerData =
              JSON.parse(
                await getFile(
                  auth.currentUser.email + "/sharedProjectsDes.json"
                )
              ) || {};

            // Ensure nested structures exist for safe updates
            if (!customerData[auth.currentUser.email]) {
              customerData[auth.currentUser.email] = {};
            }
            if (!designerData[email]) {
              designerData[email] = {};
            }

            // Update project names in both customer and designer data
            customerData[auth.currentUser.email][projectID] = newName;
            designerData[email][projectID] = newName;

            // Update designer's shared projects
            await addFile(
              "sharedProjectsDes.json",
              auth.currentUser.email,
              Buffer.from(JSON.stringify(designerData))
            );

            // Update customer's shared projects
            await addFile(
              "sharedProjectsCust.json",
              email,
              Buffer.from(JSON.stringify(customerData))
            );
          } catch (error) {
            console.error(`Failed to update data for ${email}:`, error);
          }
        }
      })
    );
    var newFS = fs;
    newFS[projectID].name = newName;
    // Upload the updated file system to Firebase Storage
    const rawPath = ConvertPathIntoRawPath(storageRef._location.path_);
    uploadJSON(newFS);
    setMessage("Project Renamed!");
    handleSnackClick();
  };
  //function to delete project
  const deleteProject = async (projectID, nuclearDelete = false) => {
    if (currentProject === projectID) {
      setMessage("Cannot delete a project if it's view is currently open!");
      handleSnackClick();
      setProjLoading(false);
      return;
    }

    if (floorPlanOpen && floorPlanProjectID === projectID && fs[projectID]?.floors[floorPlanFloorID]?.hasFloorPlan) {
      setMessage(
        "Cannot delete a project while floor plan is open! Please close the floor plan and try again."
      );
      handleSnackClick();
      return;
    }

    if (metadataRef?.split("/")[1] === projectID && !nuclearDelete) {
      setMessage(
        "Cannot delete a project that is currently open! Please go to Home and try again."
      );
      handleSnackClick();
      return;
    }

    if (!nuclearDelete) {
      //ask for confirmation
      setConfirmDialogText(
        "Are you sure you want to delete this project? This action cannot be undone."
      );
      const result = await confirm();
      if (!result) {
        return;
      }
    }

    // Delete the project from the file system
    var newFS = fs;
    delete newFS[projectID];
    setProjLoading(true);

    // Check if project is shared with any customer and delete it from shared projects
    await Promise.all(
      Object.keys(sharedProjects).map(async (email) => {
        if (sharedProjects[email][projectID]) {
          try {
            // Fetch existing data
            let customerData =
              JSON.parse(await getFile(email + "/sharedProjectsCust.json")) ||
              {};
            let designerData =
              JSON.parse(
                await getFile(
                  auth.currentUser.email + "/sharedProjectsDes.json"
                )
              ) || {};

            // Ensure nested structures exist for safe updates
            if (!customerData[auth.currentUser.email]) {
              customerData[auth.currentUser.email] = {};
            }
            if (!designerData[email]) {
              designerData[email] = {};
            }

            // Delete project from both customer and designer data
            delete customerData[auth.currentUser.email][projectID];
            delete designerData[email][projectID];

            // Update designer's shared projects
            await addFile(
              "sharedProjectsDes.json",
              auth.currentUser.email,
              Buffer.from(JSON.stringify(designerData))
            );

            // Update customer's shared projects
            await addFile(
              "sharedProjectsCust.json",
              email,
              Buffer.from(JSON.stringify(customerData))
            );
          } catch (error) {
            console.error(`Failed to update data for ${email}:`, error);
          }
        }
      })
    );

    const rawPath =
      ConvertPathIntoRawPath(storageRef._location.path_) + "/" + projectID;
    deleteFolder(rawPath).then((status) => {
      if (status == 200) {
        uploadJSON(newFS);
        setMessage("Project Deleted!");
        setDesAnchorEl(null);
        setDesOpen(false);
        if (metadataRef == "") {
          setInHome(true);
        }
        handleSnackClick();
        setProjLoading(false);
      } else {
        setMessage("Error deleting project!");
        handleSnackClick();
        setProjLoading(false);
      }
    });
    setProjLoading(false);
  };
  //function to create floors
  const createFloor = async (projectID) => {
    // Create a new floor ID by finding the next available number
    setProjLoading(true);
    const floorIDs = Object.keys(fs[projectID].floors);
    let newFloorID = 1;
    while (floorIDs.includes(`F${newFloorID}`)) {
      newFloorID++;
    }
    // Create a new floor object
    const newFloor = {
      name: `Floor ${newFloorID}`,
      rooms: {},
      thumbnail: {},
      hasFloorPlan: false,
    };
    // Add the new floor to the project
    var newFS = fs;
    newFS[projectID].floors[`F${newFloorID}`] = newFloor;
    // Upload the updated file system
    createFolder(
      "F" + newFloorID,
      ConvertPathIntoRawPath(storageRef._location.path_) + "/" + projectID
    ).then((status) => {
      if (status == 200) {
        const metadata = {};
        const metadataFileName = "F" + newFloorID + "-metadata.json";
        const metadataBuffer = Buffer.from(JSON.stringify(metadata));
        addFile(
          metadataFileName,
          ConvertPathIntoRawPath(storageRef._location.path_) +
            "/" +
            projectID +
            "/F" +
            newFloorID,
          metadataBuffer
        ).then((status) => {
          if (status == 200) {
            // Upload the updated file system to Firebase Storage
            uploadJSON(newFS, `${projectID}`);
            setfs(newFS);
            setMessage("Floor Plan Created!");
            handleSnackClick();
            setProjLoading(false);
          } else {
            setMessage("Error creating floor plan!");
            handleSnackClick();
            setProjLoading(false);
          }
        });
      } else {
        setMessage("Error creating floor!");
        handleSnackClick();
        setProjLoading(false);
      }
    });
  };
  //function to rename floor
  const renameFloor = async (projectID, floorID, newName) => {
    // Update the floor name in the file system
    //check if floor name already exists
    if (
      Object.values(fs[projectID].floors).some(
        (floor) => floor.name === newName
      )
    ) {
      //get element with unique id equal to projectID
      var element = document.querySelector(
        `[uniqueId="${projectID + floorID}"]`
      );
      //get element with editableText class
      var editableText = element.querySelector(".editableText");
      //set editable text to old name
      editableText.textContent = fs[projectID].floors[floorID].name;
      setMessage("Floor name already exists!");
      handleSnackClick();
      return;
    }
    //floor name should be only alphabetical with spaces adn no special characters and numbers and between 3-20 characters
    if (!/^[a-zA-Z ]{3,20}$/.test(newName)) {
      //get element with unique id equal to projectID
      var element = document.querySelector(
        `[uniqueId="${projectID + floorID}"]`
      );
      //get element with editableText class
      var editableText = element.querySelector(".editableText");
      //set editable text to old name
      editableText.textContent = fs[projectID].floors[floorID].name;
      setMessage(
        "Floor name should be between 3-20 characters and only alphabets!"
      );
      handleSnackClick();
      return;
    }
    var newFS = fs;
    newFS[projectID].floors[floorID].name = newName;
    // Upload the updated file system to Firebase Storage
    uploadJSON(newFS);
    setMessage("Floor Renamed!");
    handleSnackClick();
  };
  //function to delete floor
  const deleteFloor = async (projectID, floorID) => {
    if (currentFloor === floorID && currentProject === projectID) {
      setMessage("Cannot delete a floor if it's view is open!");
      handleSnackClick();
      setProjLoading(false);
      return;
    }

    if (floorPlanOpen) {
      setMessage("Please close the floor plan before deleting the floor!");
      handleSnackClick();
      return;
    }

    setProjLoading(true);
    // Clone the file system
    var newFS = { ...fs };
    delete newFS[projectID].floors[floorID];
    const rawPath =
      ConvertPathIntoRawPath(storageRef._location.path_) +
      "/" +
      projectID +
      "/" +
      floorID;
    deleteFolder(rawPath).then((status) => {
      if (status == 200) {
        uploadJSON(newFS);
        setMessage("Floor Deleted!");
        handleSnackClick();
        setProjLoading(false);
      } else {
        setMessage("Error deleting floor!");
        handleSnackClick();
        setProjLoading(false);
      }
    });
  };
  const createFloorPlan = async (projectID, floorID, file) => {
    setProjLoading(true);
    const newFS = fs;
    newFS[projectID].floors[floorID].hasFloorPlan = true;

    const rawPath =
      ConvertPathIntoRawPath(storageRef._location.path_) +
      "/" +
      projectID +
      "/" +
      floorID;
    const fileName = floorID + ".png";
    try {
      addFile(fileName, rawPath, file).then((status) => {
        if (status == 200) {
          //create metadata for the floor plan
          const metadata = {};
          const metadataFileName = floorID + "-metadata.json";
          const metadataBuffer = Buffer.from(JSON.stringify(metadata));
          addFile(metadataFileName, rawPath, metadataBuffer).then((status) => {
            if (status == 200) {
              // Upload the updated file system to Firebase Storage
              uploadJSON(newFS);
              setfs(newFS);
              setMessage("Floor Plan Created!");
              handleSnackClick();
              setProjLoading(false);
            } else {
              setMessage("Error creating floor plan!");
              handleSnackClick();
              setProjLoading(false);
            }
          });
        } else {
          setMessage("Error creating floor plan!");
          handleSnackClick();
          setProjLoading(false);
        }
      });
    } catch (err) {
      console.error(err);
      setMessage("Error creating floor plan!");
      handleSnackClick();
      setProjLoading(false);
    }
    finally{
      setProjLoading(false);
    }
  };
  const deleteFloorPlan = async (projectID, floorID) => {
    setProjLoading(true);
    const newFS = fs;
    newFS[projectID].floors[floorID].hasFloorPlan = false;

    const rawPath =
      ConvertPathIntoRawPath(storageRef._location.path_) +
      "/" +
      projectID +
      "/" +
      floorID;
    const fileName = floorID + ".png";
    const metadataFileName = floorID + "-metadata.json";

    try {
      const fileStatus = await removeFile(fileName, rawPath);
      if (fileStatus === 200) {
        const metadataStatus = await removeFile(metadataFileName, rawPath);
        if (metadataStatus === 200) {
          uploadJSON(newFS);
          setfs(newFS);
          setMessage("Floor Plan Deleted!");
          handleSnackClick();
        } else {
          setMessage("Error deleting floor plan!");
          handleSnackClick();
        }
      } else {
        setMessage("Error deleting floor plan!");
        handleSnackClick();
      }
    } catch (error) {
      setMessage("Error deleting floor plan!");
      handleSnackClick();
    } finally {
      setProjLoading(false);
    }
  };
  const saveFloorPlanMetadata = async (projectID, floorID, metadata) => {
    setProjLoading(true);
    const rawPath =
      ConvertPathIntoRawPath(storageRef._location.path_) +
      "/" +
      projectID +
      "/" +
      floorID;
    const metadataFileName = floorID + "-metadata.json";
    const metadataBuffer = Buffer.from(JSON.stringify(metadata));
    try {
      const status = await addFile(metadataFileName, rawPath, metadataBuffer);
      if (status == 200) {
        // setMessage("Metadata Saved!");
        // handleSnackClick();
        setProjLoading(false);
      } else {
        setMessage("Error saving metadata!");
        handleSnackClick();
        setProjLoading(false);
      }
    } catch (error) {
      setMessage("Error saving metadata!");
      handleSnackClick();
      setProjLoading(false);
    }finally{
      setProjLoading(true);
    }
  };
  //function to create new room
  const createRoom = async (projectID, floorID) => {
    setProjLoading(true);
    // Create a new room ID by finding the next available number
    const roomIDs = Object.keys(fs[projectID].floors[floorID].rooms);
    let newRoomID = 1;
    while (roomIDs.includes(`R${newRoomID}`)) {
      newRoomID++;
    }
    // Create a new room object
    const newRoom = {
      name: `Room ${newRoomID}`,
      views: {},
      thumbnail: {},
    };
    // Add the new room to the project
    var newFS = fs;
    newFS[projectID].floors[floorID].rooms[`R${newRoomID}`] = newRoom;

    const rawPath =
      ConvertPathIntoRawPath(storageRef._location.path_) +
      "/" +
      projectID +
      "/" +
      floorID;
    createFolder("R" + newRoomID, rawPath).then((status) => {
      if (status == 200) {
        // Upload the updated file system to Firebase Storage
        uploadJSON(newFS, `${projectID + floorID}`);
        setMessage("Room Created!");
        handleSnackClick();
        setProjLoading(false);
      } else {
        setMessage("Error creating room!");
        handleSnackClick();
        setProjLoading(false);
      }
    });
  };
  //function to rename room
  const renameRoom = async (projectID, floorID, roomID, newName) => {
    // Update the room name in the file system
    //check if room name already exists

    if (
      Object.values(fs[projectID].floors[floorID].rooms).some(
        (room) => room.name === newName
      )
    ) {
      //get element with unique id equal to projectID
      var element = document.querySelector(
        `[uniqueId="${projectID + floorID + roomID}"]`
      );
      //get element with editableText class
      var editableText = element.querySelector(".editableText");
      //set editable text to old name
      editableText.textContent =
        fs[projectID].floors[floorID].rooms[roomID].name;
      setMessage("Room name already exists!");
      handleSnackClick();
      return;
    }
    //room name should be only alphabetical with spaces and no special characters and numbers and between 3-20 characters
    if (!/^[a-zA-Z ]{3,20}$/.test(newName)) {
      //get element with unique id equal to projectID
      var element = document.querySelector(
        `[uniqueId="${projectID + floorID + roomID}"]`
      );
      //get element with editableText class
      var editableText = element.querySelector(".editableText");
      //set editable text to old name
      editableText.textContent =
        fs[projectID].floors[floorID].rooms[roomID].name;
      setMessage(
        "Room name should be between 3-20 characters and only alphabets!"
      );
      handleSnackClick();
      return;
    }
    var newFS = fs;
    newFS[projectID].floors[floorID].rooms[roomID].name = newName;
    // Upload the updated file system to Firebase Storage
    uploadJSON(newFS);
    setMessage("Room Renamed!");
    handleSnackClick();
  };
  //function to delete room
  const deleteRoom = async (projectID, floorID, roomID) => {
    if (
      currentRoom === roomID &&
      currentFloor === floorID &&
      currentProject === projectID
    ) {
      setMessage("Cannot delete a room if it's view is currently open!");
      handleSnackClick();
      setProjLoading(false);
      return;
    }

    setProjLoading(true);
    const newFS = fs;
    const rawPath = ConvertPathIntoRawPath(storageRef._location.path_);
    const roomPath = `${projectID}/${floorID}/${roomID}`;
    const floorMetadataPath = `${rawPath}/${projectID}/${floorID}/${floorID}-metadata.json`;

    try {
      const floorMetadataFile = await getFile(floorMetadataPath);
      const floorMetadata = JSON.parse(floorMetadataFile);
      const keys = Object.keys(floorMetadata);

      for (let key of keys) {
        if (floorMetadata[key].roomID === roomID) {
          delete floorMetadata[key];
        }
      }

      const roomStatus = await deleteFolder(`${rawPath}/${roomPath}`);
      if (roomStatus !== 200) throw new Error("Error deleting room");

      const metadataBuffer = Buffer.from(JSON.stringify(floorMetadata));
      const metadataStatus = await addFile(
        `${floorID}-metadata.json`,
        `${rawPath}/${projectID}/${floorID}`,
        metadataBuffer
      );
      if (metadataStatus !== 200)
        throw new Error("Error updating floor metadata");

      delete newFS[projectID].floors[floorID].rooms[roomID];
      uploadJSON(newFS);
      setMessage("Room Deleted!");
      handleSnackClick();
      setInHome(true);
    } catch (error) {
      console.error("Error deleting room:", error);
      setMessage("Error deleting room!");
      handleSnackClick();
    } finally {
      setProjLoading(false);
    }
  };
  //function to create new view
  const createView = async (projectID, floorID, roomID, file) => {
    if (userSubscriptionPlan) {
      var totalCurrentViews = 0;
      //in fs take only the items that are staring with "P"
      var projects = Object.keys(fs).filter((key) => key.startsWith("P"));
      //now iterate over the projects and get the total number of views in all of them together
      projects.forEach((project) => {
        var floors = Object.keys(fs[project].floors);
        floors.forEach((floor) => {
          var rooms = Object.keys(fs[project].floors[floor].rooms);
          rooms.forEach((room) => {
            var views = Object.keys(
              fs[project].floors[floor].rooms[room].views
            );
            totalCurrentViews += views.length;
          });
        });
      });
      if (
        totalCurrentViews >= PlanFeatureLimits[userSubscriptionPlan].imagesLimit
      ) {
        setMessage(
          `You have reached the limit of active views for ${userSubscriptionPlan} plan. Please upgrade your plan to create more views.`
        );
        handleSnackClick();
        return;
      }
    }

    // Create a new view ID by finding the next available number
    const viewIDs = Object.keys(
      fs[projectID].floors[floorID].rooms[roomID].views
    );
    let newViewID = 1;
    while (viewIDs.includes(`V${newViewID}`)) {
      newViewID++;
    }
    // Create a new view object
    const newView = { name: `view${newViewID}`, thumbnail: {} };
    //create element to show progress
    var ele = document.createElement("div");
    var element = document.querySelector(
      `[uniqueId="${projectID + floorID + roomID}"]`
    );
    element.appendChild(ele);
    ele.id = "circularprogress";
    setIsFileUploading(true);
    var ele = document.getElementById("circularprogress");
    const node = (
      <Stack
        id="circular"
        direction="row"
        alignItems="center"
        alignContent="left"
        spacing={2}
      >
        <CircularProgress size="15px" />
        <p>Uploading {file.name}</p>
      </Stack>
    );
    ReactDom.render(node, ele);
    const rawPath =
      ConvertPathIntoRawPath(storageRef._location.path_) +
      "/" +
      projectID +
      "/" +
      floorID +
      "/" +
      roomID;
    createFolder("V" + newViewID, rawPath).then((status) => {
      if (status == 200) {
        //upload the file
        addFile(
          "V" + newViewID + ".png",
          rawPath + "/V" + newViewID,
          file
        ).then((status) => {
          if (status == 200) {
            //create metadata for the uploaded file
            const metadata = {};
            //upload metadata to a new file in firebase storage
            //convert into buffer for metadata
            const metadataBuffer = Buffer.from(JSON.stringify(metadata));

            addFile(
              "V" + newViewID + "-metadata.json",
              rawPath + "/V" + newViewID,
              metadataBuffer
            ).then((status) => {
              if (status == 200) {
                // Add the new view to the room
                var newFS = fs;
                newFS[projectID].floors[floorID].rooms[roomID].views[
                  `V${newViewID}`
                ] = newView;
                // Upload the updated file system to Firebase Storage
                uploadJSON(newFS, `${projectID}${floorID}${roomID}`);
                setMessage("View Created!");
                handleSnackClick();
                ReactDom.unmountComponentAtNode(ele);
                if (document.getElementById("circularprogress"))
                  document.getElementById("circularprogress").remove();
                setIsFileUploading(false);
              } else {
                setMessage("Error creating view!");
                handleSnackClick();
                ReactDom.unmountComponentAtNode(ele);
                if (document.getElementById("circularprogress"))
                  document.getElementById("circularprogress").remove();
                setIsFileUploading(false);
              }
            });
          } else {
            setMessage("Error creating view!");
            handleSnackClick();
            ReactDom.unmountComponentAtNode(ele);
            if (document.getElementById("circularprogress"))
              document.getElementById("circularprogress").remove();
            setIsFileUploading(false);
          }
        });
      } else {
        setMessage("Error creating view!");
        handleSnackClick();
        ReactDom.unmountComponentAtNode(ele);
        document.getElementById("circularprogress").remove();
        setIsFileUploading(false);
      }
    });
  };
  //function to rename view
  const renameView = async (projectID, floorID, roomID, viewID, newName) => {
    // Update the view name in the file system
    //check if view name already exists
    if (
      Object.values(fs[projectID].floors[floorID].rooms[roomID].views).some(
        (view) => view.name === newName
      )
    ) {
      //get element with unique id equal to projectID
      var element = document.querySelector(
        `[uniqueId="${projectID + floorID + roomID + viewID}"]`
      );
      //get element with editableText class
      var editableText = element.querySelector(".editableText");
      //set editable text to old name
      editableText.textContent =
        fs[projectID].floors[floorID].rooms[roomID].views[viewID].name;
      setMessage("View name already exists!");
      handleSnackClick();
      return;
    }
    //view name should be only alphabetical with spaces and no special characters and numbers and between 3-20 characters
    if (!/^[a-zA-Z ]{3,20}$/.test(newName)) {
      //get element with unique id equal to projectID
      var element = document.querySelector(
        `[uniqueId="${projectID + floorID + roomID + viewID}"]`
      );
      //get element with editableText class
      var editableText = element.querySelector(".editableText");
      //set editable text to old name
      editableText.textContent =
        fs[projectID].floors[floorID].rooms[roomID].views[viewID].name;
      setMessage(
        "View name should be between 3-20 characters and only alphabets!"
      );
      handleSnackClick();
      return;
    }
    var newFS = fs;
    newFS[projectID].floors[floorID].rooms[roomID].views[viewID].name = newName;
    // Upload the updated file system to Firebase Storage
    uploadJSON(newFS);
    setMessage("View Renamed!");
    handleSnackClick();
  };
  //function to uplaod thumbnail
  const uploadThumbnail = async (
    projectID,
    floorID,
    roomID,
    viewID,
    data,
    type
  ) => {
    let newFs = fs;
    if (type === "Designer") {
      newFs.thumbnail = data;
    } else if (type === "Project") {
      newFs[projectID].thumbnail = data;
    } else if (type === "Floor") {
      newFs[projectID].floors[floorID].thumbnail = data;
    } else if (type === "Room") {
      newFs[projectID].floors[floorID].rooms[roomID].thumbnail = data;
    } else if (type === "View") {
      newFs[projectID].floors[floorID].rooms[roomID].views[viewID].thumbnail =
        data;
    }
    uploadJSON(newFs);
  };
  const deleteView = async (projectID, floorID, roomID, viewID) => {
    setProjLoading(true);
    const newFS = fs;
    const rawPath = ConvertPathIntoRawPath(storageRef._location.path_);
    const viewPath = `${projectID}/${floorID}/${roomID}/${viewID}`;
    const imagePath = `${viewPath}.png`;
    const metadataPath = `${viewPath}-metadata.json`;
    const floorMetadataPath = `${rawPath}/${projectID}/${floorID}/${floorID}-metadata.json`;
    //if the view is Open,then show alert
    if (
      currentRoom === roomID &&
      currentView === viewID &&
      currentFloor === floorID &&
      currentProject === projectID
    ) {
      setMessage("Cannot delete a view that is currently open!");
      handleSnackClick();
      setProjLoading(false);
      return;
    }

    try {
      const floorMetadataFile = await getFile(floorMetadataPath);
      const floorMetadata = JSON.parse(floorMetadataFile);
      const keys = Object.keys(floorMetadata);

      for (let key of keys) {
        if (
          floorMetadata[key].roomID === roomID &&
          floorMetadata[key].viewID === viewID
        ) {
          delete floorMetadata[key];
        }
      }

      const imageStatus = await removeFile(imagePath, rawPath);
      if (imageStatus !== 200) throw new Error("Error deleting image");

      const metadataBuffer = Buffer.from(JSON.stringify(floorMetadata));

      const metadataStatus = await addFile(
        `${floorID}-metadata.json`,
        `${rawPath}/${projectID}/${floorID}`,
        metadataBuffer
      );
      if (metadataStatus !== 200 && metadataStatus !== 404)
        throw new Error("Error updating floor metadata");
      const viewMetadataStatus = await removeFile(metadataPath, rawPath);
      if (viewMetadataStatus !== 200)
        throw new Error("Error deleting view metadata");
      delete newFS[projectID].floors[floorID].rooms[roomID].views[viewID];
      uploadJSON(newFS);
      setMessage("View Deleted!");
      handleSnackClick();
      setInHome(true);
    } catch (error) {
      console.error("Error deleting view:", error);
      setMessage("Error deleting view!");
      handleSnackClick();
    } finally {
      setProjLoading(false);
    }
  };
  //function to delete all projects
  const deleteAllProjects = async () => {
    //ask for confirmation
    setConfirmDialogText(
      "All Projects and their contents will be permanently deleted.Shared projects will no longer be accessible to others"
    );
    const result = await confirm();
    if (!result) {
      return;
    }
    //get the tree item with nide id equal to Designer space
    var element = document.querySelector(`[uniqueId="Designer Space"]`);
    setDeleteOpen(true);
    setDeleteAnchorEl(element);
  };
  //function to select all projects
  const selectAllProjects = () => {
    setSelectedProjects((prevSelectedProjects) => {
      const filteredProjects = projectList.filter((projID) =>
        projID.startsWith("P")
      );
      return [...prevSelectedProjects, ...filteredProjects, "Designer Space"];
    });
  };
  useEffect(() => {
    //check if selected projects cotains all the items in project list starting with P
    const filteredProjectList = projectList.filter((projID) =>
      projID.startsWith("P")
    );
    if (
      selectedProjects.length !== 0 &&
      selectedProjects.length === filteredProjectList.length
    ) {
      //include Designer Space in selected projects
      setSelectedProjects([...selectedProjects, "Designer Space"]);
    }
  }, [selectedProjects]);
  //function to download selected projects
  const downloadSelectedProjects = async (selectedProjects, fs) => {
    try {
      const zip = new JSZip();
      let totalFiles = 0;
      let downloadedFiles = 0;

      // Prompt user to save the ZIP file
      const filePicker = await window.showSaveFilePicker({
        types: [
          { description: "ZIP files", accept: { "application/zip": [".zip"] } },
        ],
      });

      setMessage("Preparing download...");
      handleSnackClick();

      // Get the raw path and list all files and directories
      const rawPath = ConvertPathIntoRawPath(storageRef._location.path_);
      const filesAndDirectories = await listAllChildDirectoriesAndFiles(
        rawPath
      );

      // Filter files by selected projects
      const filteredItems = filesAndDirectories.items.filter((item) => {
        const tempPath = item.path.substring(item.path.indexOf("/") + 1);
        const projID = tempPath.split("/")[0];
        return (
          selectedProjects.includes(projID) &&
          !item.path.includes("projectList.json")
        );
      });

      totalFiles = filteredItems.length;

      // Download and add files to ZIP
      for (const item of filteredItems) {
        const tempPath = item.path.substring(item.path.indexOf("/") + 1);
        const tempPathArray = tempPath.split("/");
        const projID = tempPathArray[0];
        const projName = fs[projID]?.name || projID;

        let path = "";

        if (tempPathArray.length === 3) {
          // Floorplan
          const floorID = tempPathArray[1];
          const floorName = fs[projID]?.floors[floorID]?.name || floorID;
          const fileName = tempPathArray[2];
          path = `${projName}/${floorName}/${fileName}`;
          path = path.replace(floorID, floorName);
        } else if (tempPathArray.length === 5) {
          // View
          const floorID = tempPathArray[1];
          const floorName = fs[projID]?.floors[floorID]?.name || floorID;
          const roomID = tempPathArray[2];
          const roomName =
            fs[projID]?.floors[floorID]?.rooms[roomID]?.name || roomID;
          const viewID = tempPathArray[3];
          const viewName =
            fs[projID]?.floors[floorID]?.rooms[roomID]?.views[viewID]?.name ||
            viewID;
          const fileName = tempPathArray[4];
          path = `${projName}/${floorName}/${roomName}/${viewName}/${fileName}`;
          path = path.replace(viewID, viewName);
        }

        // Fetch the file content
        const file = await getFile(item.path);
        // Add the file to the zip
        zip.file(path, file);

        // Update progress
        downloadedFiles++;
        const progress = (downloadedFiles / totalFiles) * 100;
        setDownloadPercent(progress);
      }

      // Generate ZIP file with progress tracking
      const zipBlob = await zip.generateAsync({ type: "blob" }, (metadata) => {
        //DO NOT DELETE - call back has to be there
      });

      // Write ZIP file to the file system
      const writable = await filePicker.createWritable();
      await writable.write(zipBlob);
      await writable.close();

      // Show success message
      setMessage("Projects Downloaded!");
      setDownloadPercent(0);
      handleSnackClick();
    } catch (error) {
      console.error("Error downloading projects:", error);
      setMessage("Error during download.");
      handleSnackClick();
    }
  };

  //function to get selected projects
  useEffect(() => {
    //get all checkboxes
    if (!selectionMode) {
      if (selectedProjects.length === 0) return;

      //filter projects starting with P
      const filteredProjects = selectedProjects.filter((projID) =>
        projID.startsWith("P")
      );

      setIsDownloading(true);
      downloadSelectedProjects(filteredProjects, fs);
      setSelectedProjects([]);
    }
  }, [selectionMode]);
  //function to get shared projects from DB
  const getSharedProjects = async () => {
    getFile(auth.currentUser.email + "/sharedProjectsDes.json")
      .then(async (rawFile) => {
        const jsonFile = await JSON.parse(rawFile);
        setSharedProjects(jsonFile);
      })
      .catch((error) => {
        console.error("Error fetching shared projects:", error);
      });
  };
  //function to get public projects from DB
  const getPublicProjects = async () => {
    getFile("global/marketPlace.json").then(async (rawFile) => {
      const jsonFile = await JSON.parse(rawFile);

      setPublicProjects(jsonFile[auth.currentUser.email]);
    });
  };
  //function to load projects from firebase storage
  const load = async () => {
    if (storageRef == null) return;
    getFiles(storageRef)
      .then((result) => {
        setProjLoading(true);
        setfs(result);
        setProjectList(Object.keys(result));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    await getPublicProjects();
    await getSharedProjects();
    setProjLoading(false);
  };

  //audio playing code

  const [audioLoaded, setAudioLoaded] = useState(false);
  const [audioURL, setAudioURL] = useState(null);

  useEffect(() => {
    if (audioLoaded === true) {
      const audioElement = document.getElementById("audioPlayer");
      if (audioElement) {
        audioElement.play();
      }
    }
  }, [audioLoaded]);

  // function playRef() {
  //   const storageRef = ref(storage, "audio/audio.mp3");

  //   getDownloadURL(storageRef)
  //     .then((url) => {
  //       setAudioURL(url);
  //       setAudioLoaded(true);
  //     })
  //     .catch((error) => {
  //       console.error("Error getting download URL:", error);
  //     });
  // }

  const { confirm, dialog } = useConfirmDialog();
  const [userNames, setUserNames] = useState({});
  const handleGetUserNameByEmail = (emailToFind) => {
    return new Promise(async (resolve, reject) => {
      try {
        const rawFile = await getFile("users/multivrseInteriaUsers.json");
        const usersData = JSON.parse(rawFile)[0];
        for (const key of Object.keys(usersData)) {
          if (usersData[key].email === emailToFind) {
            resolve(usersData[key].firstname + " " + usersData[key].lastname);
            return;
          }
        }
        resolve(null); // Resolve null if no match is found
      } catch (error) {
        console.error("Error fetching users:", error);
        reject(error);
      }
    });
  };

  // Function to fetch and store username
  const fetchAndStoreUserName = async (email) => {
    try {
      const userName = await handleGetUserNameByEmail(email);
      setUserNames((prev) => ({
        ...prev,
        [email]: userName || "Name not found",
      }));
    } catch (error) {
      console.error("Error fetching user name:", error);
      setUserNames((prev) => ({ ...prev, [email]: "Error fetching name" }));
    }
  };

  useEffect(() => {
    // Extract emails and fetch usernames
    const emails = Object.keys(sharedProjects).filter(
      (email) => Object.keys(sharedProjects[email]).length > 0
    );

    emails.forEach((email) => {
      if (!userNames[email]) {
        fetchAndStoreUserName(email);
      }
    });
  }, [sharedProjects]);

  return (
    <div className="manager" style={{ zIndex: 2 }} onLoadStart={load}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        message={message}
        action={action}
        style={{
          position: "fixed",
          top: "150px", // Move Snackbar downward
          left: "50%",
          transform: "translateX(-50%)",
        }}
        ContentProps={{
          style: {
            fontSize: "4rem", // Increase font size
            padding: "24px 32px", // Increase padding
            minWidth: "350px", // Increase width
            textAlign: "center", // Center the text if needed
          },
        }}
      >
        <Alert
          onClose={handleSnackClose}
          severity="info"
          variant="filled"
          sx={{
            bgcolor:
              theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(24px)",
            border: "1px solid",
            borderColor: "divider",
            color:
              theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.87)"
                : "rgba(255, 255, 255, 0.87)",
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
          }}
        >
          {message.toString()}
        </Alert>
      </Snackbar>
      {/* button for project browser */}
      <div className="nav_button">
        <Button
          variant="text"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={(theme) => ({
            ...(open && { display: "none" }),
            zIndex: 1102,
            height: "60vh",
            padding: "0px",
            marginLeft: "10px",
            bgcolor:
              theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(24px)",
            border: "1px solid",
            borderColor: "divider",
            color: theme.palette.mode === "dark" ? "white" : "black",
          })}
        >
          <ChevronRightIcon />
        </Button>
      </div>
      <Drawer
        sx={(theme) => ({
          ...(open && {
            bgcolor:
              theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(24px)",
            marginLeft: "10px",
            border: "1px solid",
            borderColor: "divider",
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            borderRadius: "10px",
          }),
          ...(!open && {
            display: "none",
          }),
          zIndex: 1102,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            ...(open && {
              position: "relative",
              width: drawerWidth,
              boxSizing: "border-box",
              height: "60vh",
              alignSelf: "center",
              bgcolor: "rgb(0,0,0,0)",
              borderRadius: "10px",
              scrollbarWidth: "none",
              scrollbarColor: "transparent transparent",
              padding: "0px",
              // color:
              //   theme.palette.mode === "dark"
              //     ? "#000"
              //     : theme.palette.primary.main,
            }),
            ...(!open && {
              display: "none",
            }),
            height: "60vh",
            position: "relative",
          },
        })}
        variant="persistent"
        ModalProps={{
          keepMounted: false,
        }}
        anchor="left"
        open={open}
      >
        <Divider />
        {/* share project to multiple customer popper */}
        <Popper
          style={{ zIndex: "1201" }}
          open={desOpen}
          anchorEl={desAnchorEl}
          placement="right-start"
        >
          <ClickAwayListener
            onClickAway={() => {
              setDesAnchorEl(null);
              setIsPublicCheck(false);
              setEmailList([]);
              setEmail("");
              setDesOpen(false);
            }}
          >
            <Box
              sx={{
                bgcolor:
                  theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)",
                backdropFilter: "blur(24px)",
                border: "1px solid",
                borderColor: "divider",
                color:
                  theme.palette.mode === "light"
                    ? "rgba(0, 0, 0, 0.87)"
                    : "rgba(255, 255, 255, 0.87)",
                boxShadow:
                  theme.palette.mode === "light"
                    ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                    : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
                p: 1,
                borderRadius: 2,
                maxHeight: "40vh",
                backdropFilter: "blur(24px)",
                border: "1px solid",
                borderColor: "divider",
                overflowY: "auto",
                msOverflowStyle: "none", // IE and Edge
                scrollbarWidth: "none", // Firefox
                WebkitOverflowScrolling: "touch", // Smooth scrolling for iOS
              }}
              // component="form"
              autoComplete="on"
              alignItems="center"
              //disable hit enter to submit form
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              <CloseIcon
                fontSize="small"
                cursor="pointer"
                onClick={() => {
                  setDesAnchorEl(null);
                  setEmailList([]);
                  setEmail("");
                  setDesOpen(false);
                  setIsPublicCheck(false);
                }}
              ></CloseIcon>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <h4 style={{ margin: "auto" }}>
                  Share{" "}
                  {selectedProjectID &&
                  Object.keys(fs).includes(selectedProjectID)
                    ? fs[selectedProjectID]["name"]
                    : false}{" "}
                  with :
                </h4>
                <TextField
                  required
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  label="Enter customer mail"
                  size="small"
                  variant="standard"
                  error={email ? !handleEmail() : false}
                  helperText={
                    email && !handleEmail() ? "Please enter a valid email" : ""
                  }
                />
                <List sx={{ width: "100%" }}>
                  {emailList.map((email, index) => (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          variant="standard"
                          onClick={() => handleDeleteEmail(email)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText id={index} primary={email} />
                    </ListItem>
                  ))}
                </List>
                <Stack alignItems={"center"} spacing={1} direction="row">
                  <Checkbox
                    checked={isPublicCheck}
                    onChange={() => {
                      setIsPublicCheck(!isPublicCheck);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Typography textAlign={"center"}>
                    Make project public
                  </Typography>
                </Stack>
                <Stack spacing={2} direction="row">
                  <Tooltip title="Add Email">
                    <Button
                      onClick={() => {
                        handleAddEmail();
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <AddCircleIcon />
                      </Box>
                    </Button>
                  </Tooltip>

                  <Tooltip title="Ok">
                    <Button
                      onClick={() => {
                        handleDesSubmit();
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <CheckIcon />
                      </Box>
                    </Button>
                  </Tooltip>
                </Stack>
              </Stack>
            </Box>
          </ClickAwayListener>
        </Popper>
        {/* edit project popper to manage shared projects */}
        <Popper
          style={{ zIndex: 1201 }}
          open={customerOpen}
          anchorEl={customerAnchorEl}
          placement="right-start"
        >
          <ClickAwayListener onClickAway={() => setCustomerAnchorEl(null)}>
            <Box
              sx={{
                bgcolor:
                  theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)",
                backdropFilter: "blur(24px)",
                border: "1px solid",
                borderColor: "divider",
                color:
                  theme.palette.mode === "light"
                    ? "rgba(0, 0, 0, 0.87)"
                    : "rgba(255, 255, 255, 0.87)",
                boxShadow:
                  theme.palette.mode === "light"
                    ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                    : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
                p: 1,
                borderRadius: 2,
                maxHeight: "40vh",
                backdropFilter: "blur(24px)",
                border: "1px solid",
                borderColor: "divider",
                overflowY: "auto",
                msOverflowStyle: "none", // IE and Edge
                scrollbarWidth: "none", // Firefox
                WebkitOverflowScrolling: "touch", // Smooth scrolling for iOS
                p: 1,
                borderRadius: 2,
                maxHeight: "40vh",
                backdropFilter: "blur(24px)",
                border: "1px solid",
                borderColor: "divider",
                overflowY: "auto",
                msOverflowStyle: "none", // IE and Edge
                scrollbarWidth: "none", // Firefox
                WebkitOverflowScrolling: "touch", // Smooth scrolling for iOS
              }}
              autoComplete="on"
              alignItems="center"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              <CloseIcon
                fontSize="small"
                onClick={() => {
                  handleCusSubmit();
                  setCustomerAnchorEl(null);
                  setSelectedMail("");
                  setCustomerOpen(false);
                }}
              ></CloseIcon>

              <Stack spacing={2} justifyContent="center" alignItems="center">
                <h4 style={{ margin: "auto" }}>
                  Manage project access to {selectedMail} :
                </h4>

                <List
                  sx={{
                    width: "100%",
                    maxHeight: "30vh",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#c1c1c1",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "#a0a0a0",
                    },
                  }}
                >
                  {projectList.map((projectName, index) => {
                    if (projectName === "error" || projectName == "thumbnail")
                      return null;

                    return (
                      <ListItem key={index} disablePadding>
                        <ListItemButton
                          role={undefined}
                          onClick={handleToggleCheckBox(index)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf(index) !== -1}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={projectName}
                            primary={
                              Object.keys(fs).includes(projectName)
                                ? fs[projectName]["name"]
                                : ""
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>

                {/* <Stack
          spacing={2}
          direction="row"
          sx={{
            position: "sticky",
            bottom: 0,
            p: 1,
          }}
        >
          <Tooltip title="Save changes">
          <Button
            variant="contained"
            onClick={() => {
              handleCusSubmit();
            }}
          >
            <SaveIcon/>
          </Button>
          </Tooltip>
        </Stack> */}
              </Stack>
            </Box>
          </ClickAwayListener>
        </Popper>
        {/* popper for project delete confirmation */}
        <Popper
          style={{ zIndex: 1201 }}
          open={deleteOpen}
          anchorEl={deleteAnchorEl}
          placement="right-start"
        >
          <ClickAwayListener onClickAway={() => setDeleteAnchorEl(null)}>
            <Box
              sx={{
                bgcolor:
                  theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)",
                backdropFilter: "blur(24px)",
                border: "1px solid",
                borderColor: "divider",
                color:
                  theme.palette.mode === "light"
                    ? "rgba(0, 0, 0, 0.87)"
                    : "rgba(255, 255, 255, 0.87)",
                boxShadow:
                  theme.palette.mode === "light"
                    ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                    : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
                p: 1,
                borderRadius: 2,
                maxHeight: "40vh",
                backdropFilter: "blur(24px)",
                border: "1px solid",
                borderColor: "divider",
                overflowY: "auto",
                msOverflowStyle: "none", // IE and Edge
                scrollbarWidth: "none", // Firefox
                WebkitOverflowScrolling: "touch", // Smooth scrolling for iOS
              }}
              // component="form"
              autoComplete="on"
              alignItems="center"
              //disable hit enter to submit form
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              <CloseIcon
                fontSize="small"
                cursor="pointer"
                onClick={() => {
                  setDeleteAnchorEl(null);
                  setDeleteOpen(false);
                  setDeleteText("");
                }}
              ></CloseIcon>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <h4 style={{ margin: "auto", textAlign: "center" }}>
                  Type "Delete All Projects"
                </h4>
                <TextField
                  required
                  type="text"
                  onChange={(e) => {
                    setDeleteText(e.target.value);
                  }}
                  value={deleteText}
                  label="Enter text"
                  size="small"
                  variant="standard"
                  error={deleteText !== "Delete All Projects"}
                  helperText={
                    deleteText !== "Delete All Projects"
                      ? "Please enter the correct text"
                      : ""
                  }
                />
                <Stack spacing={2} direction="row">
                  <Tooltip title="Delete All Projects">
                    <Button
                      disabled={deleteText !== "Delete All Projects"}
                      onClick={() => {
                        //get all projects IDS from fs tht starts with P
                        setProjLoading(true);
                        const projectIDs = Object.keys(fs).filter((key) =>
                          key.startsWith("P")
                        );

                        //delete all projects
                        projectIDs.forEach((projectID) => {
                          deleteProject(projectID, true);
                        });

                        setMessage("All Projects Deleted!");
                        handleSnackClick();
                        setDeleteAnchorEl(null);
                        setDeleteOpen(false);
                        setDeleteText("");
                        setProjLoading(false);
                      }}
                    >
                      <CheckIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <Button
                      onClick={() => {
                        setDeleteAnchorEl(null);
                        setDeleteOpen(false);
                        setDeleteText("");
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  </Tooltip>
                </Stack>
              </Stack>
            </Box>
          </ClickAwayListener>
        </Popper>

        {/* popper for focusing on single project  */}
        <ClickAwayListener
          onClickAway={() => {
            setContextMenuOpen(false);
            // if(focusedProject)
            //   setFocusProject(true)
            //change focused project to prev value
          }}
        >
          <Popper
            anchorEl={contextMenuAnchorEl}
            placement="top"
            style={{ zIndex: 1201 }}
            open={Boolean(contextMenuOpen)}
            onClose={() => setContextMenuOpen(false)} // Close the menu on outside click
            sx={{
              zIndex: 1201,
              ...(contextMenuOpen && {
                bgcolor:
                  theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)",
                backdropFilter: "blur(24px)",
                marginLeft: "10px",
                border: "1px solid",
                borderColor: "divider",
                boxShadow:
                  theme.palette.mode === "light"
                    ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                    : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
                borderRadius: "10px",
              }),
              ...(!contextMenuOpen && {
                display: "none",
              }),
            }}
          >
            <div>
              {menuOptions.map((option, index) => (
                <MenuItem key={index} onClick={option.action}>
                  {option.label}
                </MenuItem>
              ))}
            </div>
          </Popper>
        </ClickAwayListener>

        {/* designer space tree view */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "60vh", // Set the container height to fill the viewport
            overflow: "hidden", // Hide the scrollbars
          }}
        >
          <div style={{ height: "50%" }}>
            <TreeView
              aria-label="multi-select"
              style={{
                textAlign: "left",
                height: "100%",
                overflow: "auto",
                msOverflowStyle: "none",
                scrollbarWidth: "none",
                WebkitOverflowScrolling: "touch",
              }}
            >
              {/* Designer Space */}
              <TreeItem
                nodeId={"Designer Space"}
                uniqueId={"Designer Space"}
                label={TreeItemOptions({
                  nodeId: "Designer Space",
                  labelText: (
                    <span style={{ fontFamily: "Audiowide, sans-serif" }}>
                      Designer Space
                    </span>
                  ),
                  treeItemOptions: {
                    addTitle: "Project",
                    addFunction: createProject,
                    deleteTitle: "All Projects",
                    deleteFunction: deleteAllProjects,
                    downloadProjects: true,
                  },
                  loadingIcon: !projLoading ? (
                    <CircularProgress size={20} />
                  ) : null,
                  closeButton: ChevronLeftIcon,
                  handleDrawerClose: handleDrawerClose,
                  selectionMode: selectionMode,
                  setSelectionMode: setSelectionMode,
                  setSelectedProjects: setSelectedProjects,
                  selectAllProjects: selectAllProjects,
                  selectedProjects: selectedProjects,
                  downloadPercent: downloadPercent,
                })}
                data-type="root" // Add data-type attribute for folder nodes
                style={{ borderBottom: "1px solid #161a1d" }}
                icon={
                  <Tooltip
                    title="Add thumbnail for Designer"
                    PopperProps={{ disablePortal: true }}
                  >
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        setThumbnailType("Designer");
                        setThumbnailProjectID(null);
                        setThumbnailRoomID(null);
                        setThumbnailViewID(null);
                        setThumbnailAnchor(
                          e.currentTarget.parentElement.parentElement
                        );
                      }}
                    >
                      <ArchitectureIcon
                        sx={{
                          color:
                            fs != null &&
                            fs.thumbnail != null &&
                            Object.keys(fs.thumbnail)
                              ? "primary.main"
                              : "default",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                }
              ></TreeItem>
              {renderTree(
                focusedProject && focusProject ? focusedProjectfs : fs
              )}
            </TreeView>
          </div>

          <div style={{ height: "50%" }}>
            <TreeView
              onContextMenu={(event) => event.preventDefault()}
              aria-label="multi-select"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              style={{
                textAlign: "left",
                height: "100%",
                overflow: "auto",
                userSelect: "none",
              }}
            >
              <TreeItem
                key="Shared Space" // Unique key for the root TreeItem
                uniqueId="Shared Space" // Unique nodeId for the root TreeItem
                nodeId="Shared Space" // Unique nodeId for the root TreeItem
                label={
                  <span style={{ fontFamily: "Audiowide, sans-serif" }}>
                    Shared Space
                  </span>
                }
                collapseIcon={<GroupAddIcon color="disabled" />} // Icon for the root Tree
                icon={<GroupAddIcon />} // Always show the icon
                style={{
                  borderBottom: "1px solid #161a1d",
                  userSelect: "none",
                }}
              ></TreeItem>
              {Object.entries(sharedProjects)
                .filter(([, projects]) => Object.keys(projects).length > 0) // Filter out emails with no projects
                .map(([email, projects], index) => (
                  <TreeItem
                    key={index} // Unique key for each email TreeItem
                    nodeId={email} // Unique nodeId for each email TreeItem
                    onContextMenu={(event) => {
                      event.preventDefault();
                      handleCusClick(event, email);
                    }}
                    label={
                      <span id={`userName-${email}`}>
                        {userNames[email] || "Loading..."}{" "}
                        {/* Display the username */}
                      </span>
                    } // Label for each email TreeItem
                    // Custom context menu handler
                    style={{ userSelect: "none" }}
                  >
                    {Object.entries(projects).map(([projID], idx) => (
                      <TreeItem
                        key={idx} // Unique key for each project TreeItem
                        nodeId={`${email}-${projID}`} // Unique nodeId for each project TreeItem
                        label={projects[projID]} // Display project name
                        style={{ userSelect: "none" }}
                      />
                    ))}
                  </TreeItem>
                ))}
            </TreeView>
          </div>
        </div>
        <ThumbnailPopper
          theme={theme}
          data={thumbnailsData}
          categories={thumbnailsCategories}
          projID={thumbnailProjectID}
          floorID={thumbnailFloorID}
          roomID={thumbnailRoomID}
          viewID={thumbnailViewID}
          submitFunction={uploadThumbnail}
          anchorEl={thumbnailAnchor}
          setAnchorEl={setThumbnailAnchor}
          type={thumbnailType}
          isThumbnail={
            thumbnailType == "Designer"
              ? fs?.thumbnail?.icon
              : thumbnailType === "Project"
              ? fs[thumbnailProjectID]?.thumbnail?.icon
              : thumbnailType === "Floor"
              ? fs[thumbnailProjectID]?.floors[thumbnailFloorID]?.thumbnail
                  ?.icon
              : thumbnailType === "Room"
              ? fs[thumbnailProjectID]?.floors[thumbnailFloorID]?.rooms[
                  thumbnailRoomID
                ]?.thumbnail?.icon
              : thumbnailType === "View"
              ? fs[thumbnailProjectID]?.floors[thumbnailFloorID]?.rooms[
                  thumbnailRoomID
                ]?.views[thumbnailViewID]?.thumbnail?.icon
              : false
          }
          currentThumbnail={
            thumbnailType == "Designer"
              ? fs?.thumbnail
              : thumbnailType == "Project"
              ? fs[thumbnailProjectID]?.thumbnail
              : thumbnailType == "Floor"
              ? fs[thumbnailProjectID]?.floors[thumbnailFloorID]?.thumbnail
              : thumbnailType == "Room"
              ? fs[thumbnailProjectID]?.floors[thumbnailFloorID]?.rooms[
                  thumbnailRoomID
                ]?.thumbnail
              : thumbnailType == "View"
              ? fs[thumbnailProjectID]?.floors[thumbnailFloorID]?.rooms[
                  thumbnailRoomID
                ]?.views[thumbnailViewID]?.thumbnail
              : false
          }
        />
        <FloorPlanPopper
          floorPlanAnchor={floorPlanAnchor}
          setFloorPlanAnchor={setFloorPlanAnchor}
          floorPlanProjectID={floorPlanProjectID}
          setFloorPlanProjectID={setFloorPlanProjectID}
          floorPlanFloorID={floorPlanFloorID}
          setFloorPlanFloorID={setFloorPlanFloorID}
          pinViewID={pinViewID}
          setPinViewID={setPinViewID}
          pinRoomID={pinRoomID}
          setPinRoomID={setPinRoomID}
          createFloorPlan={createFloorPlan}
          deleteFloorPlan={deleteFloorPlan}
          saveFloorPlanMetadata={saveFloorPlanMetadata}
          isMinimized={isMinimized}
          setIsMinimized={setIsMinimized}
        />
        {dialog}
        {/* this is the audio player element */}
        {/* {audioLoaded && (
          <audio src={audioURL} id="audioPlayer">
            Play ME
          </audio>
        )} */}
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
      </Main>
    </div>
  );
}
