import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { setPersistence, browserSessionPersistence } from "firebase/auth";
import { auth } from "../authentication/Firebase";
import IconButton from "@mui/material/IconButton";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import InteriaLogo from "../../icons/interia_logo.svg";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/" style={{ fontFamily: "Audiowide" }}>
        Interia
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function RequestSignUp() {
  const [message, setMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [userType, setUserType] = React.useState("Customer");
  const [darkMode, setDarkMode] = React.useState(() => {
    return localStorage.getItem("theme") === "dark";
  });

  React.useEffect(() => {
    localStorage.setItem("theme", darkMode ? "dark" : "light");
  }, [darkMode]);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? "dark" : "light",
        },
      }),
    [darkMode]
  );

  const handleThemeToggle = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setMessage("");
    const data = new FormData(event.currentTarget);

    const fullName = data.get("fullName");
    const organization = userType == "Designer" ? data.get("organization") : "";
    const phone = data.get("phone");
    const email = data.get("email");  
    if (!fullName || !phone || !email || (userType == "Designer" && !organization)) {
      setError("All fields are required.");
      return;
    }

    try {
      setIsLoading(true);
      await setPersistence(auth, browserSessionPersistence);

      const userProfileData = {
        fullName,
        organization,
        phone,
        email,
        userType,
      };

      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_URL_AUTH_SERVER}/api/signuprequest`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userProfileData),
        }
      );
      const responseData = await response.json();
      if (responseData.success) {
        setMessage(
          "Request submitted successfully. We will get back to you soon. 😊"
        );
      } else {
        setError("Something went wrong. Please try again later.");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Avatar
            alt="logo of Interia"
            src={InteriaLogo}
            sx={{
              width: 150,
              height: 150,
            }}
          />
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <IconButton
            sx={{ position: "absolute", top: 10, right: 10 }}
            onClick={handleThemeToggle}
            color="inherit"
          >
            {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel id="userTypeLabel">Select the type of user</InputLabel>
                <Select
                  labelId="userTypeLabel"
                  required
                  fullWidth
                  name="userType"
                  value={userType}
                  onChange={(event) => {
                    setUserType(event.target.value);
                  }}
                >
                  <MenuItem value={"Customer"}>Customer</MenuItem>
                  <MenuItem value={"Designer"}>Designer</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="fullName"
                  label="Full Name"
                  name="fullName"
                  autoComplete="name"
                />
              </Grid>
              {userType === "Designer" && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    id="organization"
                    label="Organization or Company Name"
                    name="organization"
                    autoComplete="organization"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  autoComplete="tel"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                {error && (
                  <Typography variant="body1" textAlign={"center"} color="error">
                    {error}
                  </Typography>
                )}
                {message && (
                  <Typography variant="body1" textAlign={"center"} color="success">
                    {message}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {isLoading && (
              <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                style={{ fontSize: "1.2rem" }}
              >
                Loading... <CircularProgress size={20} />
              </Typography>
            )}
            <Link href="/signin" variant="body2">
              Already have an account? Sign in
            </Link>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
