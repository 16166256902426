import React from "react";
import "aframe";
import { useTheme } from "@emotion/react";

const MenuItem = ({
  text = null,
  image = null,
  position,
  uniqueID,
  width,
  height,
  textWidth,
  setSelectedID,
  selectable,
  className,
  primaryTextColor,
  secondaryTextColor,
  primaryPlaneColor,
  secondaryPlaneColor,
  isUserItem = false,
  alphabetId,
  showAlphabetId = true,
  radius = 0.3,
  ismetadata = false,
}) => {
  // Register components outside the component to avoid repeated registration

  let theme = useTheme();
  if (Object.keys(theme).length === 0) {
    // Change theme to have a default value
    theme = {
      palette: {
        mode: "light",
      },
    };
  }
  if (!window.AFRAME.components["outer_plane"]) {
    window.AFRAME.registerComponent("outer_plane", {
      schema: {
        selectable: { type: "boolean", default: true },
        primaryTextColor: {
          type: "string",
          default:
            theme.palette.mode === "light"
              ? "rgba(0, 0, 0, 0.87)"
              : "rgba(255, 255, 255, 0.87)",
        },
        secondaryTextColor: {
          type: "string",
          default:
            theme.palette.mode === "light"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)",
        },
        primaryPlaneColor: { type: "string", default: "#42a5f5" },
        secondaryPlaneColor: {
          type: "string",
          default:
            theme.palette.mode === "light"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)",
        },
      },
      init: function () {
        const { el, data } = this;

        const handleMouseEnter = () => {
          if (!data.selectable) return;
          el.setAttribute("color", data.secondaryPlaneColor);
          el.object3D.scale.set(1.2, 1.2, 1.2);
          const textEl = el.querySelector("a-text");
          // if (textEl) textEl.setAttribute("color", data.secondaryTextColor);
        };

        const handleMouseLeave = () => {
          if (!data.selectable) return;
          el.setAttribute("color", data.primaryPlaneColor);
          el.object3D.scale.set(1, 1, 1);
          const textEl = el.querySelector("a-text");
          // if (textEl) textEl.setAttribute("color", data.primaryTextColor);
        };

        el.addEventListener("mouseenter", handleMouseEnter);
        el.addEventListener("mouseleave", handleMouseLeave);

        el.addEventListener("click", () => {
          if (data.selectable) {
            const uniqueID = el.getAttribute("uniqueID");
            setSelectedID(uniqueID);
          }
        });

        el.addEventListener("raycaster-intersected", handleMouseEnter);
        el.addEventListener("raycaster-intersected-cleared", handleMouseLeave);
      },
    });
  }

  if (!window.AFRAME.components["right-controllor"]) {
    window.AFRAME.registerComponent("right-controllor", {
      init: function () {
        this.el.addEventListener("triggertouchstart", (e) => {
          const intersectedEls = e.target.components.raycaster.intersectedEls;
          if (intersectedEls.length > 0) {
            const uniqueID = intersectedEls[0].getAttribute("uniqueID");
            setSelectedID(uniqueID);
          }
        });
      },
    });
  }

  if (!window.AFRAME.components["custom-rounded-rect"]) {
    window.AFRAME.registerComponent("custom-rounded-rect", {
      schema: {
        width: { type: "number", default: 1 },
        height: { type: "number", default: 1 },
        radius: { type: "number", default: 0.1 },
        color: {
          type: "color",
          default:
            theme.palette.mode === "light"
              ? "rgba(0, 0, 0, 1)"
              : "rgba(255, 255, 255, 1)",
        },
        opacity: { type: "number", default: 0.6 }, // Add opacity parameter
      },
      init: function () {
        this.createRoundedRect();
      },
      update: function () {
        this.createRoundedRect();
      },
      createRoundedRect: function () {
        const { width, height, radius, color, opacity } = this.data; // Destructure opacity
        const el = this.el;

        el.removeObject3D("mesh");

        const shape = new window.THREE.Shape();
        shape.moveTo(-width / 2 + radius, height / 2);
        shape.lineTo(width / 2 - radius, height / 2);
        shape.quadraticCurveTo(
          width / 2,
          height / 2,
          width / 2,
          height / 2 - radius
        );
        shape.lineTo(width / 2, -height / 2 + radius);
        shape.quadraticCurveTo(
          width / 2,
          -height / 2,
          width / 2 - radius,
          -height / 2
        );
        shape.lineTo(-width / 2 + radius, -height / 2);
        shape.quadraticCurveTo(
          -width / 2,
          -height / 2,
          -width / 2,
          -height / 2 + radius
        );
        shape.lineTo(-width / 2, height / 2 - radius);
        shape.quadraticCurveTo(
          -width / 2,
          height / 2,
          -width / 2 + radius,
          height / 2
        );

        const geometry = new window.THREE.ShapeGeometry(shape);
        const material = new window.THREE.MeshBasicMaterial({
          side: window.THREE.DoubleSide,
          color: color,
          opacity: opacity, // Set the opacity
          transparent: opacity < 1, // Enable transparency if opacity is less than 1
        });
        const mesh = new window.THREE.Mesh(geometry, material);
        el.setObject3D("mesh", mesh);
      },
    });
  }

  const [x, y, z] = position.split(" ").map(Number);
  const adjustedTextPosition = `${0} ${-height + 1.6} ${0}`;
  const adjustedPosition = `${x} ${y - 0.7} ${z - 0.1}`;
  textWidth = textWidth / 1.2;

  const opacityZeroIds = [
    "Previous",
    "Back",
    "Next",
    "CloseMetadata",
    "HideMenu",
    "Yes",
    "No",
    "ConnectMessage",
    "NoItems",
    "MuteButton",
    "customerHelp",
    "Home",
    "MarketPlace",
    "PageNumbers"
  ];
  const radiusZeroIds = [
    "Previous",
    "Back",
    "Next",
    "MuteButton",
    "Home",
    "MarketPlace",
    "pageNumbers"
  ];

  const isRadiusZero = radiusZeroIds.includes(uniqueID);
  const isOpacityZero = opacityZeroIds.includes(uniqueID);
  return (
    <>
      <a-plane
        position={adjustedPosition}
        uniqueID={uniqueID}
        width={width}
        height={height}
        color={
          isUserItem
            ? theme.palette.mode === "light"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)"
            : primaryPlaneColor
        }
        outer_plane={`selectable: ${selectable}; primaryTextColor: ${primaryTextColor}; secondaryTextColor: ${secondaryTextColor}; primaryPlaneColor: ${
          isUserItem ? "white" : primaryPlaneColor
        }; secondaryPlaneColor: ${isUserItem ? "white" : secondaryPlaneColor}`}
        custom-rounded-rect={`width: ${
          uniqueID === "Title" ||
          uniqueID === "ControlBar" ||
          uniqueID === "TitleTwo"
            ? 0
            : width
        }; height: ${uniqueID === "Title" ? 0 : height}; radius: ${
          uniqueID === "Title" ? "0" : radius
        }; color: ${
          isUserItem ? "rgba(255, 255, 255, 0.4)" : "black"
        }; opacity: ${
          isOpacityZero || ismetadata ? "0" : isUserItem ? "0.6" : "0.6"
        }`}
        class={className}
        transition="property: transform; dur: 1000; easing: easeInOutQuad"
      >
        {image != null && (
          <>
            <a-entity
              position={"0 0 0.1"}
              custom-rounded-rect={`width: ${width - 0.6}; height: ${
                height - 0.6
              }; radius: ${
                uniqueID === "Title" || uniqueID === "ControlBar"
                  ? "2"
                  : isRadiusZero
                  ? "0"
                  : radius
              }; color: ${
                isUserItem
                  ? theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)"
                  : "black"
              }; opacity: ${isOpacityZero ? "0" : isUserItem ? "0.7" : "0.1"}`}
            />

            <a-image
              src={image}
              width={width - (isUserItem ? 1 : 0.6)}
              height={height - (isUserItem ? 1 : 0.6)}
              position={"0 0 0.1"}
              opacity={1}
            />
          </>
        )}

        {!isUserItem && (
          <>
            <a-entity
              position={"0 0 -0.1"}
              custom-rounded-rect={`width: ${width}; height: ${
                isRadiusZero ? 0 : height ? height : 3
              }; radius: ${
                uniqueID === "Title" || uniqueID === "ControlBar"
                  ? "2"
                  : isRadiusZero
                  ? "0"
                  : radius
              }; color: ${
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)"
              }; opacity: 0.6`}
            />

            <a-text
              width={textWidth}
              align={ismetadata ? "left" : "center"}
              white-space={ismetadata ? "pre" : "normal"}
              position={ismetadata ? "-3 0 0" : "0 0 0"}
              value={text}
              color={
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(255, 255, 255, 0.87)"
              }
            />
          </>
        )}
        {isUserItem && (
          <>
            <a-entity
              position={adjustedTextPosition}
              custom-rounded-rect={`width: ${width}; height: 1.8; radius: ${radius}; color: ${
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)"
              }; opacity: 0.6`}
            />
            <a-text
              width={textWidth}
              align={"center"}
              position={adjustedTextPosition}
              value={`${text}`}
              color={
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(255, 255, 255, 0.87)"
              }
            />

            {showAlphabetId && (
              <>
                <a-entity
                  position={`${0} ${height - 2.1} ${0}`}
                  custom-rounded-rect={`width: ${1.4}; height: 1.2; radius: ${radius}; color: ${
                    theme.palette.mode === "light"
                      ? "rgba(255, 255, 255, 0.4)"
                      : "rgba(0, 0, 0, 0.4)"
                  }; opacity: 0.6`}
                />
                <a-text
                  width={textWidth}
                  align={"center"}
                  position={`${0} ${height - 2.1} ${0}`}
                  value={`${alphabetId ? `${alphabetId}` : ""}`}
                  color={
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.4)"
                      : "rgba(255, 255, 255, 0.4)"
                  }
                />
              </>
            )}
          </>
        )}
      </a-plane>
    </>
  );
};

export default MenuItem;
