import JSZip from 'jszip';
import { saveAs } from 'file-saver';
const AWS = require('aws-sdk');

const wasabiEndpoint = new AWS.Endpoint(process.env.REACT_APP_WASABI_ENDPOINT);
const s3 = new AWS.S3({
    endpoint: wasabiEndpoint, // Use the Wasabi endpoint instance
    accessKeyId: process.env.REACT_APP_WASABI_ACCESS_KEY_ID, // Access key ID
    secretAccessKey: process.env.REACT_APP_WASABI_SECRET_ACCESS_KEY, // Secret access key
    region: process.env.REACT_APP_WASABI_REGION, // Region
});
const BucketName = process.env.REACT_APP_WASABI_BUCKET_NAME;
const WasabiBaseURL = `https://${process.env.REACT_APP_WASABI_ENDPOINT}/${BucketName}/`;

export async function GetFile(key) {
    const params = {
        Bucket: BucketName,
        Key: key,
    };
    try {
        const data = await s3.getObject(params).promise();
        return data.Body; // Returns the file content
    } catch (error) {
        console.error('Error retrieving file:', error);
    }
}

export async function CreateFolder(folderName, path) {

    const params = {
        Bucket: BucketName,
        Key: `${path}/${folderName}/`,
    };

    try {
        await s3.putObject(params).promise();
        return 200; // Success status code
    } catch (error) {
        console.error('Error creating folder:', error);
        throw error;
    }
}

export async function DeleteFolder(path) {

    const params = {
        Bucket: BucketName,
        Prefix: `${path}/`,
    };

    try {
        const listedObjects = await s3.listObjectsV2(params).promise();

        if (listedObjects.Contents.length === 0) return 204; // No content

        const deleteParams = {
            Bucket: BucketName,
            Delete: { Objects: listedObjects.Contents.map(({ Key }) => ({ Key })) },
        };

        await s3.deleteObjects(deleteParams).promise();
        return 200; // Success status code
    } catch (error) {
        console.error('Error deleting folder:', error);
        throw error;
    }
}

export async function RenameFolder(folderName, path, newFolderName) {

    const oldPath = `${path}/${folderName}/`;
    const newPath = `${path}/${newFolderName}/`;

    try {
        const listedObjects = await s3.listObjectsV2({ Bucket: "BucketName", Prefix: oldPath }).promise();

        if (listedObjects.Contents.length === 0) return 404; // Not Found

        for (const object of listedObjects.Contents) {
            const copyParams = {
                Bucket: BucketName,
                CopySource: `${BucketName}/${object.Key}`,
                Key: object.Key.replace(oldPath, newPath),
            };
            await s3.copyObject(copyParams).promise();
            await s3.deleteObject({ Bucket: BucketName, Key: object.Key }).promise();
        }

        return 200;
    } catch (error) {
        console.error('Error renaming folder:', error);
        throw error;
    }
}

export async function AddFile(fileName, path, file) {
        
    const params = {
        Bucket: BucketName,
        Key: `${path}/${fileName}`,
        Body: file,
    };

    try {
        await s3.upload(params).promise();
        return 200; // Success status code
    } catch (error) {
        console.error('Error adding file:', error);
        throw error;
    }
}

export async function RemoveFile(fileName, path) {
    const params = {
        Bucket: BucketName,
        Key: `${path}/${fileName}`,
    };

    try {
        await s3.deleteObject(params).promise();
        return 200; // Success status code
    } catch (error) {
        console.error('Error removing file:', error);
        throw error;
    }
}

export async function RenameFile(fileName, path, newFileName) {
    const oldKey = `${path}/${fileName}`;
    const newKey = `${path}/${newFileName}`;

    try {
        const copyParams = {
            Bucket: BucketName,
            CopySource: `${BucketName}/${oldKey}`,
            Key: newKey,
        };

        await s3.copyObject(copyParams).promise();
        await s3.deleteObject({ Bucket: BucketName, Key: oldKey }).promise();
        return 200; // Success status code
    } catch (error) {
        console.error('Error renaming file:', error);
        throw error;
    }
}

export async function UpdateFile(fileName, path, file, append = false) {
    const key = `${path}/${fileName}`;

    try {
        if (append) {
            const existingData = await GetFile(key);
            const updatedData = Buffer.concat([existingData, file]);

            const params = {
                Bucket: BucketName,
                Key: key,
                Body: updatedData,
            };

            await s3.putObject(params).promise();
        } else {
            const params = {
                Bucket: BucketName,
                Key: key,
                Body: file,
            };

            await s3.putObject(params).promise();
        }
        return 200; // Success status code
    } catch (error) {
        console.error('Error updating file:', error);
        throw error;
    }
}


export async function ListAllChildDirectoriesAndFiles(folderPath) {
    const params = {
        Bucket: BucketName,
        Prefix: folderPath, // Start from this folder
        Delimiter: '', // Recursive listing by ignoring folder breaks
    };

    const output = {
        items: [],   // List of files
        prefixes: [], // List of folders
    };

    let continuationToken = null;

    try {
        do {
            const result = await s3.listObjectsV2({
                ...params,
                ContinuationToken: continuationToken, // Handles pagination
            }).promise();
    
            // Separate items into files and folders
            result.Contents.forEach(item => {
                // Skip invalid or empty keys
                if (!item.Key || item.Key.trim() === '') {
                    console.warn('Skipping invalid key:', item.Key);
                    return;
                }
    
                // Skip "null" folders or objects
                if (item.Key === 'null/' || item.Key.startsWith('null/')) {
                    console.warn('Skipping "null" folder or objects:', item.Key);
                    return;
                }
    
                if (item.Key.endsWith('/')) {
                    // If the key ends with a '/', it's a folder
                    output.prefixes.push({ path: item.Key });
                } else {
                    // Otherwise, it's a file
                    output.items.push({
                        path: item.Key,
                        size: item.Size,
                        updated: item.LastModified,
                    });
                }
            });
    
            continuationToken = result.IsTruncated ? result.NextContinuationToken : null;
        } while (continuationToken);
    
        return output;
    } catch (error) {
        console.error('Error listing files and folders:', error);
    }
    
}


export async function GetAllAndDownload(folderPath = '') {
    const zip = new JSZip(); // Create a new ZIP instance

    async function fetchAndAddFilesToZip(prefix, zipFolder) {
        const params = {
            Bucket: BucketName,
            Prefix: prefix,
        };

        try {
            let continuationToken = null;

            do {
                const result = await s3
                    .listObjectsV2({
                        ...params,
                        ContinuationToken: continuationToken, // Handles pagination
                    })
                    .promise();

                for (const item of result.Contents) {
                    if (item.Key.endsWith('/')) {
                        // Skip folders since they are implicit in ZIP
                        continue;
                    }
                    const fileData = await s3
                        .getObject({ Bucket: BucketName, Key: item.Key })
                        .promise();

                    // Add the file to the ZIP
                    const relativePath = item.Key.replace(folderPath, ''); // Remove the base folder path
                    zipFolder.file(relativePath, fileData.Body);
                }

                continuationToken = result.IsTruncated ? result.NextContinuationToken : null;
            } while (continuationToken);
        } catch (error) {
            console.error('Error fetching files for ZIP:', error);
            throw error;
        }
    }

    try {
        const zipFolder = zip.folder(folderPath || 'root'); // Create a folder in ZIP
        await fetchAndAddFilesToZip(folderPath, zipFolder);

        // Generate the ZIP file and trigger download
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, 'download.zip');
    } catch (error) {
        console.error('Error generating ZIP file:', error);
        throw error;
    }
}
