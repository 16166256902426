import React, { useState, useEffect, useContext } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import PlaceIcon from "@mui/icons-material/Place";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { CircularProgress } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import { Divider, Stack, Tooltip, Typography } from "@mui/material";
import {
  getFile,
  ConvertPathIntoRawPath,
} from "../CloudStorage/cloudStorageAbstraction";
import { AppContext } from "../../AppContext";

export default function FloorPlanPopper({
  floorPlanAnchor,
  setFloorPlanAnchor,
  floorPlanProjectID,
  setFloorPlanProjectID,
  floorPlanFloorID,
  setFloorPlanFloorID,
  pinViewID,
  setPinViewID,
  pinRoomID,
  setPinRoomID,
  createFloorPlan,
  deleteFloorPlan,
  saveFloorPlanMetadata,
  isMinimized,
  setIsMinimized,
}) {
  const {
    storageRef,
    fs,
    setMessage,
    handleSnackClick,
    metadataRef,
    setOpen,
    floorPlanOpen,
    setFloorPlanOpen,
  } = useContext(AppContext);
  const theme = useTheme();
  const [hasFloorPlan, setHasFloorPlan] = useState(false);
  const [floorPlanMetadata, setFloorPlanMetadata] = useState({});
  const [currentPinPosition, setCurrentPinPosition] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (floorPlanAnchor) {
      setIsMinimized(false);
    }
  }, [floorPlanAnchor]);

  useEffect(() => {
    if (isMinimized) {
      setFloorPlanAnchor(null);
      setOpen(false);
    }
  }, [isMinimized]);

  useEffect(() => {
    if (metadataRef != "" && metadataRef != null && metadataRef) {
      //extract roomID and viewID from the path
      const pathArray = metadataRef.split("/");
      const projectID = pathArray[1];
      const floorID = pathArray[2];
      const viewID = pathArray[4];
      const roomID = pathArray[3];
      if (!isMinimized && (floorPlanProjectID != projectID || floorPlanFloorID != floorID && floorPlanProjectID && floorPlanFloorID))
        return;
      setFloorPlanProjectID(projectID);
      setFloorPlanFloorID(floorID);
      //get the keys of the object and check if the room and view are already pinned
      const keys = Object.keys(floorPlanMetadata);
      for (let key of keys) {
        if (
          floorPlanMetadata[key].roomID == roomID &&
          floorPlanMetadata[key].viewID == viewID
        ) {
          setCurrentPinPosition(floorPlanMetadata[key].position);
          //get out of the loop
          return;
        } else {
          setCurrentPinPosition(null);
        }
      }
    }
  }, [floorPlanMetadata, metadataRef, isMinimized]);

  useEffect(() => {
    if (metadataRef != "" && metadataRef != null && metadataRef) {
      const rawPath = ConvertPathIntoRawPath(metadataRef);
      //extract roomID and viewID from the path
      const pathArray = rawPath.split("/");
      const projectID = pathArray[1];
      const floorID = pathArray[2];
      saveFloorPlanMetadata(
        floorPlanProjectID,
        floorPlanFloorID,
        floorPlanMetadata
      );
      setFloorPlanOpen(false);
      setFloorPlanAnchor(null);
      setIsMinimized(false);
      setCurrentPinPosition(null);
      if (floorPlanProjectID != projectID || floorPlanFloorID != floorID && floorPlanProjectID && floorPlanFloorID)
        return;
      setFloorPlanProjectID(projectID);
      setFloorPlanFloorID(floorID);

    }
  }, [metadataRef]);

  useEffect(() => {
    if (metadataRef != "" && metadataRef != null && metadataRef) {
      setPinViewID(null);
      setPinRoomID(null);
      const rawPath = ConvertPathIntoRawPath(metadataRef);
      //extract roomID and viewID from the path
      const pathArray = rawPath.split("/");
      const projectID = pathArray[1];
      const floorID = pathArray[2];
      const viewID = pathArray[3];
      const roomID = pathArray[4];
      if (floorPlanProjectID != projectID || floorPlanFloorID != floorID && floorPlanProjectID && floorPlanFloorID)
        return;
      setFloorPlanProjectID(projectID);
      setFloorPlanFloorID(floorID);
    }
  }, [floorPlanOpen]);

  useEffect(() => {
    if (floorPlanProjectID && floorPlanFloorID) {
      const floorPlan =
        fs[floorPlanProjectID]?.floors[floorPlanFloorID]?.hasFloorPlan;
      setHasFloorPlan(floorPlan);
      const rawPath =
        ConvertPathIntoRawPath(storageRef._location.path_) +
        "/" +
        floorPlanProjectID +
        "/" +
        floorPlanFloorID +
        "/" +
        floorPlanFloorID;
      if (floorPlan)
        getFile(rawPath + ".png").then((data) => {
          if (data instanceof Uint8Array) {
            const blob = new Blob([data], { type: "image/png" });
            const reader = new FileReader();

            reader.onloadend = () => {
              const img = document.getElementById("floorPlan");
              if (img) img.src = reader.result;
              setFloorPlanAnchor(floorPlanAnchor?.parentNode);
              setImageLoaded(true);
            };
            setCurrentPinPosition(null);

            getFile(rawPath + "-metadata.json").then((data) => {
              setFloorPlanMetadata(JSON.parse(data));
            });

            reader.readAsDataURL(blob);
          }
        });
    }
  }, [floorPlanProjectID, floorPlanFloorID, floorPlanOpen, storageRef]);

  const createFileInput = (callback) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.click();
    fileInput.addEventListener("change", (e) => {
      const file = e.target.files[0];
      callback(file);
    });
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (pinRoomID && pinViewID && !isMinimized) {
      const image = e.target;
      const rect = image.getBoundingClientRect();
      const x = ((e.clientX - rect.left) / rect.width) * 100; // Get x as percentage
      const y = ((e.clientY - rect.top) / rect.height) * 100; // Get y as percentage
      //get keys of the object
      const keys = Object.keys(floorPlanMetadata);
      //get the missing number in the keys or the next number
      const usedNumbers = new Set();

      for (let key of keys) {
        usedNumbers.add(parseInt(key));
      }
      // Find the next available sphere number, skipping 0
      let num = 1;
      while (usedNumbers.has(num)) {
        num++;
      }
      const nextNumber = num;

      //check if the room and view are already pinned
      for (let key in floorPlanMetadata) {
        if (
          floorPlanMetadata[key].roomID === pinRoomID &&
          floorPlanMetadata[key].viewID === pinViewID
        ) {
          setMessage("This room and view are already pinned on the floor plan");
          handleSnackClick();
          return;
        }
      }

      const newMarkerData = {
        position: { x, y },
        projectID: floorPlanProjectID,
        floorID: floorPlanFloorID,
        roomID: pinRoomID,
        viewID: pinViewID,
        roomName:
          fs[floorPlanProjectID]?.floors[floorPlanFloorID]?.rooms[pinRoomID]
            ?.name,
        viewName:
          fs[floorPlanProjectID]?.floors[floorPlanFloorID]?.rooms[pinRoomID]
            ?.views[pinViewID]?.name,
      };
      const newMetaData = { ...floorPlanMetadata, [nextNumber]: newMarkerData };
      setFloorPlanMetadata(newMetaData);
      setPinRoomID(null);
      setPinViewID(null);
    } else if (isMinimized) {
      //set Anchor to the node of the floor plan
      const uniqueId = floorPlanProjectID + floorPlanFloorID;
      const floorPlanNode = document.querySelector(`[uniqueId="${uniqueId}"]`);
      setIsMinimized(false);
      setOpen(true);
      setFloorPlanAnchor(floorPlanNode);
    } else {
      setMessage("Please select a view first to pin it on the floor plan");
      handleSnackClick();
    }
  };

  const Marker = ({ number, x, y }) => {
    const markerData = floorPlanMetadata[number];
    return (
      <Stack
        spacing={1}
        direction={"column"}
        alignItems={"center"}
        onClick={(e) => {
          e.stopPropagation();
          //remove the marker
          const newMetaData = { ...floorPlanMetadata };
          delete newMetaData[number];
          setFloorPlanMetadata(newMetaData);
        }}
        sx={{
          cursor: "pointer",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: `${x}%`,
            top: `${y}%`,
            transform: "translate(-50%, -50%)",
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            border: "2px solid blue",
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "blue",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {number}
        </div>
        <p
          style={{
            position: "absolute",
            left: `${x}%`,
            top: `${y + 4}%`,
            transform: "translate(-50%, -50%)",
            color: "black",
          }}
        >
          {markerData?.roomName}:{markerData?.viewName}
        </p>
      </Stack>
    );
  };

  return (
    <Popper
      open={floorPlanOpen}
      anchorEl={floorPlanAnchor}
      style={{
        zIndex: 1201,
        position: isMinimized ? "absolute" : "absolute",
        left: isMinimized ? "83%" : "auto",
        top: isMinimized ? "83%" : "auto",
        width: "fit-content",
        height: "fit-content",
      }}
      placement="right-end"
      modifiers={[
        {
          name: "preventOverflow",
          options: {
            boundary: "viewport",
          },
        },
      ]}
    >
      <Box
        sx={{
          p: 1,
          borderRadius: 2,
          maxHeight: "60vh",
          width: isMinimized ? "fit-content" : "fit-content",
          maxWidth: "70vw",
          bgcolor:
            theme.palette.mode === "light"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)",
          backdropFilter: "blur(24px)",
          border: "1px solid",
          borderColor: "divider",
          boxShadow:
            theme.palette.mode === "light"
              ? "0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)"
              : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
          overflowY: "auto",
          overflowX: "hidden",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <Stack spacing={1} width={isMinimized ? "15vw" : "100%"}>
          {!isMinimized && (
            <>
              <Typography
                sx={{
                  position: "relative",
                  alignSelf: "center",
                }}
                variant="h5"
              >
                {fs[floorPlanProjectID]?.floors[floorPlanFloorID]?.name}
              </Typography>
              {hasFloorPlan && (
                <Stack
                  sx={{
                    zIndex: 1,
                    cursor: "pointer",
                    position: "absolute",
                    alignSelf: "flex-end",
                    margin: "0 !important",
                    padding: 0,
                    alignItems: "center",
                  }}
                  direction={"row"}
                  spacing={0}
                >
                  <Tooltip title="Delete Floor Plan">
                    <Button
                      variant="text"
                      color="error"
                      disabled={!hasFloorPlan}
                      onClick={async () => {
                        await deleteFloorPlan(floorPlanProjectID, floorPlanFloorID);
                        setHasFloorPlan(false);
                        setImageLoaded(false);
                      }}
                      size="small"
                    >
                      <DeleteOutlineIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Update Floor Plan">
                    <Button
                      variant="text"
                      disabled={!hasFloorPlan}
                      color="primary"
                      onClick={async () => {
                        createFileInput(async (file) => {
                          if (file) {
                            await deleteFloorPlan(
                              floorPlanProjectID,
                              floorPlanFloorID
                            );
                            await createFloorPlan(
                              floorPlanProjectID,
                              floorPlanFloorID,
                              file
                            );
                            setImageLoaded(false);
                          }
                        });
                      }}
                      size="small"
                    >
                      <FolderIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Save & Close">
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => {
                        setFloorPlanAnchor(null);
                        setFloorPlanFloorID(null);
                        setFloorPlanProjectID(null);
                        setFloorPlanOpen(false);
                        setImageLoaded(false);
                        if (hasFloorPlan)
                          saveFloorPlanMetadata(
                            floorPlanProjectID,
                            floorPlanFloorID,
                            floorPlanMetadata
                          );
                      }}
                      size="small"
                    >
                      <CloseIcon />
                    </Button>
                  </Tooltip>
                </Stack>
              )}
              {!hasFloorPlan && (
                <CloseIcon
                  style={{
                    position: "absolute",
                    right: "4",
                    top: "0",
                    cursor: "pointer",
                  }}
                  size="small"
                  onClick={() => {
                    setFloorPlanOpen(false);
                    setFloorPlanAnchor(null);
                    setFloorPlanFloorID(null);
                    setFloorPlanProjectID(null);
                  }}
                />
              )}
              <Divider />
            </>
          )}

          {hasFloorPlan ? (
            <div
              className="floor-plan-container"
              style={{
                position: "relative",
                width: isMinimized ? "15vw" : "60vw",
              }}
            >
              <img
                id="floorPlan"
                alt="Floor Plan"
                onClick={handleClick}
                style={{
                  width: "100%",
                  maxWidth: isMinimized ? "15vw" : "60vw",
                  height: "auto",
                  maxHeight: isMinimized ? "14vh" : "50vh",
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                }}
              />
              {!imageLoaded && (
                <Typography
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  variant="h5"
                >
                  Loading... <CircularProgress />
                </Typography>
              )}
              {floorPlanMetadata &&
                !isMinimized &&
                Object.keys(floorPlanMetadata).map((key) => (
                  <Marker
                    key={key}
                    number={key}
                    x={floorPlanMetadata[key].position.x}
                    y={floorPlanMetadata[key].position.y}
                  />
                ))}
              {currentPinPosition && isMinimized && (
                <PlaceIcon
                  style={{
                    position: "absolute",
                    left: `${currentPinPosition.x}%`,
                    top: `${currentPinPosition.y}%`,
                    transform: "translate(-50%, -50%)",
                    color: "red",
                    fontSize: "30px",
                  }}
                />
              )}
            </div>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                createFileInput(async (file) => {
                  await createFloorPlan(
                    floorPlanProjectID,
                    floorPlanFloorID,
                    file
                  );
                });
              }}
            >
              Click here to upload floor plan
            </Button>
          )}
        </Stack>
      </Box>
    </Popper>
  );
}
