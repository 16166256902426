import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import ReactDOM from 'react-dom'; 
import CompassIcon from '../../icons/compass.svg';
import CompassDirectionDialog from './CompassDirectionDialog';

const ACompass = ({ visible, mode,propSalientDirection='North', propUpDirection=(new window.THREE.Vector3(0,1,0)),propDirectionOfSalientDirection=(new window.THREE.Vector3(0,0,-1))}) => {




useEffect(() => {
    if(propSalientDirection && propUpDirection && propDirectionOfSalientDirection){
    setSalientDirection(propSalientDirection);
    setUpDirection(propUpDirection);
    setDirectionOfSalientDirection(propDirectionOfSalientDirection);
    }
    else{
        setSalientDirection('North');
        setUpDirection(new window.THREE.Vector3(0,1,0));
        setDirectionOfSalientDirection(new window.THREE.Vector3(0,0,-1));
    }
  }, [propSalientDirection, propUpDirection, propDirectionOfSalientDirection]);

    const [showCompassDialog, setShowCompassDialog] = useState(false);
    const [salientDirection, setSalientDirection] = useState(propSalientDirection);
    const [upDirection, setUpDirection] = useState(propUpDirection);
    const [directionOfSalientDirection, setDirectionOfSalientDirection] = useState(propDirectionOfSalientDirection);

    const dialogContainerRef = useRef(null);  // Create a ref for the dialog container

    const setCompassDirections = (salientDir, upDir, dirSalientDir) => {
        setSalientDirection(salientDir);
        setUpDirection(upDir);
        setDirectionOfSalientDirection(dirSalientDir);
        setShowCompassDialog(false);
    };

    const cancelDialog = () => {
        setShowCompassDialog(false);
    };

    
  useEffect(() => {
    // Check if the dialog container exists, if not, create and append it
    if (!dialogContainerRef.current) {
        const container = document.createElement('div');
        container.id = 'compass_edit_dialog_container';
        document.body.appendChild(container);
        dialogContainerRef.current = container;
      }
  
      // Cleanup dialog container when the component is unmounted
      return () => {
      };
  }, []);


    const handleClick = (event) => {
        event.stopPropagation();
        // Toggle the dialog visibility
        setShowCompassDialog(true);
    };

    if (typeof window.AFRAME !== 'undefined' && !window.AFRAME.components["compass-plane"]) {
        // Register the component if AFRAME is loaded
        window.AFRAME.registerComponent('compass-plane', {
            schema: {
                up: { type: 'vec3', default: '1 0 0' },
                salDir: { type: 'vec3', default: '0 0 -1' },
                distance: { type: 'number', default: 3 }, 
            },

            init: function () {
                this.el.setAttribute('position', {
                    x: 0,
                    y: -1.25,
                    z: -4,
                });
            },

            tick: function () {
                // Get the camera entity position
                const camera = document.querySelector('[camera]');

                let cameraPosition = new window.THREE.Vector3();
                camera.object3D.getWorldPosition(cameraPosition);
                
                let cameraDirection = new window.THREE.Vector3();
                camera.object3D.getWorldDirection(cameraDirection);
                cameraDirection.normalize();

                let localUp = new window.THREE.Vector3(0, 1, 0);
                let cameraUp = localUp.applyQuaternion(camera.object3D.quaternion);

                this.el.setAttribute('position', {
                    x: cameraPosition.x - 2 * cameraDirection.x - cameraUp.x,
                    y: cameraPosition.y - 2 * cameraDirection.y - cameraUp.y,
                    z: cameraPosition.z - 2 * cameraDirection.z - cameraUp.z,
                });

                
                let zA = new window.THREE.Vector3(this.data.up.x, this.data.up.y, this.data.up.z);

                let yA = new window.THREE.Vector3(this.data.salDir.x, 
                    this.data.salDir.y, this.data.salDir.z);

                let xA = new window.THREE.Vector3().crossVectors(yA, zA);

                xA.normalize();
                yA.normalize();
                zA.normalize();

                let rotationMatrix = new window.THREE.Matrix4();
                rotationMatrix.set(
                  xA.x, yA.x, zA.x, 0,
                  xA.y, yA.y, zA.y, 0,
                  xA.z, yA.z, zA.z, 0,
                  0, 0, 0, 1
                );

                let euler = new window.THREE.Euler().setFromRotationMatrix(rotationMatrix);

                const xDegrees = window.THREE.MathUtils.radToDeg(euler.x);
                const yDegrees = window.THREE.MathUtils.radToDeg(euler.y);
                const zDegrees = window.THREE.MathUtils.radToDeg(euler.z);

                this.el.setAttribute('rotation', `${xDegrees} ${yDegrees} ${zDegrees}`);

            },
        });
    }

    if (typeof window.AFRAME !== 'undefined' && !window.AFRAME.components["camera-plane"]) {
        // Register the component if AFRAME is loaded
        window.AFRAME.registerComponent('camera-plane', {
            tick: function () {
                // Get the camera entity position
                const camera = document.querySelector('[camera]');

                let cameraPosition = new window.THREE.Vector3();
                camera.object3D.getWorldPosition(cameraPosition);
                
                this.el.object3D.lookAt(cameraPosition);
            },
        });
    }

    // If not visible, do not render the compass
    if (!visible) return null;

    // Compute the Orientation of the image
    let salientRotation = 0;
    switch(salientDirection){
        case 'North':
            break;
        case 'East':
            salientRotation = 90;
            break;
        case 'South':
            salientRotation = 180;
            break;
        case 'West':
            salientRotation = 270;
            break;
    }

    return (
        <a-entity>
            <a-plane
                compass-plane={`up: ${upDirection.x} ${upDirection.y} ${upDirection.z}; salDir: ${directionOfSalientDirection.x} ${directionOfSalientDirection.y} ${directionOfSalientDirection.z}; distance: 2`}
                opacity="0"
                width={1}
                height={1}
            >
                <a-entity rotation={`0 0 ${salientRotation}`}>
                    <a-image
                    position={visible ? "0 0 0" : "0 -1000 0"}
                        src={CompassIcon}
                        width={1}
                        height={1}   
                        class="clickable"      
                        onClick={handleClick}                                 
                    >
                    </a-image>                    
                </a-entity>                
            </a-plane>
            {showCompassDialog && dialogContainerRef.current &&
                ReactDOM.createPortal(
                    <CompassDirectionDialog 
                        mode = {mode} 
                        defaultDirection={salientDirection}
                        setCompassDirections = {setCompassDirections}
                        cancelDialog = {cancelDialog}
                    />, 
                    dialogContainerRef.current
                )
            }
        </a-entity>
    );
};

export default ACompass;