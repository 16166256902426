import React from "react";
import "aframe";
import interia_lobby from "../icons/interia_lobby.jpeg";

export default function Test() {
  return (
    <div>
      <h1>Test Component</h1>
      <a-scene>
        <a-sky src={interia_lobby.default || interia_lobby}></a-sky>
        <a-camera fov="20"></a-camera> {/* Adjust the FOV here */}
      </a-scene>
    </div>
  );
}
