import React, { useEffect } from "react";
import closeCircleBlueIcon from "../../icons/close_circle_blue.svg";
import "aframe";

const NameValuePairs = ({
  menuId,
  data,
  isComment,
  dontShowkeys,
  textColor = "#000",
  backgroundColor = "#fff",
  selectable,
  uniqueID,
}) => {
  let menuLabel = menuId;
  let dataCopy = [];
  data.forEach((element) => {
    if (element.name.toLowerCase() == "name") {
      menuLabel = element.value + " (" + menuId + ")";
    } else {
      dataCopy.push(element);
    }
  });

  console.log("dataCopy", dataCopy, dataCopy.length);

  const maxCharWidth = 0.07;
  
  const maxNameLength = Math.max(...dataCopy.map((item) => item.name.length));
  const maxValueLength = Math.max(...dataCopy.map((item) => item.value.length));
  const padding = 0.2;
  const lineHeight = 0.2;
  let planeWidth = 3;
  const maxCharsPerLine = Math.floor(planeWidth / maxCharWidth);
  console.log(dataCopy[0].value.length, maxCharsPerLine);
  
  let planeHeight = isComment ? 2.5: lineHeight * (dataCopy.length + 2);
  if(isComment){
    if(dataCopy[0].value.length > maxCharsPerLine)
    var samplePlaneHeight = Math.ceil(dataCopy[0].value.length / maxCharsPerLine) * lineHeight;
    else
    var samplePlaneHeight = 3*lineHeight;
    planeHeight = Math.min(samplePlaneHeight, planeHeight);
  }
  console.log("planeWidth", planeWidth);
  console.log("planeHeight", planeHeight);

  // Check if any name matches elements in dontShowkeys
  const shouldResizePlane = dataCopy.some((item) =>
    dontShowkeys.includes(item.name.toLowerCase())
  );

  if (shouldResizePlane && !isComment) {
    planeWidth = 3;
    
    dataCopy.forEach((item) => {
      if (dontShowkeys.includes(item.name.toLowerCase())) {
        const lines = Math.ceil(item.value.length / maxCharsPerLine); // Calculate number of lines required
        const heightForValue = lines * lineHeight; // Height for this item's value
        planeHeight += heightForValue; // Add it to the total height
      }
    });
    planeHeight = planeHeight / 1.5;
  }

  // if(planeWidth < minwidth){
  //   planeWidth = minwidth;
  // }
  if (maxNameLength == 0 || maxValueLength == 0) return null;
  return (
    <a-entity position="0 -0.3 0">
      {/* Rounded rectangle with name-value pairs */}
      <a-entity>
        <a-entity
          position={`0 0 -3`}
          custom-rounded-rect={`width: ${planeWidth}; height: ${planeHeight}; radius: 0.05; color: ${backgroundColor}; opacity: 0.8`}
        ></a-entity>
        <a-entity
          position={`0 0 -3`}
          custom-rounded-rect={`width: ${planeWidth}; height: ${planeHeight}; radius: 0.05; color: ${backgroundColor}; opacity: 0`}
        >
          {
            <a-entity>
              <a-text
                value={menuLabel}
                position={`0 ${planeHeight / 2 - padding / 2} 0.01`}
                align="center"
                color={textColor}
                width="2"
              ></a-text>
              <a-plane
                position={`0 ${planeHeight / 2 - 0.2} 0.01`} // Adjusted position
                width={planeWidth * 0.9} // Adjust width as needed
                height="0.01"
                color="#779ECB"
              />
            </a-entity>
          }
          {dataCopy.map((item, index) => {
            const yPosition = isComment
              ? 0
              : planeHeight / 2 - padding / 2 - (index + 1) * lineHeight;
            const namePosition = `-${
              planeWidth / 2 - padding / 2
            } ${yPosition} 0.01`;
            const colonPosition = `${
              -planeWidth / 2 + maxNameLength * maxCharWidth + padding / 2
            } ${yPosition} 0.01`;
            const valuePosition = `${
              -planeWidth / 2 + (maxNameLength + 1) * maxCharWidth + padding / 2
            } ${yPosition} 0.01`;

            if (item.name.toLowerCase() == "name" || item.name.length === 0) {
              return;
            }
            if (isComment) {
              console.log("value", item.value);
              
              return (
                <a-text
                  value={item.value}
                  position={ namePosition }
                  align="left"
                  color={textColor}
                  wrap-count="50"
                  width={planeWidth-0.2}
                ></a-text>
              );
            }

            // If the name is in dontShowkeys, only show the value
            const shouldHideKey = dontShowkeys.includes(
              item.name.toLowerCase()
            );

            return (
              <a-entity key={index}>
                {!shouldHideKey && (
                  <a-text
                    value={item.name}
                    position={namePosition}
                    align="left"
                    color={textColor}
                    width="2"
                  ></a-text>
                )}
                {!shouldHideKey && (
                  <a-text
                    value=":"
                    position={colonPosition}
                    align="left"
                    color={textColor}
                    width="2"
                  ></a-text>
                )}
                <a-text
                  value={item.value}
                  position={!shouldHideKey ? valuePosition : "-1 0 0"}
                  align="left"
                  color={textColor}
                  width="2"
                ></a-text>
              </a-entity>
            );
          })}
          {
            <a-entity>
              <a-plane
                position={`0 ${-planeHeight / 2 + 0.2} 0.01`} // Adjusted position
                width={planeWidth * 0.9} // Adjust width as needed
                height="0.01"
                color="#779ECB"
              />
            </a-entity>
          }
        </a-entity>
      </a-entity>
      {/* Close icon below the rectangle */}
      <a-image
        position={`0 ${-(planeHeight / 2 - 0.095)} -3`}
        class="menu raycastable"
        geometry="primitive: plane; width: 0.13; height: 0.13"
        material={`src: ${closeCircleBlueIcon}; transparent: true`}
        uniqueID={uniqueID}
        outer_plane={`selectable: ${selectable}; primaryPlaneColor: ${"#bad7ff"}; secondaryPlaneColor: ${"#bad7ff"}`}
      ></a-image>
    </a-entity>
  );
};

export default NameValuePairs;
