import React, { useEffect, useContext } from 'react';
import Typography from '@mui/material/Typography';
import { auth } from '../authentication/Firebase';
import { getFile } from '../CloudStorage/cloudStorageAbstraction'; // Assuming `getFile` is a utility to fetch files from Wasabi
import { AppContext } from '../../AppContext';
const User = ({ theme, mode }) => {
  const user = auth.currentUser; // The current user from Firebase authentication
  const [firstname, setFirstname] = React.useState("Please");
  const [lastname, setLastname] = React.useState("Wait...");
  const [organization, setOrganization] = React.useState("");
  const [textColor, setTextColor] = React.useState("#000000");
  const {
    setUserSubscriptionPlan,
    setUserSubscriptionStartDate,
    setUserSubscriptionEndDate,
    } = useContext(AppContext);

  // Modify the theme's palette mode
  useEffect(() => {
    theme.palette.mode = mode;
  }, [mode]);

  // Fetch user data from the JSON file on storage
  useEffect(() => {
    if (user) {
      const jsonFileName = "multivrseInteriaUsers.json";
      const jsonFilePath = "users/";
      const fetchUserData = async () => {
        try {
          // Fetch the JSON file from storage
          const fileBuffer = await getFile(jsonFilePath + jsonFileName);
          const usersData = JSON.parse(fileBuffer.toString("utf-8"));
          const userData = usersData[0][user.uid];
          //set this userdata in the context email: "dummy@two.com"firstname: "dummy"lastname: "two"organization: "dummy"subscriptionEndDate: "2025-04-03"subscriptionPlan: "Small Business"subscriptionStartDate: "2025-01-03"userType: "Designer"
          //if end data is greater than current date then show error
          //if subscriptionEndDate is greater than current date then show error
          if(userData.subscriptionEndDate < new Date().toISOString().split('T')[0]){
            console.error("Subscription Expired");
            setFirstname("Subscription");
            setLastname("Expired");
          }
          if (userData) {
            if(userData.subscriptionEndDate && userData.subscriptionStartDate){
             if(userData.subscriptionEndDate < new Date().toISOString().split('T')[0]){
              console.error("Subscription Expired");
              setFirstname("Subscription");
              setLastname("Expired");
            }
            else if(userData.subscriptionStartDate > new Date().toISOString().split('T')[0]){
              console.error("Subscription didn't start yet!");
              setFirstname("Subscription");
              setLastname("Yet to start");
            }
            setUserSubscriptionStartDate(userData.subscriptionStartDate);
            setUserSubscriptionEndDate(userData.subscriptionEndDate);
            }
            setFirstname(userData.firstname);
            setLastname(userData.lastname);
            setOrganization(userData.organization);
            setUserSubscriptionPlan(userData.subscriptionPlan);
          } else {
            console.error("User data not found.");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
      fetchUserData();
    }
  }, [user]);

  useEffect(() => {
 if (mode == 'dark') {
     setTextColor('#FFFFFF');
  } else {
    setTextColor('#000000');
  }
}, [mode]);

  // Capitalize the first letter of a string
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* <Avatar alt={firstname} src={avatarUrl} /> */}
      <Typography
        variant="body1"
        style={{
          marginLeft: 15,
          fontFamily: 'Audiowide, sans-serif',
          color: textColor
        }}
      >
        {capitalize(firstname)} . {capitalize(lastname)} {(organization !== "undefined" && organization !== "null" && organization) ? "@ " + organization : ""}
      </Typography>

      {/* <KeyboardDoubleArrowRightIcon style={{ marginLeft: 8 }} /> */}
    </div>
  );
};

export default User;