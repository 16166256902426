import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Divider
} from "@mui/material";
import getLPTheme from "../../getLPTheme.js";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseCircleOutlineIcon from '@mui/icons-material/HighlightOff';
import IconButton from "@mui/material/IconButton";

const CompassDirectionDialog = ({mode, defaultDirection, setCompassDirections, cancelDialog}) => {
  const theme = getLPTheme(mode);
  const [salientDirection, setSalientDirection] = useState(defaultDirection);

  const handleSubmit = () => {
 
    const cameraEl = document.querySelector("[camera]");
  
    const upDirLocal = new window.THREE.Vector3(0, 1, 0);
    const cameraRotation = cameraEl.object3D.quaternion;  
    const upDir = upDirLocal.applyQuaternion(cameraRotation);
  
    let salDir = new window.THREE.Vector3();
    cameraEl.object3D.getWorldDirection(salDir);
    salDir.negate();
    
    setCompassDirections(salientDirection, upDir, salDir);

    // Create a custom event with the necessary data
    const compassDirectionChangedEvent = new CustomEvent("compassDirectionChanged", {
      detail: {
        salientDirection,  // The selected direction (e.g., 'North', 'South', etc.)
        upDir,             // The up direction vector
        salDir             // The salient direction vector (view direction)
      }
    });
  
    // Dispatch the event globally
    window.dispatchEvent(compassDirectionChangedEvent);
  };
  

  const handleCancel = () => {
    cancelDialog();
  };
 
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%", // Adjust top position
        left: "50%", // Adjust left position
        transform: "translate(-50%, -50%)",
        bgcolor: "rgba(0, 0, 0, 0.9)", // Semi-transparent background
        p: 0,
        borderRadius: 2,
        boxShadow: 3,
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent", 
        zIndex: 10
      }}
    >
      <FormControl 
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "auto", // Adjust height based on content
          maxWidth: 400,  // Optional: Limit max width
          width: 210,
          margin: "2 auto", // Center horizontally
          backgroundColor: theme.palette.background.paper,
          borderRadius: 2, // Rounded corners
          opacity: 1,
        }}
      >
        <FormLabel 
          component="legend"
          sx={{
            color: theme.palette.text.primary,
            marginTop: 1, // Add margin for spacing
            fontSize: "1.25rem",
          }}
        >
          Choose a Direction
        </FormLabel>

        <Divider sx={{ width: "90%", marginBottom: 2, marginTop: 1, borderWidth: 1, borderColor: theme.palette.primary.main }} />

        <RadioGroup
          value={salientDirection}
          defaultValue="North"
          onChange={(event)=>{ setSalientDirection(event.target.value);}}
          column
        >
          <FormControlLabel value="North" control={<Radio />} label="North" />
          <FormControlLabel value="South" control={<Radio />} label="South" />
          <FormControlLabel value="East" control={<Radio />} label="East" />
          <FormControlLabel value="West" control={<Radio />} label="West" />
        </RadioGroup>

        <Divider sx={{ width: "90%", marginBottom: 1, marginTop: 1, borderWidth: 1, borderColor: theme.palette.primary.main, borderStyle: 'dashed' }} />

        <Box
          sx={{
            display: 'flex',    // Align items horizontally
            justifyContent: 'center',  // Optional: center the icons
          }}
        >
          <IconButton
            color={theme.palette.text.primary}
            onClick={handleSubmit}
            sx={{
              "&:hover": {
                transform: "scale(1.2)",
                transition: "transform 0.3s ease",
              },
            }}
          >
            <CheckCircleOutlineIcon />
          </IconButton>

          <IconButton
            color={theme.palette.text.primary}
            onClick={handleCancel} // Close dialog
            sx={{
              "&:hover": {
                transform: "scale(1.2)",
                transition: "transform 0.3s ease",
              },
            }}
          >
            <CloseCircleOutlineIcon />
          </IconButton>
        </Box>
        
        <Divider sx={{ width: "90%", marginBottom: 2, marginTop: 1, borderWidth: 1, borderColor: theme.palette.primary.main }} />

        <Typography
          variant="body2"
          sx={{
            color: theme.palette.text.secondary, // Use secondary text color from the theme
            textAlign: "justify", // Center align the text
            maxWidth: "90%", // Limit width to prevent text from stretching too far
            fontSize: "0.875rem", // Slightly smaller text size
            lineHeight: 1.5, // Line height for readability
          }}
        >
          The current view direction will be considered as the direction selected above.
        </Typography>
      </FormControl>
    </Box>
  );
};

export default CompassDirectionDialog;