const PlanFeatureLimits = {
  "Free Trial": {
    activeProjects: 1,
    archivedProjects: 2,
    imagesLimit: 3,
    vrHeadsetsIncluded: 0,
  },
  "Small Business": {
    activeProjects: 3,
    archivedProjects: 6,
    imagesLimit: 500,
    vrHeadsetsIncluded: 1,
  },
  "Midsize Business": {
    activeProjects: 8,
    archivedProjects: 16,
    imagesLimit: 1500,
    vrHeadsetsIncluded: 2,
  },
  "Large Business": {
    activeProjects: 15,
    archivedProjects: 30,
    imagesLimit: 3000,
    vrHeadsetsIncluded: 4,
  },
};

export default PlanFeatureLimits;